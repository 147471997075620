import $ from 'jquery';
import FastClick from 'fastclick';
import Timer from 'static/js/libraries/cs-timer';
import common from 'static/js/app/game/common';
import screenUtils from 'static/js/app/game/screen';
import pauseUtils from 'static/js/app/game/pause';
import mobileUtils from 'static/js/app/game/mobile';

// this injects the svg plugin into jquery
import neededButNotUsed from 'static/js/libraries/jquery-plugins/svg';

function Game3(parentDiv, langparam, lightversion) {
    var trialnum = 228,
        width,
        xo,yo,
        height,
        pauseswitch = false,
        homeswitch = false,
        size,
        resizer = false,
        widthHalf,
        taskstarted = false,
        heightHalf,
        minWidth = 0,
        minHeight = 0,
        leftTouchArea,
        rightTouchArea,
        container = $('#svg-container'),
        svg,
        order = [],
        toolatecheck,
        answer,
        p,
        overallscore = 0,
        prog = 0,
        loaded = false,
        started = false,
        practiceround = true,
        practiceround1 = false,
        practiceround2 = false,
        g,
        timer, bglayer,
        gridlength,
        fbitems,
        actualJSON,
        end,
        counter = 0,
        instr,
        gamecountdown,
        results = [],
        types = [
            [0, 0, 1],
            [1, 0, 0],
            [1, 1, 0],
            [0, 1, 1]
        ],
        background,
        pausebg,
        msgTime = 4000,
        path = "gamedata/game3img/",
        mobileMode = false,
        timeouts = [],
        list = [path + "background.png", path + "x.png", path + "powered.png", path + "ii.png", path + "tablet.png", path + "tabletRed.png",
            path + "0l.png", path + "0m.png", path + "0r.png", path + "1l.png", path + "1m.png", path + "1r.png",
            path + "x1l.png", path + "x1m.png", path + "x1r.png", path + "x2l.png", path + "x2m.png", path + "x2r.png",
            path + "instr.png"
        ];
    var key;
    var one;
    var two;
    var three;
    var line1;
    var line2;
    var line3;
    var resp;
    var resp;

    function pause() {
        pauseswitch = true;
        document.onkeydown = null;
        try {
            $(bglayer).add(keyimgs).unbind();
        } catch (e) {
        }
        $(rightTouchArea).unbind('touchstart mousedown').remove();
        $(leftTouchArea).unbind('touchstart mousedown').remove();
        var tid = window.setTimeout(function () {
        }, 0);
        while (tid--) {
            window.clearTimeout(tid); // will do nothing if no timeout with id is present
        }
        if (homeswitch) {
            pauseUtils.homemenu(width, height, size, svg, actual_JSON, window.game, 'rgb(255,25,70)');
        } else {
            pauseUtils.pausemenu(width, height, size, svg, actual_JSON, window.game, 'rgb(255,25,70)');
        }
        app.helpers.firefoxGameHack();
    }

    function home() {
        homeswitch = true;
        pause();

    }

    function resume() {
        onResize();
        pauseswitch = false;
        homeswitch = false;
        $(menugr).remove();
        onResize();
        if (started) {
            startStimulus(parseInt(order[counter]) - 1);
        }
    }

    this.invokeResume = function () {
        return resume();
    }

    this.invokePause = function () {
        return pause();
    }

    this.invokeHome = function () {
        return home();
    }

    this.init = function () {
        common.checkIfGameIsPlayed(3, initializeGame);

        function initializeGame() {

            $(function () {
                FastClick.attach(document.body);
            });

            common.loadGameLanguageSheet(app.defaults.gameLangSheet, langparam, function (response) {
                actual_JSON = response;
                actual_JSON = actual_JSON.game3;
                $(parentDiv).css({
                    "overflow": "hidden",
                    "position": "absolute",
                    "width": "100%"
                });
                $(parentDiv).html("<div id=\"svg-container\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\"> </div>");
                $('head').append('<style type="text/css">#svg-container * {font-family: \'Titillium Web\', sans-serif;}</style>');
                container = $('#svg-container');
                container.svg({});
                svg = container.svg('get');
                onResize();
                window.scrollTo(0, 0);
                $('.game-score-box').hide();
            });

            app.events.subscribe.reloadGameLanguage(() => {
                if (app.helpers.checkDeviceOrientation()) {
                    setJSON(app.language.getLanguage());
                }
            });
        }
    }

    function setJSON(l) {
        common.loadGameLanguageSheet(app.defaults.gameLangSheet, l, function (response) {
            actual_JSON = response;
            actual_JSON = actual_JSON.game3;
            pause();
            screenUtils.updateProgress(prog, overallscore, practiceround, actual_JSON);
        });
    }

    function prepareToStart() {
        started = true;
        onResize();
        started = false;
        pauseUtils.makemenu(size, svg, height, width, path, actual_JSON, window.game);
        try {
            $(instr).remove()
        } catch (e) {
        }

        document.onkeydown = null;
        instr = svg.group({
            'fill': '#eee',
            'font-size': gridlength / (app.helpers.isMobile() ? 9 : 20) + 'px',
            'text-anchor': 'middle'
        });
        svg.text(instr, width / 2, height * 0.2, actual_JSON.title1.toUpperCase());
        svg.text(instr, width / 2, height * 0.2 + ((app.helpers.isMobile() ? 1.3 : 0.8) * size), actual_JSON.sub1, {
            'font-size': gridlength / (app.helpers.isMobile() ? 13 : 32) + 'px',
        });
        svg.image(instr, width / 2 - (app.helpers.isMobile() ? 4 : 2) * size, height * 0.2 + (app.helpers.isMobile() ? 0 : 0.6) * size, size * (app.helpers.isMobile() ? 8 : 4), size * (app.helpers.isMobile() ? 8 : 4), path + 'instr.png');
        svg.text(instr, width / 2, height * 0.2 + ((app.helpers.isMobile() ? 7.5 : 4.8) * size), actual_JSON.title2,{
            'font-size': gridlength / (app.helpers.isMobile() ? 11 : 20) + 'px',
        });
        svg.text(instr, width / 2, height * 0.2 + ((app.helpers.isMobile() ? 9 : 5.6) * size), actual_JSON.sub2, {
            'font-size': gridlength / (app.helpers.isMobile() ? 13 : 32) + 'px',
        });

        var size3x = size * (app.helpers.isMobile() ? 2.7 : 1);


        svg.polygon(instr, [
            [width / 2 - 3.4 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 8.8 : 8.3) * size)],
            [width / 2 + 3 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 8.8 : 8.3) * size)],
            [width / 2 + 3.3 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 10 : 9) * size)],
            [width / 2 + 3 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 11.2 : 9.7) * size)],
            [width / 2 - 3.4 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 11.2 : 9.7) * size)],
            [width / 2 - 3.1 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 10 : 9) * size)]
        ], {
            fill: 'rgb(255,25,70)',
            class: 'poligon-btn'
        });
        svg.text(instr, width / 2, height * 0.35 +((app.helpers.isMobile() ? 10.5 : 9.18) * size), " " + actual_JSON.start + " ", {
            'font-size': size / (app.helpers.isMobile() ? 0.7 : 1.9) + 'px',
            'font-weight': 'bold',
            cursor: 'pointer'
        });
        $('.poligon-btn').bind("click", function () {
            pauseswitch = false;
            $(instr).remove();
            $('.game-score-box').show();
            var triallistFile = 'gamedata/control_long.csv';
            if (lightversion)
                triallistFile = 'gamedata/control_short.csv'

            $.get(triallistFile + '?' + Math.random(10), function (data) {
                var orders = data.split('\n');
                if (order.length == 0) {
                    order.push(1, 1, 1, 2, 2, 4, 4, 3, 3, 3);
                    order.push.apply(order, orders[Math.floor((Math.random() * 9))].split(','));
                    trialnum = order.length;
                }
                gamecountdown = setTimeout(function (e) {
                    results[0] = ['trial', 'TType', 'response', 'correct', 'responseTime', 'iti', 'browser', 'os', 'screen', 'timestamp'];
                    startStimulus(parseInt(order[0]) - 1);
                }, 20);
            });
        });
    }

    function startStimulus(TType) {
        var resphand,
            iti,
            score = 0;
        app.helpers.firefoxGameHack();

        if (practiceround && !practiceround1 && !practiceround2) {
            keyimgs = svg.group({});
            if (!app.helpers.isMobileOrTablet()) {
                screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.before, actual_JSON.fingers, actual_JSON.press, 5000000, '#fff', '#4fbadf', '#eee');
                document.onkeydown = function (e) {
                    key = e.location + "" + e.keyCode;
                    switch (key) {
                        case common.getLeftKey():
                        case common.getRightKey():
                            document.onkeydown = null;
                            $(keyimgs).remove();
                            screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.keep, "", 5000000, '#fff', '#4fbadf', '#eee');
                            document.onkeydown = function (e) {
                                $(keyimgs).remove();
                                screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.i1, actual_JSON.i10, 1000000, '#fff', '#4fbadf', '#eee');
                                document.onkeydown = function (e) {
                                    try {
                                        $(g).remove();
                                    } catch (err) {
                                    }
                                    g = svg.group({});
                                    one = svg.image(g, width / 5 - gridlength / 8, (height / 2) - gridlength / 3, gridlength / 4, gridlength / 4, path + '' + 1 + 'l.png');
                                    two = svg.image(g, (width / 2) - (gridlength / 8.2), (height / 2) - gridlength / 2, gridlength / 4, gridlength / 4, path + '' + 1 + 'm.png');
                                    three = svg.image(g, width * 4 / 5 - gridlength / 8, (height / 2) - gridlength / 3, gridlength / 4, gridlength / 4, path + '' + 0 + 'r.png');
                                    screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.i2, actual_JSON.i20, 1000000, '#fff', '#4fbadf', '#eee');
                                    document.onkeydown = function (e) {
                                        screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.i3, actual_JSON.i30, 1000000, '#fff', '#4fbadf', '#eee');
                                        document.onkeydown = function (e) {
                                            $(g).remove();
                                            g = svg.group({});
                                            one = svg.image(g, width / 5 - gridlength / 8, (height / 2) - gridlength / 3, gridlength / 4, gridlength / 4, path + '' + 0 + 'l.png');
                                            two = svg.image(g, (width / 2) - (gridlength / 8.2), (height / 2) - gridlength / 2, gridlength / 4, gridlength / 4, path + '' + 1 + 'm.png');
                                            three = svg.image(g, width * 4 / 5 - gridlength / 8, (height / 2) - gridlength / 3, gridlength / 4, gridlength / 4, path + '' + 1 + 'r.png');
                                            screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.i4, actual_JSON.i40, 1000000, '#fff', '#4fbadf', '#eee');
                                            document.onkeydown = function (e) {
                                                screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, "", actual_JSON.i5, "", 5000000, '#fff', '#4fbadf', '#fff');
                                                document.onkeydown = function (e) {
                                                    $(keyimgs).remove();
                                                    $(g).remove();
                                                    document.onkeydown = null;
                                                    screenUtils.countDown(svg, 2, width, height);
                                                    setTimeout(function () {
                                                        started = true;
                                                        onResize();
                                                        practiceround1 = true;
                                                        drawBoxers(TType);
                                                    }, 2200);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            break;
                    }
                }
            } else {
                actual_JSON.continuetext = actual_JSON.continuetextTouch;
                actual_JSON.i3 = actual_JSON.i3Touch;
                actual_JSON.i4 = actual_JSON.i4Touch;
                screenUtils.showImageInfo(path, actual_JSON, svg, gridlength, width, height, actual_JSON.t1, "", 5000000, "tabletRed.png");
                $(bglayer).add(keyimgs).on("click", function () {
                    $(keyimgs).remove();
                    screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.i1, actual_JSON.i10, 1000000, '#fff', '#4fbadf', '#eee');
                    $(bglayer).add(keyimgs).on("click", function () {
                        try {
                            $(g).remove();
                        } catch (err) {
                        }
                        g = svg.group({});
                        one = svg.image(g, width / 5 - gridlength / 8, (height / 2) - gridlength / (app.helpers.isMobile()?2:3), gridlength / 4, gridlength / 4, path + '' + 1 + 'l.png');
                        two = svg.image(g, (width / 2) - (gridlength / 8.2), (height / 2) - gridlength / (app.helpers.isMobile()?1.5:2), gridlength / 4, gridlength / 4, path + '' + 1 + 'm.png');
                        three = svg.image(g, width * 4 / 5 - gridlength / 8, (height / 2) - gridlength / (app.helpers.isMobile()?2:3), gridlength / 4, gridlength / 4, path + '' + 0 + 'r.png');
                        screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.i2, actual_JSON.i20, 1000000, '#fff', '#4fbadf', '#eee');
                        $(bglayer).add(keyimgs).on("click", function () {
                            screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.i3, actual_JSON.i30, 1000000, '#fff', '#4fbadf', '#eee');
                            $(bglayer).add(keyimgs).on("click", function () {
                                try {
                                    $(g).remove();
                                } catch (err) {
                                }
                                g = svg.group({});
                                one = svg.image(g, width / 5 - gridlength / 8, (height / 2) - gridlength / (app.helpers.isMobile()?2:3), gridlength / 4, gridlength / 4, path + '' + 0 + 'l.png');
                                two = svg.image(g, (width / 2) - (gridlength / 8.2), (height / 2) - gridlength / (app.helpers.isMobile()?1.5:2), gridlength / 4, gridlength / 4, path + '' + 1 + 'm.png');
                                three = svg.image(g, width * 4 / 5 - gridlength / 8, (height / 2) - gridlength / (app.helpers.isMobile()?2:3), gridlength / 4, gridlength / 4, path + '' + 1 + 'r.png');
                                screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.i4, actual_JSON.i40, 1000000, '#fff', '#4fbadf', '#eee');
                                $(bglayer).add(keyimgs).on("click", function () {
                                    screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, "", actual_JSON.i5, "", 5000000, '#fff', '#4fbadf', '#fff');
                                    $(bglayer).add(keyimgs).on("click", function () {
                                        $(g).remove();
                                        $(keyimgs).remove();
                                        $(bglayer).add(keyimgs).unbind();
                                        screenUtils.countDown(svg, 2, width, height);
                                        setTimeout(function () {
                                            started = true;
                                            onResize();
                                            practiceround1 = true;
                                            drawBoxers(TType);
                                        }, 2200);
                                    });
                                });
                            });
                        });
                    });
                });
            }
        } else if (counter == 5 && practiceround1) {
            practiceround1 = false;
            practiceround2 = true;
            screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.welldone, actual_JSON.welldone1, "", 1000000, '#fff', '#4fbadf', '#fff');
            if (!app.helpers.isMobileOrTablet()) {
                document.onkeydown = function (e) {
                    document.onkeydown = null;
                    $(keyimgs).remove();
                    drawBoxers(TType);
                }
            } else {
                $(bglayer).add(keyimgs).on("click", function () {
                    $(bglayer).add(keyimgs).unbind();
                    $(keyimgs).remove();
                    drawBoxers(TType)
                });
            }
        } else {
            drawBoxers(TType);
        }
        taskstarted = true;

        function drawBoxers(t) {
            try {
                $(keyimgs).remove();
            } catch (err) {
            }
            try {
                $(g).remove();
            } catch (err) {
            }
            try {
                $(feedbackFrame).remove();
                $(feedbackText).remove();
            } catch (err) {
            }
            g = svg.group({});
            one = svg.image(g, width / 5 - gridlength / 8, (height / 2) - gridlength / 8, gridlength / 4, gridlength / 4, path + '' + types[t][0] + 'l.png');
            three = svg.image(g, width * 4 / 5 - gridlength / 8, (height / 2) - gridlength / 8, gridlength / 4, gridlength / 4, path + '' + types[t][2] + 'r.png');
            setTimeout(function (e) {
                try {
                    $(two).remove();
                } catch (e) {
                }
                two = svg.image(g, (width / 2) - (gridlength / 8.2), (height / 2) - gridlength / 3, gridlength / 4, gridlength / 4, path + '' + types[t][1] + 'm.png');
                timer = new Timer();
                timer.start();
                leftTouchArea = svg.rect(g, size, 0, widthHalf, height, {
                    'opacity': 0
                });
                rightTouchArea = svg.rect(g, widthHalf, 0, widthHalf - size, height, {
                    'opacity': 0
                });
                $(leftTouchArea).on('touchstart mousedown', function (e) { // click
                    e.preventDefault();
                    checkAnswer(0);
                });
                $(rightTouchArea).on('touchstart mousedown', function (e) { // click
                    e.preventDefault();
                    checkAnswer(1);
                });
                document.onkeydown = function (e) {
                    key = e.location + "" + e.keyCode;
                    switch (key) {
                        case common.getLeftKey():
                            checkAnswer(0);

                            break;
                        case common.getRightKey():
                            checkAnswer(1);
                            break;
                    }
                };
                clearInterval(toolatecheck);
                toolatecheck = setInterval(function () {
                    if (timer.getDuration() > 1000) {
                        clearInterval(toolatecheck);
                        if (app.helpers.checkDeviceOrientation()) {
                            checkAnswer(-1);
                        }
                    }
                }, 2);
            }, 200);
        }

        function checkAnswer(answer) {
            end = timer.getDuration();
            timer.pause();
            score = Math.round(250 - (end - 250) / 3);
            if (score < 1 && counter < 12) {
                score = 0;
            }
            var feedback;
            document.onkeydown = null;
            $(rightTouchArea).unbind('touchstart mousedown').remove();
            $(leftTouchArea).unbind('touchstart mousedown').remove();
            if (end < 90 && answer != -1) {
                resphand = "left (too early)";
                if (answer == 1) {
                    resphand = "right (too early)";
                }
                try {
                    $(g).remove();
                } catch (e) {
                }
                resp = -1;
                showInfo(actual_JSON.tooearly, 2000);
            } else {
                if (answer == -1) {
                    if (app.helpers.checkDeviceOrientation()) {
                        resp = -999;
                        resphand = "toolate";
                        showFeedback(-999, 450, "#fc014d");
                        overallscore = Math.max(0, overallscore - 999);
                        setTimeout(function () {
                            counter = Math.max(0, counter - 1);
                            if (app.helpers.checkDeviceOrientation()) {
                                showInfo(actual_JSON.toolate, 1400);
                            }
                        }, 600);
                    }
                } else {
                    if (!resizer) {
                        punch(answer);
                    } else {
                        setTimeout(function () {
                            startStimulus(parseInt(order[counter]) - 1);
                        }, 150);
                    }
                }
            }

            function punch(s) {
                var time = 180,
                    colors,
                    exlines,
                    one1,
                    two1,
                    three1;
                if (!resizer) {
                    if (s == 0) { //left
                        colors = ['#50bbe1', '#fc004d', '#fc004d', '#50bbe1'];
                        exlines = svg.group({
                            strokeWidth: gridlength / 35,
                            stroke: colors[TType],
                        });
                        line1 = svg.line(exlines, width / 5 - gridlength / 8, height, width / 5, (height / 2) - gridlength / 16);
                        $(line1).css('opacity', '0.0');
                        $(line1).css('transition', '0.1s all');
                        $(one).remove();
                        one1 = svg.image(width / 5 - gridlength / 8, (height / 2) - gridlength / 8, gridlength / 4, gridlength / 4, path + 'x1l.png');
                        if (TType == 0 || TType == 2) { //correct
                            time += 50;
                        }
                        setTimeout(function () {
                            $(line1).css('opacity', '0.8');
                            if (TType == 0 || TType == 2) { //correct
                                $(two).remove();
                                two1 = svg.image((width / 2) - (gridlength / 8.18), (height / 2) - gridlength / 3, gridlength / 4, gridlength / 4, path + 'x1m.png');
                                line2 = svg.line(exlines, width / 5, (height / 2), (width / 2), (height / 2) - gridlength / 4.5);
                                $(one1).remove();
                                one1 = svg.image(width / 5 - gridlength / 8, (height / 2) - gridlength / 8, gridlength / 4, gridlength / 4, path + 'x2l.png');
                                $(line2).css('opacity', '0.0');
                                $(line2).css('transition', '0.1s all');
                                setTimeout(function () {
                                    $(line2).css('opacity', '0.8');
                                    line3 = svg.line(exlines, width / 2, (height / 2) - gridlength / 3.7, (width / 2), 0);
                                    $(line3).css('opacity', '0.0');
                                    $(line3).css('transition', '0.1s all');
                                    $(bglayer).css('opacity', '0.3');
                                    setTimeout(function () {
                                        $(two1).remove();
                                        two1 = svg.image((width / 2) - (gridlength / 8.2), (height / 2) - gridlength / 3, gridlength / 4, gridlength / 4, path + 'x2m.png');
                                        $(line3).css('opacity', '0.8');
                                    }, 20);
                                }, 20);
                            }
                        }, 20);
                        resphand = "left";
                    } else {
                        colors = ['#fc004d', '#50bbe1', '#50bbe1', '#fc004d'];
                        exlines = svg.group({
                            strokeWidth: gridlength / 35,
                            stroke: colors[TType],
                        });
                        line1 = svg.line(exlines, width * 4 / 5 + gridlength / 8, height, width * 4 / 5, (height / 2) - gridlength / 16);
                        $(line1).css('opacity', '0.0');
                        $(line1).css('transition', '0.1s all');
                        $(three).remove();
                        three1 = svg.image(width * 4 / 5 - gridlength / 8, (height / 2) - gridlength / 8, gridlength / 4, gridlength / 4, path + 'x1r.png');
                        if (TType == 1 || TType == 3) { //correct
                            time += 50;
                        }
                        setTimeout(function () {
                            $(line1).css('opacity', '0.8');
                            if (TType == 1 || TType == 3) { //correct
                                $(two).remove();
                                two1 = svg.image((width / 2) - (gridlength / 8.2), (height / 2) - gridlength / 3, gridlength / 4, gridlength / 4, path + 'x1m.png');
                                line2 = svg.line(exlines, width * 4 / 5, (height / 2), (width / 2), (height / 2) - gridlength / 4.5);
                                $(three1).remove();
                                three1 = svg.image(width * 4 / 5 - gridlength / 8, (height / 2) - gridlength / 8, gridlength / 4, gridlength / 4, path + 'x2r.png');
                                $(line2).css('opacity', '0.0');
                                $(line2).css('transition', '0.1s all');
                                setTimeout(function () {
                                    $(line2).css('opacity', '0.8');
                                    line3 = svg.line(exlines, width / 2, (height / 2) - gridlength / 3.7, (width / 2), 0);
                                    $(line3).css('opacity', '0.0');
                                    $(line3).css('transition', '0.1s all');
                                    $(bglayer).css('opacity', '0.3');
                                    setTimeout(function () {
                                        $(two1).remove();
                                        two1 = svg.image((width / 2) - (gridlength / 8.18), (height / 2) - gridlength / 3, gridlength / 4, gridlength / 4, path + 'x2m.png');
                                        $(line3).css('opacity', '0.8');
                                    }, 20);
                                }, 20);
                            }
                        }, 20);
                        resphand = "right";
                    }
                }
                setTimeout(function () {
                    $(bglayer).css('opacity', '0.8');
                    $(exlines).remove();
                    $(two1).remove();
                    try {
                        $(one1).remove();
                    } catch (e) {
                    }
                    try {
                        $(three1).remove();
                    } catch (e) {
                    }
                    resp = 0;
                    if (score < -999) {
                        score = -999;
                    }
                    if (resizer) {
                        showFeedback("", 450, "lightgrey");
                    } else {
                        if ((s == 0 && (TType == 0 || TType == 2)) || (s == 1 && (TType == 1 || TType == 3))) {
                            resp = 1;
                            showFeedback(score, 450, "lightgrey");
                        } else {
                            score = Math.min(0, score);
                            showFeedback(score, 450, "lightgrey");
                        }
                        overallscore = Math.max(0, overallscore += score);
                    }
                }, time);
            }
        }

        function showFeedback(s, t, col) {
            prog = (counter + 1) / (trialnum);
            fbitems = svg.group({});
            var circlepath = [];
            var radius = gridlength / 7;
            if(app.helpers.isMobile()){
                radius *= 1.2;
            }
            var steps = 100;
            var xStart = width / 2;
            var yStart = height / 2 - gridlength / 5;
            for (var i = 0; i < steps; i++) {
                var x = (xStart + radius * Math.cos(2 * Math.PI * i / steps));
                var y = (yStart + radius * Math.sin(2 * Math.PI * i / steps));
                circlepath.push([x, y]);
            }
            if (t > 10) {
                circlepath = circlepath.slice(0, 80);
                var fbpoly = svg.polyline(fbitems, circlepath, {
                    stroke: col,
                    strokeWidth: Math.floor(gridlength / 40),
                    transform: 'rotate(' + (128) + ',' + (xStart) + ',' + (yStart) + ')'
                });
                if (col != "#fc004d" && s > 0) {
                    if (s > 140) {
                        var circlepath2 = circlepath.slice(Math.max(1, Math.floor(s / 3.7)) - 1, circlepath.length);
                        $(fbpoly).attr("stroke", app.helpers.getColor(1 - s / 300));
                        var fbpoly2 = svg.polyline(fbitems, circlepath2, {
                            stroke: 'lightgrey',
                            strokeWidth: Math.floor(gridlength / 40),
                            transform: 'rotate(' + (128) + ',' + (xStart) + ',' + (yStart) + ')'
                        });
                    } else {
                        var circlepath2 = circlepath.slice(0, Math.max(1, Math.floor(s / 3.7)) - 1);
                        var fbpoly2 = svg.polyline(fbitems, circlepath2, {
                            stroke: app.helpers.getColor(1 - s / 300),
                            strokeWidth: Math.floor(gridlength / 40),
                            transform: 'rotate(' + (128) + ',' + (xStart) + ',' + (yStart) + ')'
                        });
                    }
                }
            }

            // feedback text
            svg.text(fbitems, widthHalf, yStart + gridlength / 6.5, s.toString(), {
                'fill': col,
                'font-size': gridlength / (app.helpers.isMobile()?12:15),
                'text-anchor': 'middle',
            });

            timer.pause();
            document.onkeydown = null;
            $(rightTouchArea).unbind('touchstart mousedown').remove();
            $(leftTouchArea).unbind('touchstart mousedown').remove();
            try {
                $(g).remove();
            } catch (e) {
            }
            iti = t + Math.floor((Math.random() * 200));
            var iti2 = iti;
            if (!resizer) {
                if (t < 10) {
                    iti2 = iti + 2000;
                }
                counter++;

                results.push([counter, TType, resphand, resp, end, iti2, app.helpers.getBrowserName(), navigator.platform, screen.width + 'x' + screen.height, app.helpers.getTimestamp() ]);
            }
            var halfIti = Math.floor(iti * 0.5);
            setTimeout(function () {
                screenUtils.updateProgress(prog, overallscore, practiceround, actual_JSON);
                $(fbitems).remove();
            }, halfIti);
            if (s == -999) {
                iti = iti + 2000;
            }

            setTimeout(function () {
                if (counter < trialnum) {
                    if (t < 10) {
                        counter--;
                    }
                    if (counter == 10 && t > 10 && practiceround) {
                        practiceround = false;
                        overallscore = 0;
                        screenUtils.updateProgress(prog, overallscore, practiceround, actual_JSON);
                        screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.faster, actual_JSON.faster1, "", 1000000, '#fff', '#4fbadf', '#fff');
                        if (!app.helpers.isMobileOrTablet()) {
                            document.onkeydown = function (e) {
                                screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.realgame, actual_JSON.realgame2, "", 1000000, '#fff', '#4fbadf', '#fff');
                                document.onkeydown = function (e) {
                                    $(keyimgs).remove();
                                    startStimulus(parseInt(order[counter]) - 1);
                                }
                            }
                        } else {
                            $(bglayer).add(keyimgs).on("click", function () {
                                screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.realgame, actual_JSON.realgame2, "", 1000000, '#fff', '#4fbadf', '#fff');
                                $(bglayer).add(keyimgs).on("click", function () {
                                    $(keyimgs).remove();
                                    $(bglayer).add(keyimgs).unbind();
                                    startStimulus(parseInt(order[counter]) - 1);
                                });
                            });
                        }
                    } else {
                        if (practiceround1 && resp == 0 && s != -999 && t > 9) {
                            counter--;
                            screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.error, actual_JSON.error1, actual_JSON.error2, 1000000, '#fff', '#4fbadf', '#eee');
                            if (!app.helpers.isMobileOrTablet()) {
                                document.onkeydown = function (e) {
                                    document.onkeydown = null;
                                    $(keyimgs).remove();
                                    startStimulus(parseInt(order[counter]) - 1);
                                }
                            } else {
                                $(bglayer).add(keyimgs).on("click", function () {
                                    $(bglayer).add(keyimgs).unbind();
                                    $(keyimgs).remove();
                                    startStimulus(parseInt(order[counter]) - 1);
                                });
                            }
                        } else {
                            if (app.helpers.checkDeviceOrientation()) {
                                startStimulus(parseInt(order[counter]) - 1);
                            }
                        }
                    }
                } else {
                    screenUtils.showResults(3, overallscore, container, parentDiv, results);
                }
            }, iti);
        }

        function showInfo(s, t) {
            timer.pause();
            var g = app.helpers.isMobile()?gridlength*1.5:gridlength;
            document.onkeydown = null;
            $(rightTouchArea).unbind('touchstart mousedown').remove();
            $(leftTouchArea).unbind('touchstart mousedown').remove();
            $(bglayer).add(keyimgs).unbind();
            try {
                $(one).remove();
                $(two).remove();
                $(three).remove();
            } catch (e) {
            }
            if (!resizer) {
                var feedbackFrame = svg.rect(width / 2 - g / 1.5, height / 2 - g / 11, g * 1.333, g / 5.5, 6, 6, {
                    'fill-opacity': 0.95,
                    fill: 'rgb(255,25,70)'
                });
                var feedbackText = svg.text(widthHalf, height / 1.95, s.toUpperCase(), {
                    'fill': '#fff',
                    'font-weight': '400',
                    'font-size': g / 20 + 'px',
                    'text-anchor': 'middle'
                });
                t += 1500;
            }
            setTimeout(function () {
                try {
                    if (app.helpers.checkDeviceOrientation()) {
                        $(feedbackFrame).remove();
                        $(feedbackText).remove();
                    }
                } catch (e) {
                }
                score = 0;
                if (s != actual_JSON.toolate) {
                    showFeedback("", 0, "");
                }
            }, t - 1500)
        }
    }

    function onResize() {
        width = screenUtils.getWindowWidthOrMin();
        height = screenUtils.getWindowHeightOrMin(parentDiv);
        xo = 0;
        yo = 0;
        size = Math.min(width, height) / 20;
        widthHalf = width / 2;
        heightHalf = height / 2;
        svg.configure({
            width: '100%',
            height: height
        });
        $(parentDiv).css("height", height + "px");
        var callback = function () {
            if (!pauseswitch && !resizer) {
                resizer = true;
                $(leftTouchArea).trigger("mousedown");
            }
            try {
                $(bglayer).remove();
                $(background).remove();
                $(ltext).remove();
            } catch (err) {
            }
            gridlength = Math.min(Math.min(width - (2 * size), 600), Math.min(height - (6 * size), 600));
            var a = width / height;
            if (a >= 1) {
                background = svg.image(0, 0, height, height, path + 'background.png', {
                    transform: 'scale(' + a * 1 + ', ' + 1 + ')'
                });
            } else {
                background = svg.image(0, 0, width, width, path + 'background.png', {
                    transform: 'scale(' + 1 + ', ' + 1 / a + ')'
                });
            }
            bglayer = svg.rect(0, 0, width, height, {
                fill: 'black'
            });
            $(bglayer).css('opacity', '0.8');
            $(bglayer).css('transition', '0.1s all');
            try {
                $(powered).remove();
            } catch (err) {
            }
            powered = svg.image(width - 214, height - 40, 214, 40, path + 'powered.png', {
                cursor: "pointer"
            });
            $(powered).on("click", function () {
                if (!app.helpers.isMobileOrTablet()) {
                    window.open('http://brainsfirst.com/', '_blank');
                }
            });
            if (app.helpers.isMobile()) {
                $(powered).remove();
            }
            screenUtils.updateProgress(prog, overallscore, practiceround, actual_JSON);
            if (pauseswitch) {
                pause();
            } else {
                if (!mobileMode && app.helpers.isMobile()) {
                    mobileUtils.activateMobileMode();
                    mobileMode = true;
                } else {
                    pauseUtils.makemenu(size, svg, height, width, path, actual_JSON, window.game);
                }
            }
            setTimeout(function (e) {  // emulate left choice at resize in order to ensure that a next trial will come and the game won't get stuck
                $(leftTouchArea).trigger("mousedown");
                resizer = false;
            }, 160);
            if (!started && !pauseswitch) {
                prepareToStart();
            }
            app.helpers.firefoxGameHack();
        };

        $(window).blur(function () {
            if (app.helpers.checkDeviceOrientation()) {
                try {
                    pause();
                } catch (e) { }
            }
        });
        $('.header').add('.subheader *').unbind();
        $('.header').add('.subheader *').click(function () {
            if (app.helpers.checkDeviceOrientation()) {
                pause();
            }
        });
        $('.logo, .logo img ').unbind();
        $('.logo, .logo img ').click(function (e) {
            if (app.helpers.checkDeviceOrientation()) {
                home();
            }
            e.preventDefault();
            return false;
        });
        loaded = screenUtils.checkedAndLoaded(loaded, path, list, svg, width, height, size, callback, actual_JSON);
        if (!loaded && taskstarted) {
            pauseswitch = true;
        }
    }

    $(window).resize(function () {
        onResize();
        onResize();
        if (app.helpers.isMobile()) {
            mobileUtils.fullscreenCheck();
        }
    });

    this.initDummy = function () {
        $(parentDiv).css({
            "overflow": "hidden",
            "position": "absolute",
            "width": "100%"
        });
        $(parentDiv).html("<div id=\"svg-container\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\"> </div>");
        $('head').append('<style type="text/css">#svg-container * {font-family: \'Titillium Web\', sans-serif;}</style>');
        container = $('#svg-container');
        container.svg({});
        svg = container.svg('get');

        results[0] = ['trial', 'TType', 'response', 'correct', 'responseTime', 'iti', 'browser', 'os', 'screen', 'timestamp'];
        for (var i = 0; i < 225; i++) {
            results.push([i, 1, "left", 1, 333, 444, app.helpers.getBrowserName(), navigator.platform, screen.width + 'x' + screen.height, app.helpers.getTimestamp() ]);
        }

        screenUtils.showResults(3, 666, container, parentDiv, results);
    }

}

export default Game3;
