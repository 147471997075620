import $ from 'jquery';
import storage from './storage';
import constants from './constants';

// handles loading/switching of campaigns

export default {

    setCampaign: function (campaign) {
        storage.setCampaign(campaign);
        storage.setLightversion(campaign.lightVersion === true);
    },

    isLightversion: function () {
        return storage.getLightversion() == 'true';
    },

    isSoccer: function () {
        return (storage.getCampaign() || {}).landingPage === constants.landingPageSoccer;
    },

    isSports: function () {
        return (storage.getCampaign() || {}).landingPage === constants.landingPageSports;
    },

    isHcm: function () {
        const lp = (storage.getCampaign() || {}).landingPage;
        return lp !== constants.landingPageSoccer && lp !== constants.landingPageSports;
    },

    isDemo: function () {
        return (storage.getCampaign() || {}).demo === true;
    },

    isPyramid: function () {
        let campaignModels = (storage.getCampaign() || {}).campaignModels
        return !!campaignModels && !!campaignModels.find(cm => cm.type === "PYRAMID_MATCH" || cm.type === "PYRAMID_TRAIT")
    },

    hasNoTraitModels: function () {
        let campaignModels = (storage.getCampaign() || {}).campaignModels
        return !!campaignModels && !campaignModels.find(cm => cm.type === "PYRAMID_MATCH" || cm.type === "PYRAMID_TRAIT" || cm.type === "TRAIT")
    },

    hasBrainSkillModel: function () {
        let campaignModels = (storage.getCampaign() || {}).campaignModels;
        return !!campaignModels && campaignModels.some(cm => cm.type === "BRAIN_SKILL");
    },

    reportAccessIsAllowed: function () {
        return (storage.getCampaign() || {}).resultPdf === true;
    },

    reportArchetypeIsAllowed: function () {
        return (storage.getCampaign() || {}).archetypeReportsAvailableForCandidate === true;
    },

    useNativePageLangTranslations: function () {
        return (storage.getCampaign() || {}).useNativePageLangs === true;
    },

    isReplayEnabled: function () {
        let c = storage.getCampaign() || {};
        let gm = c.gameMode || 0;
        if (gm === constants.gameMode.AUTOMATIC_REPLAY ||
            gm === constants.gameMode.MANUAL_REPLAY) {
            return true;
        }
        return false;
    },

    // transforms google sheet json data into pageLang object
    loadCampaignLanguage: function (sheetID, lang) {

        if (storage.getPageLangSheet()) {
            return Promise.resolve().then(() => {

                let d = {};

                function getVal(obj) {
                    try {
                        return app.helpers.replaceAll(obj["gsx$" + lang].$t, "\\\"", "'").replace(/\\/g, "");
                    } catch (e) {
                        return "";
                    }
                }

                function getKey(obj) {
                    return obj.gsx$var.$t;
                }

                function evaluateConditional(str) {
                    let negation = false;

                    const indexOfStart = str.indexOf('[');
                    if (indexOfStart === -1) return str;

                    let indexOfSign = str.indexOf('!=', indexOfStart);
                    if (indexOfSign !== -1) {
                        negation = true;
                    } else {
                        indexOfSign = str.indexOf('=', indexOfStart);
                        if (indexOfSign === -1) {
                            return false;
                        }
                    }

                    const indexOfEnd = str.indexOf(']', indexOfSign);
                    if (indexOfEnd === -1) {
                        return false;
                    };

                    const key = str.substring(indexOfStart + 1, indexOfSign).trim();
                    let val = str.substring(indexOfSign + 1 + (negation ? 1 : 0), indexOfEnd).trim();

                    if (val[0] == val[val.length - 1] && (val[0] == '"' || val[0] == "'")) {
                        val = val.substring(1, val.length - 1);
                    }

                    const normalizedPageLangKey = typeof d[key] === "object" && Object.keys(d[key]).length === 0
                        ? '{}'
                        : String(d[key] !== undefined ? d[key] : "").toLowerCase();

                    const normalizedStoreValue = val.toLowerCase();

                    if ((normalizedPageLangKey == normalizedStoreValue) === (negation == false)) {
                        return evaluateConditional(str.substring(0, indexOfStart) + str.substring(indexOfEnd + 1));
                    } else {
                        return false;
                    }

                }

                function objToDict(dict, prefix = "", encoded = false) {
                    const traverseParam = function (path, obj) {
                        const key = path[path.length - 1];
                        const parsedKey = evaluateConditional(key);
                        const val = obj[key];

                        if (parsedKey) {
                            path[path.length - 1] = parsedKey;
                        }

                        if (val === undefined || parsedKey === false) {
                            return false;
                        }

                        if (val !== null && typeof val === "object" && Object.keys(val).length !== 0) {
                            const indices = Object.keys(val);
                            for (let j = 0; j < indices.length; j++) {
                                traverseParam(path.concat(indices[j]), val);
                            }
                        } else {
                            d[path.join(".")] = val;
                        }
                    };

                    if (encoded) {
                        for (let i in dict) {
                            traverseParam([dict[i].name], { [dict[i].name]: dict[i].value });
                        }
                    } else {
                        for (let ind in dict) {
                            traverseParam([prefix, ind], dict);
                        }
                    }
                }

                let campaign = storage.getCampaign();
                if (campaign) {
                    let campaignVariables = campaign["campaignVariables"];
                    delete campaign["campaignVariables"];

                    objToDict(storage.getCandidate(), "candidate");
                    objToDict(campaign, "campaign");

                    if (!campaign.sheet) {
                        objToDict(campaignVariables, false, true);
                    }
                }

                if (!campaign || campaign.sheet) {
                    let data = storage.getPageLangSheet();

                    if (data && data.feed) {
                        let entry = data.feed.entry;
                        $(entry).each(function (index) {
                            const key = evaluateConditional(getKey(this));
                            if (key !== false) {
                                d[key] = getVal(this);
                            }
                        });
                    }
                }
                return d;
            });
        }

        if (!sheetID) {
            storage.setPageLangSheet({});
            return this.loadCampaignLanguage(sheetID, lang)
        }

        return new Promise((resolve, reject) => {
            var pageLangUrl = '/pagelang/' + sheetID;
            if (this.useNativePageLangTranslations()) {
                pageLangUrl = '/api/candidate/campaigns/pagelang/' + sheetID;
            }
            $.getJSON(pageLangUrl, (data) => {
                storage.setPageLangSheet(data);
                resolve(this.loadCampaignLanguage(sheetID, lang));
            }).fail((err) => {
                reject(err);
            });
        })
    }
};



