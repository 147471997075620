import $ from 'jquery';

let backrect = {};
let backtext = {};
let pauserect = {};
let pausetext = {};
let backbutton = {};
let pbutton = {};
let bbutton = {};
let gridlength = {};
let resumshape = {};
let resum = {};

var arrowShape = function (x, y, size) {
    var size3 = size * (app.helpers.isMobile() ? 1.8 : 1);
    var size3x = size * (app.helpers.isMobile() ? 2.7 : 1);

    return [[x - 3.4 * size3x, y + (.3 * size3)],
    [x + 3 * size3x, y + (.3 * size3)],
    [x + 3.3 * size3x, y + (1 * size3)],
    [x + 3 * size3x, y + (1.7 * size3)],
    [x - 3.4 * size3x, y + (1.7 * size3)],
    [x - 3.1 * size3x, y + (1 * size3)]];
};

var getGridlength = function (size) {
    var g = Math.min(Math.min(window.innerWidth - (2 * size), 800), Math.min(window.innerHeight - (6 * size), 800));
    if (app.helpers.isMobile()) {
        g *= 2;
    }
    return g;
};

function makemenu(size, svg, height, width, path, actual_JSON, root) {
    try {
        $(backbutton).remove();
        $(pausebutton).remove()
        $(backrect).remove();
        $(pauserect).remove()
    } catch (e) {
    }
    var menuh = (Math.round(size * 0.5)) * 2;
    if (app.helpers.isMobile()) {
        menuh *= 2;
    }

    backrect = svg.rect(-1.5 * menuh, height / 2 - menuh / 2, menuh * 2.5, menuh, {
        fill: '#e5e3db',
        cursor: "pointer",
    });
    pauserect = svg.rect(width - menuh, height / 2 - menuh / 2, menuh * 2.5, menuh, {
        fill: '#e5e3db',
        cursor: "pointer",
    });
    backbutton = svg.image(0, height / 2 - menuh / 2, menuh, menuh, path + "x.png", { cursor: "pointer" });
    pausebutton = svg.image(width - menuh, height / 2 - menuh / 2, menuh, menuh, path + "ii.png", { cursor: "pointer" });
    $(pausebutton).add(pausebutton).on("click", function () { root.invokePause(); });
    $(backbutton).add(backbutton).on("click", function () { root.invokeHome(); });
    var bhovered = false;
    var phovered = false;
    $(backbutton).hover(function () {
        if (!bhovered) {
            bhovered = true;
            $(backrect).css('fill', '#e5e3db');
            try {
                $(backtext).remove();
                $(bbutton).remove()
            } catch (e) {
            }
            backtext = svg.text(1 * menuh, height / 2 + menuh / 6.5, actual_JSON.home, {
                'font-size': menuh / 2.5,
                'font-weight': 'bolder',
                color: 'rgb(43,43,41)'
            });
            bbutton = svg.rect(0, height / 2 - menuh / 2, menuh * 2.5, menuh, {
                fill: 'transparent',
                cursor: "pointer"
            });
            $(bbutton).on("click", function () { root.invokeHome(); });
            $(bbutton).hover(function () { },
                function () {
                    $(bbutton).remove();
                    if (app.helpers.isWebkitBrowser()) {
                        $(backrect).animate({ x: -1.5 * menuh }, 100);
                    } else {
                        $(backrect).attr("x", -1.5 * menuh + "px");
                    }
                    setTimeout(function () {
                        $(backtext).remove();
                        bhovered = false;
                        $(backrect).css('fill', '#e5e3db');
                    }, 100);
                });
            if (app.helpers.isWebkitBrowser()) {
                $(backrect).animate({ x: 0 }, 100);
            } else {
                $(backrect).attr("x", 0);
            }
        }
    }, function () { });
    $(pausebutton).hover(function () {
        if (!phovered) {
            phovered = true;
            $(pauserect).css('fill', '#e5e3db');
            try {
                $(pausetext).remove();
                $(pbutton).remove()
            } catch (e) {
            }
            pausetext = svg.text(width - 2.15 * menuh, height / 2 + menuh / 6.5, actual_JSON.pause, {
                'font-size': menuh / 2.5,
                'font-weight': 'bolder',
                color: 'rgb(43,43,41)'
            });
            pbutton = svg.rect(width - menuh * 2.5, height / 2 - menuh / 2, menuh * 2.5, menuh, {
                fill: 'transparent',
                cursor: "pointer"
            });
            $(pbutton).on("click", function () { root.invokePause(); });
            $(pbutton).hover(function () { },
                function () {
                    $(pbutton).remove();
                    if (app.helpers.isWebkitBrowser()) {
                        $(pauserect).animate({ x: width - menuh }, 100);
                    } else {
                        $(pauserect).attr("x", width - menuh + "px");
                    }
                    setTimeout(function () {
                        $(pausetext).remove();
                        phovered = false;
                        $(pauserect).css('fill', '#e5e3db');
                    }, 100);
                });
            if (app.helpers.isWebkitBrowser()) {
                $(pauserect).animate({ x: width - 2.5 * menuh }, 100);
            } else {
                $(pauserect).attr("x", width - 2.5 * menuh + "px");
            }
        }
    }, function () { });
}

function pausemenu(width, height, size, svg, actual_JSON, root, fill) {
    gridlength = getGridlength(size);
    try {
        $(backbutton).remove();
        $(pausebutton).remove();
        $(backrect).remove();
        $(pauserect).remove();
        $(menugr).remove();
    } catch (e) {
    }
    menugr = svg.group({ 'text-anchor': 'middle' });
    svg.rect(menugr, 0, 0, window.innerWidth, window.innerHeight, { fill: "lightgrey" });
    svg.text(menugr, window.innerWidth / 2, window.innerHeight / 2.5 - size / (app.helpers.isMobile() ? 3.4 : 3), actual_JSON.pause + "...", {
        fill: "#222",
        'font-size': (gridlength) / 22 + 'px',
    });
    resumshape = svg.polygon(menugr, arrowShape(window.innerWidth / 2, window.innerHeight / 2.5, gridlength / (app.helpers.isMobile() ? 32 : 17)), {
        cursor: 'pointer',
        fill: fill,
        class: 'poligon-reset-btn'
    });
    resum = svg.text(menugr, window.innerWidth / 2, window.innerHeight / 2.5 + gridlength / 14, actual_JSON.resume, {
        'font-size': gridlength / 28 + 'px',
        cursor: 'pointer',
        fill: "#fff",
    });
    $(resum).add(resumshape).on("click", function (a) {
        a.preventDefault();
        root.invokeResume();
    });
}

function homemenu(width, height, size, svg, actual_JSON, root, fill) {
    gridlength = getGridlength(size);
    try {
        $(backbutton).remove();
        $(pausebutton).remove();
        $(backrect).remove();
        $(pauserect).remove()
        $(menugr).remove()
    } catch (e) {
    }
    menugr = svg.group({ 'text-anchor': 'middle' });
    svg.rect(menugr, 0, 0, window.innerWidth, window.innerHeight, { fill: "lightgrey" });
    svg.text(menugr, window.innerWidth / 2, window.innerHeight / 2.5 - size * (app.helpers.isMobile() ? 3.3 : 1.55), actual_JSON.sure, {
        fill: "#222",
        'font-size': gridlength / 22 + 'px',
    });
    svg.text(menugr, window.innerWidth / 2, window.innerHeight / 2.5 - size / (app.helpers.isMobile() ? 0.7 : 2), actual_JSON.sure2, {
        fill: "#222",
        'font-size': gridlength / 32 + 'px',
    });

    var arrowPadding = gridlength / 4.8;
    var x = app.helpers.isMobile() ? window.innerWidth / 2 : window.innerWidth / 2 - arrowPadding;
    var y = window.innerHeight / (app.helpers.isMobile() ? 1.8 : 2.5);
    var arrowSize = gridlength / (app.helpers.isMobile() ? 32 : 17);
    let quitshape = svg.polygon(menugr, arrowShape(x, y, arrowSize), {
        cursor: 'pointer',
        fill: 'darkgrey',
        class: 'poligon-quit-btn'
    });
    let quit = svg.text(menugr, x, y + gridlength / 14, actual_JSON.quit, {
        'font-size': gridlength / 28 + 'px',
        cursor: 'pointer',
        fill: "#fff"
    });

    x = app.helpers.isMobile() ? window.innerWidth / 2 : window.innerWidth / 2 + arrowPadding;
    y = window.innerHeight / 2.5;
    resumshape = svg.polygon(menugr, arrowShape(x, y, arrowSize), {
        cursor: 'pointer',
        fill: fill,
        class: 'poligon-reset-btn'
    });
    resum = svg.text(menugr, x, y + gridlength / 14, actual_JSON.resume, {
        'font-size': gridlength / 28 + 'px',
        cursor: 'pointer',
        fill: "#fff"
    });

    $(quit).add(quitshape).on("click", function (a) {
        a.preventDefault();
        app.events.fire.navigate('game-types.html');
    });
    $(resum).add(resumshape).on("click", function (a) {
        a.preventDefault();
        root.invokeResume();
    });
}

export default {
    makemenu,
    pausemenu,
    homemenu
};
