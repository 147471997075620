import $ from "jquery";

import api from "static/js/app/base/api";
import constants from "static/js/app/base/constants";
import events from "static/js/app/base/events";
import storage from "static/js/app/base/storage";
import helpers from "static/js/app/helpers/helpers";
import ui from "static/js/app/ui/ui";

export default function (cb) {
    const candidate = storage.getCandidate();
    const campaign = storage.getCampaign();
    const campaignGames = campaign.games.map((e) => e.id);
    if (candidate && candidate.firstName && candidate.lastName) {
        $(".player-name").text("Hi " + app.candidate.getName());
    }

    // fill check icons for played games
    campaignGames.map((id) =>
        $(".game-types-item[gameid=" + id + "]").css("display", "")
    );

    function findNextGameToPlay(resultGames) {
        let nextGame = false;
        campaignGames.map((e) => {
            if (resultGames.indexOf(e) === -1) {
                if (!nextGame) nextGame = e;
            }
        });
        return nextGame;
    }

    function unplayedGames(resultGames) {
        let result = campaignGames.filter((g) => !resultGames.includes(g));
        return result;
    }

    function hasNotPlayedAnyGame() {
        let nextPage = "consents-default.html";
        if (app.campaign.isDemo() || app.campaign.isHcm()) {
            nextPage = "introduction-step.html";
        }
        $(".btn-goto-games").attr(
            "href",
            helpers.addDefaultParametersToUrl(nextPage)
        );
    }
    function hasNotPlayedAllTheGames(gameIds, nextGame) {
        $("[data-text=start]").data("text", "continue");
        $(".btn-goto-games").attr(
            "href",
            helpers.addDefaultParametersToUrl("game" + nextGame + ".html")
        );
        gameIds.map((e) =>
            $(".game-types-item[gameId=" + e + "]").addClass("completed")
        );
    }
    function hasPlayedAllTheGames() {
        $("[data-text=start]").data("text", "view_score");
        $(".btn-submit").bind("click", () =>
            selectResultTemplate()
        );
        $(".game-types-item").addClass("completed");

        function playAgain() {
            if (helpers.isMobile()) {
                ui.error(
                    "We're sorry, but we cannot measure your brain profile when playing the games on a mobile device. Please login with a PC/Laptop or Tablet."
                );
                return;
            }
            api.createNewGameBattery(candidate.id)
                .then(() => {
                    storage.setFlagProfilePageGoesToGames();
                    events.fire.navigate("profile-page.html?mode=edit");
                })
                .catch((err) =>
                    console.error(
                        "Can't create a new game battery for client '" +
                            candidate.id +
                            "'. Got:",
                        err
                    )
                );
        }
        let gm = storage.getCampaign().gameMode;
        if (gm === constants.gameMode.MANUAL_REPLAY) {
            $("#btn-play-again").click(playAgain);
            $("#btn-play-again").show();
        }
    }

    return api.getResults().then((gameResults) => {
        const gameIds = gameResults.map((e) => e.game.id);
        let unplayedGamesArr = unplayedGames(gameIds);
        if (unplayedGamesArr.length > 0) {
            sessionStorage.setItem("unplayed-games", unplayedGamesArr);
        }
        if (
            gameIds.length === 0 &&
            sessionStorage.getItem("game-sawIntro") !== "true"
        ) {
            hasNotPlayedAnyGame();
        } else {
            let nextGame = findNextGameToPlay(gameIds);
            if (nextGame) {
                hasNotPlayedAllTheGames(gameIds, nextGame);
            } else {
                hasPlayedAllTheGames();
            }
        }

        ui.setPageLang();

        // proceed with common page initialisation
        cb();
    });
}

export let selectResultTemplate = () => {
    if(app.campaign.isPyramid()) {
        app.events.fire.navigate('resultscreens1-one-topskill.html');
    } else {
        app.events.fire.navigate('resultscreens1.html');
    }
}
