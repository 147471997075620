import $ from 'jquery';
import constants from '../../base/constants';
import domHelper from '../../helpers/dom';
import liquid from "../../../../themes/liquid/liquid";

const resultScreen = function(cb) {
    if (app.storage.getCampaign().path === constants.remoteWork.path) {
        app.events.fire.navigate('resultscreens-remote-work.html');
        return;
    }

    let bestScoreTraitImg = $('#result-bestscore-trait-img');
    let bestScoreTraitImgSecond = $('#result-bestscore-trait-img-second');
    let bestScoreTraitImgThird = $('#result-bestscore-trait-img-third');
    let bestScoreTraitImgSoccer = $('.result-bestscore-trait-img-soccer');
    let bestScoreDescription = $('#result-bestscore-description');
    let bestScoreDescriptionSecond = $('#result-bestscore-description-second');
    let bestScoreDescriptionThird = $('#result-bestscore-description-third');
    let bestScoreTitle = $('#result-bestscore-title');
    let bestScoreTitleSecond = $('#result-bestscore-title-second');
    let bestScoreTitleThird = $('#result-bestscore-title-third');
    let reportSection = $('#report-section');
    let resultsTitle = $('#resultsTitle');
    let resultsTitleSoccer = $('#resultsTitleSoccer');
    let reportSectionText = $('#report-section-text');
    let reportSectionTextSoccer = $('#report-section-text-soccer');
    let reportArchetypeSection = $('#report-archetype-section');
    let archetypeLabelText = $('#report-archetype-label-section-text');
    let demoResults = $('#demo-results');

    liquid.liquidResults();

    bestScoreTitle.hide();
    bestScoreTraitImg.hide();
    bestScoreTraitImgSecond.hide();
    bestScoreTraitImgThird.hide();
    bestScoreDescription.hide();
    bestScoreDescriptionSecond.hide();
    bestScoreDescriptionThird.hide();
    reportSection.hide()
    resultsTitleSoccer.hide()
    reportSectionText.hide();
    reportSectionTextSoccer.hide();
    reportArchetypeSection.hide();
    archetypeLabelText.hide();
    bestScoreTraitImgSoccer.hide();
    demoResults.hide();

    if (app.campaign.isDemo()) {
        demoResults.show();
        $('.result-header').hide();
        $('.result-bestscore-container').hide();
        $('#result-content-intro').hide();
        $('#report-section').hide();
        $('.result-container')[0].style.maxWidth = "880px";
        $('#next > span:nth-child(2)').text("Log Out");
        $('#next').click(() => app.candidate.logout());
        return cb();
    }
    if (app.campaign.isSoccer()) {
        resultsTitle.hide()
        resultsTitleSoccer.show()
        if (!app.campaign.reportAccessIsAllowed() && !app.campaign.reportArchetypeIsAllowed()) {
            reportSectionTextSoccer.show()
            $('#report-section')[0].style.height = "0";
            $('.result-download-footer-website')[0].style.marginTop = "0";
        }
        updateDom();
    }
    if (!app.campaign.isSoccer()) {
        $('#campaign-img').hide()
    }

    if (app.campaign.isPyramid()) {
        $('.result-container')[0].style.maxWidth = "640px";
    }

    if (app.campaign.hasNoTraitModels() && !app.campaign.hasBrainSkillModel()) {
        app.events.fire.navigate('resultscreens2.html');
    }
    if (app.storage.getCampaign().id == 508){
        return app.events.fire.navigate('resultscreens2.html')
    }

    return showResults();

    // ==========================================================================

    function showResults() {
        const candidateId = app.storage.getCandidate().id;
        return app.api.getResults()
            .then((res) => afterGetResults(res))
            .then(() => app.api.polling(() => {
                // after the user plays the games we need to wait for the scores to be computed
                // because we don't have any async mechanism, we do that with polling
                return app.api.getTopTraits(candidateId);
            }, 5000, 120000))
            .then((topTraits) => reloadText(topTraits))
            .catch(() => reloadText())
            .finally(cb);
    };

    function reloadText(topTraits) {
        if (!topTraits) {
            return;
        }
        if (topTraits[0].type === constants.topTraitType.PYRAMID) {
            bestScoreTitle.text(topTraits[0].title || '').show();
            bestScoreDescription.text(topTraits[0].description || '').show();
            if (topTraits[0].imgSrc !== null) {
                bestScoreTraitImg.attr("src", topTraits[0].imgSrc || '').show();
            }
            $('#result-bestscore-brain-img').hide();
        } else {
            showLegacyImage();

            let title = topTraits[0].title;
            let titleSecond = topTraits[1].title;
            let titleThird = topTraits[2].title;

            let description = topTraits[0].description;
            let descriptionSecond = topTraits[1].description;
            let descriptionThird = topTraits[2].description;

            let descriptions = [description, descriptionSecond, descriptionThird];

            let titles = [
                [title.toUpperCase(), title],
                [titleSecond.toUpperCase(), titleSecond],
                [titleThird.toUpperCase(), titleThird]
            ]

            for (let i = 0; i < descriptions.length; i++) {
                for (let j = 0; j < titles[1].length; j++) {
                    descriptions[i] = descriptions[i].replace(titles[i][j], "");
                    if (domHelper.stripHtml(descriptions[i]).startsWith(".")) {
                        descriptions[i] = descriptions[i].replace(".", "");
                    }
                }
            }

            bestScoreTitle.text(topTraits[0].title || '').show();
            bestScoreTitleSecond.text(topTraits[1].title || '').show();
            bestScoreTitleThird.text(topTraits[2].title || '').show();

            bestScoreDescription.text(domHelper.stripHtml(descriptions[0] || '')).show();
            bestScoreDescriptionSecond.text(domHelper.stripHtml(descriptions[1] || '')).show();
            bestScoreDescriptionThird.text(domHelper.stripHtml(descriptions[2] || '')).show();

            const traitImages = [bestScoreTraitImg, bestScoreTraitImgSecond, bestScoreTraitImgThird]
            for (let i = 0; i < traitImages.length; i++) {
                if (topTraits[i].imgSrc !== null) {
                    traitImages[i].attr("src", topTraits[i].imgSrc || '').show();
                }
            }
            if (app.campaign.isSoccer()) {
                bestScoreTraitImgSoccer.attr("src", "static/img/content/soccer_icon.png").show();
            }
            liquid.liquidResultsImages();
        }
    };

    function updateDom() {
        let c = app.storage.getCampaign();
        if (c.code) {
            $('#campaign-img').attr('alt', c.code);
        }
        if (c.logo) {
            $('#campaign-img').attr('src', c.logo);
        }
    }

    function afterGetResults(games) {
        var campaign = app.storage.getCampaign();
        if (!app.helpers.gamesDone(campaign.games, games)) {
            app.events.fire.navigate('game-types.html');
            return;
        }

        if (campaign.demo) {
            $('#next').hide();
        } else {
            $('#next').click(() => app.events.fire.navigate('resultscreens2.html'));
            if (app.campaign.reportArchetypeIsAllowed()) {
                showArchtypeResultsSection();
            }
            if (app.campaign.reportAccessIsAllowed()) {
                showDownloadReportSection();
            }
            if ((app.campaign.reportAccessIsAllowed() || app.campaign.reportArchetypeIsAllowed()) && app.candidate.isEmailKnown()) {
                reportSectionText.show();
            }
        }
    };


    function showDownloadReportSection() {
        $('#report-section-button').click(function() {
            app.ui.startPageLoading();
            app.api.downloadCandidateReport()
                .catch(err => console.error("Can't download the Candidate Report Document: ", err))
                .finally(() => app.ui.stopPageLoading());
        });

        reportSection.show();
    }

    function showArchtypeResultsSection() {
        $('#report-archetype-section-button').click(function() {
            app.ui.startPageLoading();
            app.api.downloadCandidateArchetypeReport()
                .catch(err => console.error("Can't download the Candidate Career Advice Report Document: ", err))
                .finally(() => app.ui.stopPageLoading());
        });

        reportArchetypeSection.show();
    };

    function showLegacyImage() {
        let lang = app.language.getLanguage();
        let isEn = lang === 'en';
        const gameScores = [{
            name: 'WORKING_MEMORY',
            title: isEn ? 'Working memory' : 'Werkgeheugen',
            color: '#47bae3'
        }, {
            name: 'ANTICIPATION',
            title: isEn ? 'Anticipation' : 'Anticipatie',
            color: '#edb400'
        }, {
            name: 'CONTROL',
            title: isEn ? 'Control' : 'Controle',
            color: '#fe1946'
        }, {
            name: 'ATTENTION',
            title: isEn ? 'Attention' : 'Aandacht',
            color: '#03d9af'
        }];
        for (var i = 0; i < gameScores.length; i++) {
            let score = gameScores[i];
            let n = i + 1;
            $('.title-' + n).text(score.title);
            $('.brain-' + n).css('fill', score.color);
        }
    };
};

const updatePartnerLogo = function(cb) {
    $('.result-partner-logo').attr("src", app.storage.getCampaign().logo);
    cb();
};

export default {
    resultscreens1onetopskill: resultScreen,
    resultscreens1: resultScreen,
    resultscreens2: updatePartnerLogo,
};
