
import BaseForm from './BaseForm';
import ui from 'static/js/app/ui/ui';
import ErrorCodes from '../base/api-errors';

//
// This form always appears together with the RegistrationForm (2 tabs)
//
// Show this form when:
//    (open campaign) campaign.registrationClose === false
//    (reg. needed  ) campaign.open              === false
//
class LoginForm extends BaseForm {

    constructor(tabZero, tabReference, buttonReference, campaign) {
        super(tabReference, buttonReference, campaign);

        // first we show the tab zero
        // after pressing the next button we show tabReference
        $('.sign-up-tab').hide();
        $('.sign-up-in-tab-buttons').hide();
        this.tabZero = $(tabZero);
        this.tabZero.find('#tab-0-next-btn').click((e) => this.nextButtonClicked(e));
        this.tabZero.removeClass('start-hidden');
        this.tabZero.addClass('sign-up-tab');
        this.tabZero.show();

        // bind form inputs
        this.emailField = this.tabReference.find('input#emailAddress-sign-in');
        this.passwordField = this.tabReference.find('input#password-sign-in');

        // Last Pass Fix: start disabled
        this.emailField.attr('data-lpignore', true);
        this.passwordField.attr('data-lpignore', true);
    }

    lastPassFix() {
        // Last Pass Fix: toggle the disabled attribute so that last pass does not draw the auto fill icons
        // on the hidden form elements
        // https://stackoverflow.com/a/44984917/797369
        let previous = this.emailField.attr('data-lpignore');
        this.emailField.attr('data-lpignore', !previous);
        this.passwordField.attr('data-lpignore', !previous);
    }

    nextButtonClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        this.tabZero.fadeOut();
        this.tabZero.removeClass('sign-up-tab');
        this.tabZero.hide();
        $('.sign-up-in-tab-buttons').fadeIn();
        $('.sign-up-tab').fadeIn();
    }

    validateForm() {
        let isValid = true;
        if (!(this.validate.required(this.emailField) && this.validate.email(this.emailField))) {
            isValid = false;
        }
        if (!this.validate.required(this.passwordField)) {
            isValid = false;
        }
        return isValid;
    }

    displayApiError(e) {
        let errorCode = -1;
        const haveAnErrorCode = e && e.length > 0 && e[0].code > 0;
        if (haveAnErrorCode) {
            errorCode = e[0].code;
        }
        switch (errorCode) {
            case ErrorCodes.REGISTRATION_NOT_CONFIRMED:
                this.emailField.closest('.form-group').find('p.help-block[data-text=email_confirmationLink_not_used]').addClass(this.errorClass);
                break;
            case ErrorCodes.INVALID_CAMPAIGN:
                this.tabReference.find('.form-group p.help-block[data-text=invalid_campaign]').addClass(this.errorClass);
                break;
            default:
                this.tabReference.find('.form-group p.help-block[data-text=default_error]').addClass(this.errorClass);
        }
    }

    submit() {
        const loginData = {
            campaignId: this.campaign.id,
            username: this.emailField.val(),
            password: this.passwordField.val()
        };
        return app.api.login(loginData)
            .then((res) => app.candidate.loginAndGoHome(res))
            .catch((e) => {
                this.displayApiError(e);
                ui.stopPageLoading();
            });
    }
}

export default LoginForm;
