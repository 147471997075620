let app = window.app || {};
app.state = app.state || {};
app.state.private = app.state.private || {};
app.state.private.eventHandlers = {
    language: []
};
app.state.private.functions = {
    fire: (name, state) => {
        const allHandlers = app.state.private.eventHandlers[name];
        // console.log('Event fired: ', name, ' State: ', state, 'Handlers: ', allHandlers);
        allHandlers && allHandlers.forEach(handler => handler(state));
    },
    subscribe: (name, handler) => {
        // console.log('subscribed to event ' + name + ' with: ' + handler);
        let h = app.state.private.eventHandlers[name] = app.state.private.eventHandlers[name] || []
        let i = h.indexOf(handler);
        if (i === -1) {
            h.push(handler);
        }
    },
};

app.events = {
    fire: {
        languageUpdated: (state) => app.state.private.functions.fire('language', state),
        campaignUpdated: (state) => app.state.private.functions.fire('campaign', state),
        navigate: (state) => app.state.private.functions.fire('navigate', state),
        reloadGameLanguage: (state) => app.state.private.functions.fire('reload-game-lang', state),
    },
    subscribe: {
        languageUpdated: (handler) => app.state.private.functions.subscribe('language', handler),
        campaignUpdated: (handler) => app.state.private.functions.subscribe('campaign', handler),
        navigate: (handler) => app.state.private.functions.subscribe('navigate', handler),
        reloadGameLanguage: (handler) => app.state.private.functions.subscribe('reload-game-lang', handler),
    },
};

export default app.events;
