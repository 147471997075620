import $ from 'jquery';
import Tock from 'tocktimer';
import FastClick from 'fastclick';
import common from 'static/js/app/game/common';
import screenUtils from 'static/js/app/game/screen';
import pauseUtils from 'static/js/app/game/pause';
import mobileUtils from 'static/js/app/game/mobile';

function Game1(parentDiv, langparam, lightversion) {

    var width,
        height,
        pauseswitch = false,
        homeswitch = false,
        TType = -1,
        size, imgr, instr, bigred, wrongred,
        svg,
        counter = 0,
        progress = 0,
        results = [],
        container,
        spaceX, spaceY, xo, yo, overallscore = 0,
        touchAreas = [],
        targetImgs = [],
        actual_conditions = [],
        conditionAmounts = [2, 2, 2, 2, 2, 2, 2, 2],
        conditionErrors = [0, 0, 0, 0, 0, 0, 0, 0],
        conditions = [1, 2, 3, 4, 5, 6, 7, 8],
        bonus = false,
        repeatTrial = false,
        f, gridlength,
        bonusConditions = [1, 2, 3, 4, 5, 6, 7, 8],
        bonusHardAmounts = [],
        bonusEasyAmounts = [],
        progressSteps = [1 / 18, 1 / 18, 1 / 18, 1 / 18, 1 / 18, 1 / 18, 1 / 18, 1 / 18],
        bonusProgressSteps = [1 / 18, 1 / 18, 1 / 18, 1 / 18, 1 / 18, 1 / 18, 1 / 18, 1 / 18],
        championCounter = [0, 0, 0, 0, 0, 0, 0, 0],
        instructions = [],
        errorMsg = false,
        infoMsg = false,
        lastBonus = "hard",
        theend = false,
        resizer = false,
        practiceround = true,
        loaded = false,
        mobileMode = false,
        texts = [".", ".", "Wrong", "Correct", "Too Early"],
        path = "gamedata/game1img/",
        list = [path + "background.png", path + "soccerRed.png", path + "soccerYellow.png", path + "racketRed.png", path + "tablet.png",
            path + "racketYellow.png", path + "powered.png", path + "fout.png", path + "goed.png", path + "soccerRed2.png", path + "racketRed2.png"
        ];
        var rt;

    this.init = function () {
        common.checkIfGameIsPlayed(1, initializeGame);

        function initializeGame() {
            if (lightversion) {
                conditions = [1, 6];
                bonusConditions = [1, 6];
            }
            $(function () {
                FastClick.attach(document.body);
            });
            $(parentDiv).css({
                "overflow": "hidden",
                "position": "absolute",
                "width": "100%"
            });
            $(parentDiv).html("<div id=\"svg-container\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\"> </div>");
            $('head').append('<style type="text/css">#svg-container * {font-family: \'Titillium Web\', sans-serif;}</style>');
            container = $('#svg-container');
            container.svg({});
            svg = container.svg('get');
            common.loadGameLanguageSheet(app.defaults.gameLangSheet, langparam, function (response) {
                setTexts(response);
                onResize();
                onResize(); // executed twice to fix scrollbars
            });
            window.scrollTo(0, 0);
            $('.game-score-box').hide();
            app.events.subscribe.reloadGameLanguage(() => setJSON(app.language.getLanguage()));
        }
    };


    var killAllTimeouts = function () {
        var tid = window.setTimeout(function () {
        }, 0);
        while (tid--) {
            window.clearTimeout(tid);
        }
    }

    var pause = function () {

        // prevent queued events from still showing while pause is active
        killAllTimeouts();

        try {
            $(f).add(keyimgs).add(background).unbind(); // keyimgs is the popup window in general
        } catch (e) {
        }
        try {
            $(instr).remove(); // remove instruction element
        } catch (e) {
        }
        try {
            $(cnum).remove(); // remove countdown element
        } catch (e) {
        }

        pauseswitch = true;
        if (homeswitch) {  // homeswitch is  true while home window is active
            pauseUtils.homemenu(width, height, size, svg, actual_JSON, window.game, '#4fbadf'); // invoke home menu
        } else { // pauseswitch is  true while pause window is active
            pauseUtils.pausemenu(width, height, size, svg, actual_JSON, window.game, '#4fbadf'); // invoke pause menu
        }
    }

    var home = function () {
        homeswitch = true; // if home is active, pause gets activated automatically too
        pause();
    }

    var resume = function () {
        pause();
        pauseswitch = false;
        homeswitch = false;
        try {
            $(keyimgs).remove();
            $(imgr).remove(); // collection of all target/destractor images
            $(instr).remove();
            $(cnum).remove();
            $(wrongred).remove(); // wrongly clicked grid square lighting up red
            $(bigred).remove(); // big red cross that appears in the middle of the screen after a wrong trial
        } catch (e) {
            console.warn("game 1 | try block failed in resume function");
        }
        for (var i = 0; i < targetImgs.length; i++) {
            $(targetImgs[i]).remove();
        }
        for (var i = 0; i < touchAreas.length; i++) {
            $(touchAreas[i]).remove();
        }
        drawField('#0B1C22'); // drawing the 5x5 grid
        screenUtils.updateProgress(progress, overallscore, practiceround, actual_JSON);
        $(menugr).remove();
        pauseUtils.makemenu(size, svg, height, width, path, actual_JSON, window.game);
        errorMsg = false;
        infoMsg = false;
        if (TType === -1) { // targettype, 0 <= x <= 7 for all conditions and -1 if not initialized
            startSimulation();
        } else {
            startStimulus(TType);
        }
    }

    this.invokeResume = function () {
        return resume();
    }

    this.invokePause = function () {
        return pause();
    }

    this.invokeHome = function () {
        return home();
    }

    function setTexts(r) {
        actual_JSON = r.game1; // refers to the JSOn object returned from the google sheet containing game text
        instructions = [actual_JSON.task1, actual_JSON.task1, actual_JSON.task1, actual_JSON.task1, actual_JSON.task1, actual_JSON.task2, actual_JSON.task2, actual_JSON.task2]; // instructions for each of the 8 trial types
        texts[4] = actual_JSON.tooearly;
		if (app.helpers.isMobileOrTablet()) {
			actual_JSON.p1subtext1 = actual_JSON.p1subtext1Touch;
			actual_JSON.p6subtext2 = actual_JSON.p6subtext2Touch;
			actual_JSON.c1error1 = actual_JSON.c1error1Touch;
			actual_JSON.continuetext = actual_JSON.continuetextTouch;
		}
    }

    function setJSON(l) {
        // setting all the game language, texts etc
        common.loadGameLanguageSheet(app.defaults.gameLangSheet, l, function (response) {
            setTexts(response);
            pause();
            screenUtils.updateProgress(progress, overallscore, practiceround, actual_JSON);
        });
    }

    function startSimulation() {
        // top row of csv sheet
        results[0] = ["Trial", "TType", "Used_Objects", "Corrects_Answers", "Response_Code", "Total_RT", "Browser", "OS", "Screen", "Timestamp"];
        screenUtils.updateProgress(progress, overallscore, practiceround, actual_JSON);
        actual_conditions = conditions.concat();
        pauseUtils.makemenu(size, svg, height, width, path, actual_JSON, window.game); // create pause and home buttons on the sides
        document.onkeydown = null; // remove event handlers that might have been bound
        try {
            $(f).add(keyimgs).add(background).unbind();
        } catch (e) {
        }
        drawField("transparent"); // draw Instr Screen, remove existing stuff
        try {
            $(keyimgs).remove(); // remove any popup that was there
        } catch (e) {
        }
        try {
            $(instr).remove() // remove instructions (those that show right when the game starts)
        } catch (e) {
        }
        instr = svg.group({
            'fill': '#eee',
            'font-size': gridlength / (app.helpers.isMobile() ? 11 : 20) + 'px',
            'text-anchor': 'middle'
        });
        svg.text(instr, width / 2, height * (app.helpers.isMobile() ? 0.12 : 0.2), actual_JSON.title1, {
            'font-size': gridlength / (app.helpers.isMobile() ? 9 : 20) + 'px',
        });
        svg.text(instr, width / 2, height * 0.12 + ((app.helpers.isMobile() ? 1.4 : 2.6) * size), actual_JSON.subtitle1, {
            'font-size': gridlength / (app.helpers.isMobile() ? 13 : 32) + 'px',
        });
        svg.image(instr, width / 2 - size * (app.helpers.isMobile() ? 1.5 : 1), height * (app.helpers.isMobile() ? 0.24 : 0.3), size * (app.helpers.isMobile() ? 3 : 2), size * (app.helpers.isMobile() ? 3 : 2), path + "soccerRed.png");
        svg.text(instr, width / 2, height * 0.3 + ((app.helpers.isMobile() ? 3.1 : 2.7) * size), actual_JSON.task1title);
        svg.image(instr, width / 2 - size * (app.helpers.isMobile() ? 1.5 : 1), height * 0.3 + ((app.helpers.isMobile() ? 4.2 : 4) * size), size * (app.helpers.isMobile() ? 3 : 2), size * (app.helpers.isMobile() ? 3 : 2), path + "racketRed.png");
        svg.text(instr, width / 2, height * 0.3 + ((app.helpers.isMobile() ? 8.4 : 6.7) * size), actual_JSON.task2title);

        var size3x = size * (app.helpers.isMobile() ? 2.7 : 1);

        svg.polygon(instr, [
            [width / 2 - 3.4 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 8.8 : 8.3) * size)],
            [width / 2 + 3 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 8.8 : 8.3) * size)],
            [width / 2 + 3.3 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 10 : 9) * size)],
            [width / 2 + 3 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 11.2 : 9.7) * size)],
            [width / 2 - 3.4 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 11.2 : 9.7) * size)],
            [width / 2 - 3.1 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 10 : 9) * size)]
        ], {
            fill: '#4fbadf',
            class: 'poligon-btn'
        });
        svg.text(instr, width / 2, height * 0.35 + ((app.helpers.isMobile() ? 10.5 : 9.18) * size), " " + actual_JSON.start + " ", {
            'font-size': size / (app.helpers.isMobile() ? 0.7 : 1.9) + 'px',
            'font-weight': 'bold',
            cursor: 'pointer'
        });
        $('.poligon-btn').bind("click", function () {
            $(instr).remove();
            screenUtils.countDown(svg, 2, width, height); // 2 second countdown
            setTimeout(function (e) {
                $('.game-score-box').show();
                changeFieldcol('#0B1C22');
                setTimeout(function (e) {
                    if (app.helpers.isMobileOrTablet()) {
                        setTimeout(function () {
                            startStimulus(actual_conditions[0]);
                        }, 250);
                    } else {
                        startStimulus(actual_conditions[0]);
                    }
                }, 500);
            }, 2000);
        });
    }

    function startStimulus(tt) {
        const practiceRound = (conditionAmounts[TType - 1] == 2) && !bonus;
        imgr = svg.group();
        TType = tt;
        var xx = 0,
            changePos, change,
            timer = new Tock({}),
            correct_Answers = 0,
            targets = [],
            answerList = [],
            distImgs = [],
            fbImgs = [],
            distractors = [],
            orientations = [];
        resizer = false;
        counter++;

        function createImg(xPosition, yPosition, rotationFactor, imageName) {
            return svg.image(imgr, 3 + xo + (spaceX * xPosition), yo + .15 * size + (spaceY * yPosition), spaceX * 0.9, spaceY * 0.9, path + imageName, {
                transform: 'rotate(' + (rotationFactor * 90) + ',' + (3 + xo + (spaceX * xPosition) + (spaceX * 0.45)) + ',' + (yo + .15 * size + (spaceY * yPosition) + (spaceY * 0.45)) + ')'
            });
        }

        var xt = 0; // extra time to make up for feedback about errors
        if (!errorMsg) {
            if (practiceRound) { // if practice round
                setTimeout(function () {
                    changeFieldcol('#386873', 0);
                    if (TType != 6) {
                        screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON["p" + (TType) + "text"], actual_JSON["p" + (TType) + "subtext"], actual_JSON["p" + (TType) + "subtext1"], 4000000, '#fff', '#4fbadf', '#eee');
                        $(f).add(keyimgs).add(background).click(function () {
                            $(f).add(keyimgs).add(background).unbind(); // prevent starting the trial twice
                            $(keyimgs).remove(); // remove all old stuff
                            setTimeout(function () {
                                runTrial();
                            }, 250);
                        });
                    } else { // except when we go from game 1 to game 2, we show two popups
                        screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON["p6text"], actual_JSON["p6subtext"], actual_JSON["p6subtext1"], 5000000, '#fff', '#4fbadf', '#eee');
                        $(f).add(keyimgs).add(background).click(function () {
                            $(keyimgs).remove();
                            screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON["p6text"], actual_JSON["p6subtext2"], actual_JSON["p6subtext3"], 5000000, '#fff', '#4fbadf', '#eee');
                            $(f).add(keyimgs).add(background).click(function () {
                                $(f).add(keyimgs).add(background).unbind();
                                $(keyimgs).remove();
                                setTimeout(function () {
                                    runTrial();
                                }, 250);
                            });
                        });
                    }
                }, 200);
                xt += 4500; // TODO: does this do anything?
            } else { // if not pratice round
                setTimeout(function () {
                    changeFieldcol('#386873', 0);
                    screenUtils.showInfo(actual_JSON, svg, gridlength, width, height, instructions[TType - 1], 1500, '#fff', '#4fbadf');
                }, 200);
                xt = 2000;
                setTimeout(function () {
                    runTrial();
                }, xt);
            }
        } else {
            runTrial();
        }

        // create Target Array,
        function runTrial() {
            errorMsg = false; // flippin' the switch
            var x = Math.floor(Math.random() * 25);
            targets.push(x);
            for (var i = 1; i < conditionAmounts[TType - 1]; i++) { // for each item (number of items == difficulty)
                while ($.inArray(x, targets) != -1) {
                    x = Math.floor(Math.random() * 25); // keep finding for an index that hasn't been used yet
                }
                targets.push(x);
            }
            x = Math.floor(Math.random() * 25);
            for (var i = 1; i <= conditionAmounts[TType - 1]; i++) { // also pick indices for distractors, regardless of whether distractors are necessary
                while ($.inArray(x, targets) != -1 || $.inArray(x, distractors) != -1) {
                    x = Math.floor(Math.random() * 25);
                }
                distractors.push(x);
            }
            //conditions ------------------------------------------------------------------------
            if (TType > 5) { // change detection
                do { // check if no 2 subsequent orientations
                    orientations = [];
                    for (var i = 0; i < targets.length; i++) {
                        orientations.push(Math.floor(Math.random() * 4));
                    }
                } while (!app.helpers.verificar(orientations));

                for (var i = 0; i < targets.length; i++) { // draw all targets
                    let Ypos = Math.floor(targets[i] / 5);
                    let Xpos = targets[i] - (Ypos * 5);
                    targetImgs[i] = createImg(Xpos, Ypos, orientations[i], 'racketRed.png');
                }
                if (TType == 7) {
                    for (var i = 0; i < distractors.length; i++) {
                        let Ypos = Math.floor(distractors[i] / 5);
                        let Xpos = distractors[i] - (Ypos * 5);
                        let dori = Math.floor(Math.random() * 4); // Distractor ORIentation
                        distImgs[i] = createImg(Xpos, Ypos, dori, 'racketYellow.png');
                    }
                }
                setTimeout(function () {
                    for (var i = 0; i < targetImgs.length; i++) {
                        $(targetImgs[i]).remove();
                        $(distImgs[i]).remove();
                    }
                    if (TType == 8) {
                        for (var i = 0; i < distractors.length; i++) {
                            let Ypos = Math.floor(distractors[i] / 5);
                            let Xpos = distractors[i] - (Ypos * 5);
                            let dori = Math.floor(Math.random() * 4); // Distractor ORIentation
                            distImgs[i] = createImg(Xpos, Ypos, dori, 'racketYellow.png');

                        }
                    }
                    setTimeout(function () {
                        for (var i = 0; i < targetImgs.length; i++) {
                            $(distImgs[i]).remove();
                        }
                        change = (Math.floor(Math.random() * orientations.length)); // randomly picked index or orientation to change
                        var x = orientations[change];

                        let changeto = 0;
                        do { // new orientation that's not equal to the old one or old one * 180deg
                            changeto = Math.floor(Math.random() * 4);
                        } while (changeto == x || Math.abs(changeto - x) == 2);
                        orientations[change] = changeto;

                        createTouchAreas();
                        for (var i = 0; i < touchAreas.length; i++) {
                            $(touchAreas[i]).remove();
                        }
                        for (var i = 0; i < targets.length; i++) {
                            let Ypos = Math.floor(targets[i] / 5);
                            let Xpos = targets[i] - (Ypos * 5);
                            targetImgs[targets[i]] = createImg(Xpos, Ypos, orientations[i], 'racketRed.png');

                            $(targetImgs[targets[i]]).bind('click', function (e) { // click
                                e.preventDefault();
                                checkAnswer(targetImgs.indexOf(this));
                            });
                        }
                        changePos = change;
                        change = targets[change];
                        targets = [];
                        targets.push(change);
                    }, 1000);
                }, 500);
            } else if (TType > 3) { // sequential WM cap (TType 4 and 5)
                var j = 0;
                var k = 0;

                function drawOneDist() {
                    let Ypos = Math.floor(distractors[k] / 5);
                    let Xpos = distractors[k] - (Ypos * 5);
                    distImgs[k] = createImg(Xpos, Ypos, 0, 'soccerYellow.png');
                    k++;
                    setTimeout(function () {
                        $(distImgs[k - 1]).remove();
                        if (k < targets.length) {
                            drawOneTarget();
                        } else {
                            createTouchAreas();
                        }
                    }, 500);
                }

                function drawOneTarget() {
                    let Ypos = Math.floor(targets[j] / 5);
                    let Xpos = targets[j] - (Ypos * 5);
                    targetImgs[j] = createImg(Xpos, Ypos, 0, 'soccerRed.png');
                    j++;
                    setTimeout(function () {
                        $(targetImgs[j - 1]).remove();
                        // draw target, distractor, target, distractor, target, distractor...
                        if (j <= targets.length && TType == 5) {
                            drawOneDist();
                        } else if (j < targets.length) {
                            drawOneTarget();
                        } else {
                            createTouchAreas();
                        }
                    }, 500);
                };

                drawOneTarget();
            } else { // simultaneous (( TType 1, 2 and 3)
                var extraTime = 0;
                for (var i = 0; i < targets.length; i++) {
                    let Ypos = Math.floor(targets[i] / 5);
                    let Xpos = targets[i] - (Ypos * 5);
                    targetImgs[i] = createImg(Xpos, Ypos, 0, 'soccerRed.png');
                }
                if (TType == 2) {
                    for (var i = 0; i < distractors.length; i++) {
                        let Ypos = Math.floor(distractors[i] / 5);
                        let Xpos = distractors[i] - (Ypos * 5);
                        distImgs[i] = createImg(Xpos, Ypos, 0, 'soccerYellow.png');
                    }
                }
                if (TType == 3) {
                    extraTime = 500;
                    setTimeout(function () {
                        for (var i = 0; i < targetImgs.length; i++) {
                            $(targetImgs[i]).remove();
                        }
                        for (var i = 0; i < distractors.length; i++) {
                            let Ypos = Math.floor(distractors[i] / 5);
                            let Xpos = distractors[i] - (Ypos * 5);
                            distImgs[i] = createImg(Xpos, Ypos, 0, 'soccerYellow.png');
                        }
                    }, 500); // 500ms grace period for showing the distractor
                }
                setTimeout(function () {
                    for (var i = 0; i < targetImgs.length; i++) {
                        $(targetImgs[i]).remove();
                        $(distImgs[i]).remove();
                    }
                    createTouchAreas();
                }, 500 + extraTime);
            }
        }

        // create 25 touchAreas, also starts timer
        function createTouchAreas() {
            for (var i = 0; i < touchAreas.length; i++) {
                $(touchAreas[i]).remove();
            }
            for (var j = 0; j < 5; j++) {
                for (var i = 0; i < 5; i++) {
                    touchAreas[i + (j * 5)] = svg.rect(xo + (spaceX * i), yo + (spaceY * j), spaceX, spaceY, {
                        'fill': '#183843',
                        'opacity': 0.0
                    });
                    $(touchAreas[i + (j * 5)]).bind('click', function (e) {
                        e.preventDefault();
                        $(touchAreas[touchAreas.indexOf(this)]).remove();
                        checkAnswer(touchAreas.indexOf(this));
                    });
                }
            }
            timer.start();
        }

        // check Answer
        // arg: answer: index of the clicked square in the grid
        function checkAnswer(answer) {
            rt = 0;
            var xx2 = timer.lap();
            if (xx2 - xx <= 100) { // if too early
                repeatTrial = true;
                for (var i = 0; i < touchAreas.length; i++) {
                    $(touchAreas[i]).unbind('touchstart mousedown').remove();
                    $(touchAreas[i]).remove();
                }
                endTrial();
            } else {
                repeatTrial = false;
                xx = xx2;
                for (var i = 0; i < targetImgs.length; i++) {
                    $(targetImgs[i]).remove();
                }
                //answerList = targets; !!!!!
                answerList.push(answer);
                let Ypos = Math.floor(answer / 5);
                let Xpos = answer - (Ypos * 5);
                if ($.inArray(answer, targets) != -1) { // if correct
                    if (TType > 5) { // if correct and change detection
                        fbImgs[answerList.length - 1] = createImg(Xpos, Ypos, orientations[changePos], 'racketRed.png');

                    } else { // if correct and wm_cap
                        fbImgs[answerList.length - 1] = createImg(Xpos, Ypos, 0, 'soccerRed.png');
                    }
                    if ((answerList.length == targets.length)) { // if this was the last answer
                        for (var i = 0; i < touchAreas.length; i++) {
                            $(touchAreas[i]).unbind('touchstart mousedown').remove();
                            $(touchAreas[i]).remove();
                        }
                        rt = timer.lap();
                        timer.stop();
                        setTimeout(function () {
                            endTrial();
                        }, 400);
                    }
                } else {
                    wrongred = svg.rect(xo + (spaceX * Xpos + size / 12), yo + (spaceY * Ypos) + size / 12, spaceX - size / 6, spaceY - size / 6, {
                        'fill': 'red',
                        'opacity': 0.3
                    });
                    b = false;
                    setTimeout(function () {
                        bigred = svg.image(imgr, xo + 1.5 * spaceX, yo + 1.5 * spaceY, spaceX * 2, spaceY * 2, path + 'fout.png');
                    }, 400);
                    for (var i = 0; i < touchAreas.length; i++) {
                        $(touchAreas[i]).unbind('touchstart mousedown').remove();
                        $(touchAreas[i]).remove();
                    }
                    rt = timer.lap();
                    timer.stop();
                    setTimeout(function () {
                        endTrial();
                    }, 800);
                }
            }
        }

        // check all Answers
        function endTrial() {
            let feedbackTime = 500;
            if (!repeatTrial) {
                // true when not too early
                $(bigred).remove();
                var feedbackText = texts[3];
                for (var i = 0; i < answerList.length; i++) {
                    if ($.inArray(answerList[i], targets) == -1) {
                        feedbackText = texts[2];
                    } else {
                        correct_Answers++;
                    }
                }
            } else {
                for (var i = 0; i < fbImgs.length; i++) {
                    $(fbImgs[i]).remove();
                }
                feedbackText = texts[4];
                feedbackTime = 1500;
            }
            showFeedback(feedbackText, feedbackTime);
        }

        /* args:
            s = feedback to echo to user
            t = time to show
         */
        function showFeedback(s, t) {
            infoMsg = false;
            let showHintTime = 500;
            let resultstring = [counter.toString(), TType.toString(), conditionAmounts[TType - 1].toString(), correct_Answers.toString(), s, rt.toString(), app.helpers.getBrowserName(), navigator.platform, screen.width + 'x' + screen.height, app.helpers.getTimestamp() ];

            if (s == texts[3]) { // correct
                t = t + 800;
                if ((conditionAmounts[TType - 1] == 2) && !bonus) { // if practice stage
                    if (lightversion && !bonus && TType == 6) { // switch to bonus game immediately during light version
                        practiceround = false;
                        infoMsg = true;
                        bonusHardAmounts = [10, 10, 10, 10, 10, 10, 10, 10];
                        bonusEasyAmounts = [4, 4, 4, 4, 4, 4, 4, 4];

                        setTimeout(function () {
                            bonus = true;
                            screenUtils.showInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.welldone, 3000000, '#fff', '#4fbadf', '#fff');
                            $(f).add(keyimgs).add(background).click(function () {
                                $(f).add(keyimgs).add(background).unbind();
                                $(keyimgs).remove();
                                t = 300;
                                showHintTime = 0;
                                finalizeTrial();
                            });
                        }, 1500);
                    }

                    if (TType == 5) { // sequential with distractor
                        infoMsg = true;
                        setTimeout(function () {
                            screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.welldone, actual_JSON.secondtask, "", 3000000, '#fff', '#4fbadf', '#fff');
                            if (!lightversion)
                                progress += (1 / 18);
                            screenUtils.updateProgress(progress, overallscore, practiceround, actual_JSON);
                            $(f).add(keyimgs).add(background).click(function () {
                                $(f).add(keyimgs).add(background).unbind();
                                $(keyimgs).remove();
                                t = 300;
                                showHintTime = 0;
                                finalizeTrial();
                            });
                        }, 1500);
                    }
                    if (TType == 8) { // if the last condition was succesfully completed during the practice round..
                        practiceround = false;
                        infoMsg = true;
                        setTimeout(function () {
                            screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.welldone, actual_JSON.realgame, actual_JSON.realgame1, 3000000, '#fff', '#4fbadf', '#fff');
                            if (!lightversion)
                                progress += (1 / 18);
                            screenUtils.updateProgress(progress, overallscore, practiceround, actual_JSON);
                            $(f).add(keyimgs).add(background).click(function () {
                                $(keyimgs).remove();
                                screenUtils.showInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.realgame2, 3000000, '#fff', '#4fbadf');
                                $(f).add(keyimgs).add(background).click(function () {
                                    $(keyimgs).remove();
                                    $(f).add(keyimgs).add(background).unbind();
                                    t = 300;
                                    showHintTime = 0;
                                    finalizeTrial();
                                });
                            });
                        }, 1500);
                    }
                }
                showHintTime = 0;
                if (bonus && lastBonus == "easy") { // if correct trial and easy bonus trial
                    bonusEasyAmounts[TType - 1]++;
                }
                let good = svg.image(imgr, xo + 1.5 * spaceX, yo + 1.5 * spaceY, spaceX * 2, spaceY * 2, path + 'goed.png');
                $(good).css("z-index", "999");
                setTimeout(function (e) {
                    $(good).remove();
                    changeFieldcol('#0B1C22', 300);
                    for (var i = 0; i < fbImgs.length; i++) {
                        $(fbImgs[i]).remove();
                    }
                    if ((conditionAmounts[TType - 1] != 2) || bonus) {

                        overallscore += conditionAmounts[TType - 1] * 100; // if correct, score += amount of shown items * 100
                        screenUtils.updateProgress(progress, overallscore, practiceround, actual_JSON);
                    }
                }, 600);
            }
            // too early
            if (s == texts[4]) {
                for (var i = 0; i < 25; i++) {
                    $(targetImgs[i]).remove();
                    $(distImgs[i]).remove();
                }
                var msgTime = 1500;
                if (resizer) {
                    msgTime = 0;
                }
                changeFieldcol('#0B1C22', 300);
                screenUtils.showInfo(actual_JSON, svg, gridlength, width, height, s, msgTime, "red", "transparent");
            }

            // Incorrect
            if (s == texts[2]) {
                t = t + 200;
                if ((conditionAmounts[TType - 1] == 2) && !bonus) {
                    errorMsg = true;
                    repeatTrial = true;
                    setTimeout(function (e) {
                        changeFieldcol('#386873', 0);
                        if (TType < 6) { // for wm_cap
                            var errtext = actual_JSON.c1error1;
                            if (($.inArray(answerList[answerList.length - 1], distractors) != -1) && ((TType == 2) || (TType == 3) || (TType == 5))) {
                                errtext = actual_JSON.c1error2;
                            }
                            screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.c1errorheader, actual_JSON.c1error, errtext, 2500000, '#fff', '#4fbadf', '#eee');
                            // manually trigger click event
                            $(window).click();
                        } else { // for change detection
                            screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.c2errorheader, actual_JSON.c2error, actual_JSON.c2error1, 2500000, '#fff', '#4fbadf', '#eee');
                        }
                        $(f).add(keyimgs).add(background).click(function () {
                            $(f).add(keyimgs).add(background).unbind();
                            $(keyimgs).remove();
                            t = 300;
                            showHintTime = 0;
                            finalizeTrial();
                        });
                    }, 2000);
                }
                setTimeout(function () {
                    for (var i = 0; i < targets.length; i++) { // show where you should have clicked
                        let Ypos = Math.floor(targets[i] / 5);
                        let Xpos = targets[i] - (Ypos * 5);
                        if ($.inArray(targets[i], answerList) == -1) {
                            if (TType < 6) {
                                targetImgs[i] = createImg(Xpos, Ypos, 0, 'soccerRed2.png');
                            } else {
                                targetImgs[i] = createImg(Xpos, Ypos, orientations[changePos], 'racketRed2.png');
                            }
                        }
                    }
                    setTimeout(function () {
                        changeFieldcol('#0B1C22', 300);
                        for (var i = 0; i < fbImgs.length; i++) {
                            $(fbImgs[i]).remove();
                        }
                        $(wrongred).remove();
                        for (var i = 0; i < targetImgs.length; i++) {
                            $(targetImgs[i]).remove();
                        }
                        if ((conditionAmounts[TType - 1] != 2) || bonus) {
                            overallscore += correct_Answers * 100;
                            if (lightversion && !bonus)
                                overallscore = 0;

                            screenUtils.updateProgress(progress, overallscore, practiceround, actual_JSON);
                        }
                        if (bonus && lastBonus == "hard") {
                            bonusHardAmounts[TType - 1]--;
                        }
                    }, showHintTime + 150);
                }, -150);
            }
            if (!errorMsg && !infoMsg) {
                finalizeTrial();
            }

            function finalizeTrial() {

                setTimeout(function () {
                    if (repeatTrial) {
                        startStimulus(TType);
                    } else {
                        screenUtils.updateProgress(progress, overallscore, practiceround, actual_JSON);

                        // normal round
                        if (!bonus) {

                            if (s == texts[3]) { // correct
                                conditionAmounts[TType - 1] = Math.min(conditionAmounts[TType - 1] + 2, 12);
                                conditionErrors[TType - 1] = 0;

                                //if solved correct 3 times with 12
                                if (conditionAmounts[TType - 1] == 12) {
                                    championCounter[TType - 1]++;
                                } //drop condition
                                if (championCounter[TType - 1] > 3) {
                                    conditionErrors[TType - 1] = 3; // hacky condition drop
                                }

                                // if not immediately after practice
                                if (!practiceround && !(TType === 8 && conditionAmounts[TType - 1] === 4)) {
                                    progressSteps[TType - 1] = Math.max(0, progressSteps[TType - 1] - 1 / 144);
                                    if (progressSteps[TType - 1] > 0) {
                                        progress += (1 / 144);
                                    }
                                    screenUtils.updateProgress(progress, overallscore, practiceround, actual_JSON);
                                }
                            }
                            if (s == texts[2]) { // wrong
                                conditionErrors[TType - 1]++;
                            }
                            if (conditionErrors[TType - 1] >= 2) { // failed 2 times
                                conditions.splice(conditions.indexOf(TType), 1);

                                progress += progressSteps[TType - 1];

                                screenUtils.updateProgress(progress, overallscore, practiceround, actual_JSON);
                            }
                            //drop condition
                            actual_conditions.splice(0, 1);
                            if (actual_conditions.length == 0) {

                                //next condition
                                if (conditions.length > 0) {
                                    actual_conditions.push.apply(actual_conditions, conditions);

                                } else {
                                    //go to bonus
                                    screenUtils.showInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.bonus, 1600, '#fff', '#4fbadf');
                                    for (var i = 0; i < conditionAmounts.length; i++) {
                                        bonusEasyAmounts[i] = Math.max(conditionAmounts[i] - 3, 2);
                                        bonusHardAmounts[i] = Math.min(conditionAmounts[i] + 1, 12);
                                    }
                                    bonus = true;
                                    setTimeout(function () {
                                        conditionAmounts = [];
                                        conditionAmounts.push.apply(conditionAmounts, bonusEasyAmounts);
                                        lastBonus = "easy";
                                        startStimulus(bonusConditions[0]);
                                    }, 1700);
                                }
                            }
                            if (!bonus) {
                                startStimulus(actual_conditions[0]);
                            }

                            //Bonus round
                        } else {
                            conditionAmounts = [];
                            if (lastBonus == "hard") {
                                conditionAmounts.push.apply(conditionAmounts, bonusEasyAmounts);
                                lastBonus = "easy";
                            } else {
                                conditionAmounts.push.apply(conditionAmounts, bonusHardAmounts);
                                lastBonus = "hard";
                            }
                            if (s == texts[3]) { // correct
                                bonusProgressSteps[TType - 1] = Math.max(0, bonusProgressSteps[TType - 1] - 1 / 144);
                                if (bonusProgressSteps[TType - 1] > 0) {
                                    progress += (1 / 144);
                                }
                                screenUtils.updateProgress(progress, overallscore, practiceround, actual_JSON);
                            }

                            //Condition finally dropped

                            if (bonusHardAmounts[TType - 1] == bonusEasyAmounts[TType - 1]) {
                                bonusConditions.splice(0, 1);
                                if (!lightversion) {
                                    progress += bonusProgressSteps[TType - 1];
                                }
                                screenUtils.updateProgress(progress, overallscore, practiceround, actual_JSON);
                                conditionAmounts = [];
                                conditionAmounts.push.apply(conditionAmounts, bonusEasyAmounts);
                                lastBonus = "easy";
                            }
                            if (lightversion) {
                                progress = Math.max(progress, (1 -
                                    (bonusHardAmounts[0] - bonusEasyAmounts[0]) / 14 -
                                    (bonusHardAmounts[5] - bonusEasyAmounts[5]) / 14)
                                );
                                screenUtils.updateProgress(progress, overallscore, practiceround, actual_JSON);
                            }
                            if (bonusConditions.length == 0) {
                                theend = true;
                                progress = 1;
                                screenUtils.updateProgress(progress, overallscore, practiceround, actual_JSON);
                            } else {
                                startStimulus(bonusConditions[0]);
                            }
                        }
                    }

                    results.push(resultstring);
                    if (theend) {
                        screenUtils.showResults(1, overallscore, container, parentDiv, results);
                    }
                }, t + (2 * showHintTime));
            }
        }
    }

    function onResize() {
        width = screenUtils.getWindowWidthOrMin();
        height = screenUtils.getWindowHeightOrMin(parentDiv);
        size = Math.min(width, height) / 20;
        svg.configure({
            width: '100%',
            height: height
        });
        $(parentDiv).css("height", height + "px");
        var callback = function () {
            resizer = true;
            repeatTrial = true;
            for (var i = 0; i < targetImgs.length; i++) {
                $(targetImgs[i]).trigger('mousedown');
            }
            $(touchAreas[1]).trigger('mousedown');
            try {
                $(background).remove();
            } catch (err) {
            }
            if (width / height >= 1) {
                background = svg.image(0, 0, height, height, path + 'background.png', {
                    transform: 'scale(' + width / height +
                    ', ' + 1 + ')'
                });
            } else {
                background = svg.image(0, 0, width, width, path + 'background.png', {
                    transform: 'scale(' + 1 + ', ' + 1 / (width / height) + ')'
                });
            }
            if (TType != -1) {
                drawField('#0B1C22');
            }
            screenUtils.updateProgress(progress, overallscore, practiceround, actual_JSON);
            pauseUtils.makemenu(size, svg, height, width, path, actual_JSON, window.game);
            try {
                $(powered).remove();
            } catch (err) {
            }
            powered = svg.image(width - 214, height - 40, 214, 40, path + 'powered.png', {
                cursor: "pointer"
            });
            $(powered).on("click", function () {
                if (!app.helpers.isMobileOrTablet()) {
                    window.open('http://brainsfirst.com/', '_blank');
                }
            });
            if (app.helpers.isMobile()) {
                $(powered).remove();
            }
            if (pauseswitch) {
                pause();
            } else {
                pause();
                if (!mobileMode && app.helpers.isMobile()) {
                    mobileUtils.activateMobileMode();
                    mobileMode = true;
                    resume();
                } else {
                    resume();
                }
            }

            if (app.helpers.checkDeviceOrientation()) {

                $(window).blur(function () {
                    try {
                        pause();
                    } catch (e) {
                    }
                });

                $('.header').add('.subheader *').unbind().click(function () {
                    pause();
                });

                $('.logo, .logo img ').unbind().click(function (e) {
                    home();
                    e.preventDefault();
                    return false;
                });
            }
        };
        loaded = screenUtils.checkedAndLoaded(loaded, path, list, svg, width, height, size, callback, actual_JSON);
        if (!loaded && TType > -1) {
            pauseswitch = true;
        }
    }

    function changeFieldcol(fieldcol, t) {
        $(f).css("transition", "all " + t + "ms");
        $(f).css("stroke", fieldcol);
    }

    function drawField(fieldcol) {
        try {
            $(f).remove();
        } catch (err) {
        }
        f = svg.group({
            stroke: fieldcol
        });
        var linewidth = 6;
        gridlength = Math.min(Math.min(width - (2 * size), 600), Math.min(height - (6 * size), 600));
        xo = (width / 2) - gridlength / 2;
        yo = (height / 2) - gridlength / 2;
        var main = svg.rect(f, xo, yo, gridlength, gridlength, {
            fill: 'transparent',
            strokeWidth: size / linewidth
        });
        $(main).bind('touchmove', function (e) {
            e.preventDefault();
        });
        spaceX = spaceY = gridlength / 5;
        for (var i = 1; i < 5; i++) {
            svg.line(f, xo + (i * spaceX), yo, xo + (i * spaceX), parseInt(main.height.animVal.valueAsString) + yo, {
                strokeWidth: size / linewidth
            });
            svg.line(f, xo, (yo) + (spaceY * i), xo + gridlength, yo + (spaceY * i), {
                strokeWidth: size / linewidth
            }); //horizontal lines
        }
    }

    $(window).resize(function () {
        onResize();
        onResize();
        if (app.helpers.isMobile()) {
            mobileUtils.fullscreenCheck();
        }
    });

    this.initDummy = function () {
        $(parentDiv).css({
            "overflow": "hidden",
            "position": "absolute",
            "width": "100%"
        });
        $(parentDiv).html("<div id=\"svg-container\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\"> </div>");
        $('head').append('<style type="text/css">#svg-container * {font-family: \'Titillium Web\', sans-serif;}</style>');
        container = $('#svg-container');
        container.svg({});
        svg = container.svg('get');

        results[0] = ["Trial", "TType", "Used_Objects", "Corrects_Answers", "Response_Code", "Total_RT", "Browser", "OS", "Screen", "Timestamp"];
        for (var i = 0; i < 100; i++) {
            results.push([i, 2, 3, 4, 666, 555, app.helpers.getBrowserName(), navigator.platform, screen.width + 'x' + screen.height, app.helpers.getTimestamp() ]);
        }

        screenUtils.showResults(1, 666, container, parentDiv, results);
    }
}

export default Game1;
