import $ from 'jquery';
import storage from '../../js/app/base/storage';

let isLiquid = storage.getCampaign() ? (storage.getCampaign().partner || {}).id === 151 : null;

const liquidResults = function () {
    if (isLiquid) {
        $('.result-container').addClass("liquid-results");
    }
}
const liquidResultsImages = function () {
    if (isLiquid) {
        $('.result-bestscore-trait-img').attr("src", "static/img/content/logo_liquid.png")
    }
}

const liquidStyle = function () {
    if (isLiquid) {
        if (!$('body').hasClass('liquid')) {
            $('body').addClass('liquid')
            $('html').addClass('liquid-html')
            $('.splash-screen').addClass('liquid-index');
            if (!storage.getCampaign().registrationClose) {
                $('.splash-screen').addClass('liquid-open');
                $('.action').addClass('liquid-btn');
                $('.action').addClass('btn-primary');
                $('.action').addClass('tinybutton');
                $('.action-login').addClass('liquid-btn');
                $('.action-login').addClass('btn-primary');
                $('.action-login').addClass('tinybutton');
                $('.account-created-popup').addClass('liquid-popup');
            }
            $('#autofill-submit').addClass('tinybutton');
            $('#autofill-submit').addClass('liquid-btn');
            $('#browserErrorBtn').addClass('liquid-btn');
            $('.btn').addClass('liquid-btn');
        }
    }
}

export default {
    liquidResults,
    liquidResultsImages,
    liquidStyle
};
