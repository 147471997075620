import $ from 'jquery';
import constants from "../../base/constants";
import * as Sentry from "@sentry/browser";

const remoteWork = function (cb) {
    initDomElements();
    return validateGamesCompleted();

    // ==========================================================================

    function validateGamesCompleted() {
        return app.api.getResults()
            .then((games) => {
                // loading the page without games completed, sends the user back
                var campaign = app.storage.getCampaign();
                if (!app.helpers.gamesDone(campaign.games, games)) {
                    app.events.fire.navigate('game-types.html');
                    return;
                }
            })
            .finally(cb)
            .then(triggerGtmView);
    };

    function initDomElements() {
        showReportButton();
        shareOnLinkedIn();
    }

    function showReportButton() {
        let reportSection = $('#report-section');
        reportSection.hide();
        if (!app.campaign.reportAccessIsAllowed()) {
            return;
        }
        $('#report-section-button').click(function () {
            app.ui.startPageLoading();
            app.api.downloadCandidateReport()
                .catch(err => console.error("Can't download the Candidate Report Document: ", err))
                .finally(() => app.ui.stopPageLoading());
        });
        reportSection.show();
    };

    function shareOnLinkedIn() {
        let base = window.location.host;
        const queryString = new URLSearchParams({
            mini: true,
            url: `https://${base}/campaign/work-from-anywhere.html?nounce=hf1esf4e5fsdi`
        });
        let url = `https://www.linkedin.com/shareArticle?${queryString.toString()}`;
        $('.btn-share-linkedin > a').attr("href", url);
        $('.btn-share-linkedin > a').click(triggerGtmLinkedIn);
    };

    function wrap(name, cb) {
        try {
            cb();
        } catch (e) {
            Sentry.captureMessage(`Can't track GTM event ('${name}')`, e)
        }
    };

    function triggerGtmView() {
        const name = constants.remoteWork.googleTagManagerEvents.viewResults;
        wrap(name, function () {
            window.dataLayer.push({
                'event': name
            });
        });
    };

    function triggerGtmLinkedIn() {
        const name = constants.remoteWork.googleTagManagerEvents.shareResults;
        wrap(name, function () {
            window.dataLayer.push({
                'event': name
            });
        });
    };
};

export default remoteWork;
