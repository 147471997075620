import $ from 'jquery';
import common from 'static/js/app/game/common';

var contBg;
var continueText;
var interval;

function checkedAndLoaded(loaded, path, list, svg, width, height, size, callback, actual_JSON) {
    function runCB() {
        $('.subheader *').click(function (e) {
            return false;
        });
        callback();
    }

    if (loaded) {
        runCB();
        return true;
    } else {
        try {
            $(background).remove();
        } catch (err) {
        }
        background = svg.rect(0, 0, 10000, 10000, { fill: 'black' });
        if (app.helpers.checkDeviceOrientation() == false) {
            let gridlength = Math.min(Math.min(width - (2 * size), 600), Math.min(height - (6 * size), 600));
            if (app.helpers.isMobile()) {
                gridlength /= 1.5;
            }
            showImageInfo(path, null, svg, gridlength, width, height, actual_JSON.device1, actual_JSON.device2, 5000000, "tablet.png");
            return false;
        } else {
            if (!loaded) {
                try {
                    $(background).remove();
                    $(ltext).remove();
                } catch (err) {
                }
                var listlength = list.length;
                loadOne();

                function loadOne() {
                    if (list.length > 1) {
                        var img = svg.load(list[0], {
                            addTo: svg,
                            onLoad: function () {
                                $(img).remove();
                                img = svg.image(0, 0, 1, 1, list[0]);
                                $(img).remove();
                                try {
                                    $(ltext).remove();
                                    $(background).remove();
                                } catch (e) {
                                }
                                list.splice(0, 1);
                                background = svg.rect(0, 0, 10000, 10000, {
                                    fill: 'black'
                                });
                                ltext = svg.text(width / 2, height / 1.93, "loading.. " + ((1 - (list.length / listlength)) * 100).toFixed(0) + " %", {
                                    'fill': '#eee',
                                    'font-size': 16 + 'px',
                                    'text-anchor': 'middle'
                                });
                                loadOne();
                            }
                        });
                    } else {
                        runCB();
                        return true;
                    }
                }
            }
        }
    }
}

function getWindowWidthOrMin() {
    return Math.max($(window).width(), 0);
}

function getWindowHeightOrMin(par) {
    var a = Math.max($(window).height(), 0);
    var b = $(par).position().top;
    return (a - b);
}

function updateProgress(p, s, practiceRound, actual_JSON, practiceRound2 = false) {
    if (p >= 1) {
        p = 1;
    }
    if (p >= 0.01) {
        $('.progress-bar-value').css("display", "block");
    } else {
        $('.progress-bar-value').css("display", "none");
    }
    $('.subheader .progress-bar-value').html(Math.round(p * 100) + "%");
    $('.subheader .progress-bar-value').css('width', (p * 100) + "%");
    if (s) {
        $('.game-score-box .score-value').html(actual_JSON.points);
        $('.game-score-box .score-title').html("score");
        $('.game-score-box .score').html(s);
    }
    if (practiceRound || practiceRound2) {
        let txt = actual_JSON && actual_JSON.practice && actual_JSON.practice.toUpperCase() || '';
        $('.game-score-box .score-value').html("");
        $('.game-score-box .score-title').html("");
        $('.game-score-box .score').html('<small><small>' + txt + '</small></small>');
    }
}

function showResults(gameNr, s, container, parentDiv, gameData) {
    container.hide();
    $(parentDiv).hide();
    $('.medal-score-cont span').html(s);
    common.saveGameData(gameData, gameNr, s);
}

function countDown(svg, num, width, height) {
    try {
        clearInterval(interval);
    } catch (e) {
    }
    var b = num,
        d = function (b) {
            try {
                $(cnum).remove()
            } catch (d) {
            }
            cnum = svg.text(width / 2, height / 1.9, b.toString(), {
                fill: "#fff",
                "font-size": "100px",
                "text-anchor": "middle"
            })
        };
    d(b);
    interval = setInterval(function () {
        --b;
        d(b);
        0 === b && (cnum.parentNode.removeChild(cnum),
            clearInterval(interval))
    }, 1E3)
}

function showImageInfo(path, actual_JSON, svg, gridlength, width, height, s1, s2, t, img) {
    app.helpers.firefoxGameHack();

    if (app.helpers.isMobile()) {
        gridlength *= 1.5;
    }
    try {
        $(keyimgs).remove();
    } catch (e) {
    }
    var widthHalf = width / 2;
    keyimgs = svg.group({});
    feedbackFrame = svg.rect(keyimgs, widthHalf - gridlength / 1.5, height / 2 - gridlength * 0.4, gridlength * 1.333, gridlength * 0.8, 6, 6, {
        'fill-opacity': 0.95,
        fill: '#4fbadf'
    });
    feedbackText = svg.text(keyimgs, widthHalf, height / 2 - gridlength * 0.32, s1, {
        'fill': '#fff',
        'font-weight': '400',
        'font-size': gridlength / 20 + 'px',
        'text-anchor': 'middle'
    });
    svg.text(keyimgs, widthHalf, height / 2 - gridlength * 0.25, s2, {
        'fill': '#fff',
        'font-weight': '400',
        'font-size': gridlength / 20 + 'px',
        'text-anchor': 'middle'
    });
    svg.image(keyimgs, widthHalf - gridlength / 1.535, height / 2 - gridlength * 0.17, gridlength * 1.3, gridlength * 0.43333, path + img);

    if (actual_JSON != null) {
        contBg = svg.rect(keyimgs, width / 2 - gridlength / 1.5, height / 2 + gridlength / 3.16, gridlength * 1.333, gridlength / 12, {
            'fill-opacity': 0.5,
            fill: 'rgb(14,99,130)',
        });
        continueText = svg.text(keyimgs, widthHalf, height / 2 + gridlength / 2.7, actual_JSON.continuetext, {
            'fill': '#fff',
            'font-weight': '400',
            'font-size': gridlength / 28 + 'px',
            'text-anchor': 'middle'
        });
    }
    setTimeout(function () {
        $(keyimgs).remove();
    }, t);
}

function showInfo(actual_JSON, svg, gridlength, width, height, s, t, col, col2) {
    app.helpers.firefoxGameHack();

    if (app.helpers.isMobile()) {
        gridlength *= 1.5;
    }
    try {
        $(keyimgs).remove();
    } catch (e) {
    }

    keyimgs = svg.group({});
    var widthHalf = width / 2;
    var griddivider = 4;
    if (s == actual_JSON.task1 || s == actual_JSON.task2 || s == actual_JSON.tooearly || s == actual_JSON.bonus || s == actual_JSON.toolate) {
        griddivider = 5.5;
    }
    feedbackFrame = svg.rect(keyimgs, width / 2 - gridlength / 1.5, height / 2 - gridlength / 11, gridlength * 1.333, gridlength / griddivider, 6, 6, {
        'fill-opacity': 1,
        fill: col2,
    });
    feedbackText = svg.text(keyimgs, widthHalf, height / 1.95, s.toString(), {
        'fill': col,
        class: 'infoText',
        'font-size': gridlength / 20 + 'px',
    });
    setTimeout(function () {
        $(feedbackFrame).remove();
        $(feedbackText).remove();
    }, t);

    contBg = svg.rect(keyimgs, width / 2 - gridlength / 1.5, height / 2 + gridlength / 13, gridlength * 1.333, gridlength / 12, {
        'fill-opacity': 0.5,
        fill: 'rgb(14,99,130)',
    });
    continueText = svg.text(keyimgs, widthHalf, height / 2 + gridlength / 7.7, actual_JSON.continuetext, {
        'fill': '#fff',
        class: 'infoText',
        'font-size': gridlength / 28 + 'px',
    });
    if (s == actual_JSON.task1 || s == actual_JSON.task2 || s == actual_JSON.tooearly || s == actual_JSON.bonus || s == actual_JSON.toolate) {
        $(feedbackFrame).animate({
            height: gridlength / 5.5
        }, 0);
        $(continueText).remove();
        $(contBg).remove();
    }
}

function showXLargeInfo(actual_JSON, svg, gridlength, width, height, s1, s2, s3, t, col, col2, col3) {
    app.helpers.firefoxGameHack();

    var widthHalf = width / 2;
    if (app.helpers.isMobile()) {
        gridlength *= 1.5;
    }
    try {
        $(keyimgs).remove();
    } catch (e) {
    }
    keyimgs = svg.group({});
    feedbackFrame = svg.rect(keyimgs, width / 2 - gridlength / 1.5, height / 2 - gridlength / 8, gridlength * 1.333, gridlength / 3, 6, 6, {
        fill: col2,
    });
    let yoff = 0;
    if (col3 == "#eee") {
        yoff = gridlength / 32;
        svg.rect(keyimgs, width / 2 - Math.min(s1.length * 10, gridlength / 1.5),
            height / 2 - gridlength / 5, Math.min(s1.length * 20, gridlength / 0.75), gridlength / 9, 6, 6, {
            'fill-opacity': 1,
            fill: 'rgb(47,143,177)',
        });
        $(feedbackFrame).remove();
        feedbackFrame = svg.rect(keyimgs, width / 2 - gridlength / 1.5, height / 2 - gridlength / 8,
            gridlength * 1.333, gridlength / 3, 6, 6, {
            fill: col2,
        });
        feedbackText = svg.text(keyimgs, widthHalf, height / 2 - gridlength / 7, s1.toUpperCase(), {
            'fill': col3,
            'font-size': gridlength / 20 + 'px',
            class: 'infoText'
        });
    } else {
        feedbackText = svg.text(keyimgs, widthHalf, height / 2 - gridlength / 22, s1, {
            'fill': col3,
            'font-size': gridlength / 20 + 'px',
            class: 'infoText'
        });
    }

    svg.text(keyimgs, widthHalf, height / 2 + gridlength / 60 - yoff, s2, {
        'fill': col,
        'font-size': gridlength / 20 + 'px',
        class: 'infoText'
    });
    svg.text(keyimgs, widthHalf, height / 2 + gridlength / 12.75 - yoff, s3, {
        'fill': col,
        'font-size': gridlength / 20 + 'px',
        class: 'infoText'
    });
    contBg = svg.rect(keyimgs, width / 2 - gridlength / 1.5, height / 2 + gridlength / 8.05, gridlength * 1.333, gridlength / 12, {
        'fill-opacity': 0.5,
        fill: 'rgb(14,99,130)',
    });
    continueText = svg.text(keyimgs, widthHalf, height / 2 + gridlength / 5.6, actual_JSON.continuetext, {
        'fill': '#fff',
        'font-size': gridlength / 28 + 'px',
        class: 'infoText'
    });

    if (s1 == actual_JSON.before) {
        $(feedbackFrame).animate({
            height: gridlength / 4
        }, 0);
        $(continueText).remove();
        $(contBg).remove();
    }

    setTimeout(function () {
        $(keyimgs).remove();
    }, t);
}

var css = '.infoText {font-weight: 400; text-anchor: middle} ',
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
    style.styleSheet.cssText = css;
} else {
    style.appendChild(document.createTextNode(css));
}
head.appendChild(style);

export default {
    checkedAndLoaded,
    getWindowWidthOrMin,
    getWindowHeightOrMin,
    updateProgress,
    showResults,
    countDown,
    showImageInfo,
    showInfo,
    showXLargeInfo,
};
