import $ from 'jquery';

export default function (cb) {

    $('#resend').on('click', function (e) {
        e.preventDefault();
        const email = sessionStorage.getItem("game-tempData");
        app.api.resetPasswordMailRequest(email, () => window.location.reload());
    });

    //proceed with common page initialisation
    cb();
};

