import ui from 'static/js/app/ui/ui';
import api from 'static/js/app/base/api';
import events from 'static/js/app/base/events';
import storage from 'static/js/app/base/storage';
import constants from 'static/js/app/base/constants';

export default function (cb) {
    ui.startPageLoading();
    let c = storage.getCampaign();
    if (!(c && app.state.loggedIn === true)) {
        events.fire.navigate('index.html');
        return;
    }

    let nextPage = 'profile-page.html?mode=edit';
    if (c.gameMode === constants.gameMode.MANUAL_REPLAY) {
        nextPage = 'game-types.html';
    }

    // when get Profile returns false, we go to the create account
    // when the Profile exists, we go to a page according to the game mode
    api.getProfile()
        .then((profile) => events.fire.navigate(profile === false ? 'profile-page.html' : nextPage))
        .catch((err) => {
            console.error("Can't read user profile. Redirecting to home page", err);
            events.fire.navigate('index.html');
        });
};
