"use strict";

/*
    This file is the entry point for webpack!
 */

import $ from "jquery";

// Why we need this import we don't use? because the docs say so:
// source: https://getbootstrap.com/docs/3.3/getting-started/
//         https://stackoverflow.com/questions/37452086/what-is-the-best-way-to-load-bootstrap-with-an-es6-import
//        "This will load all of Bootstrap's jQuery plugins onto the jQuery object. The bootstrap module itself does not export anything."
import "bootstrap";

// source: https://babeljs.io/docs/en/next/babel-polyfill.html
//         https://www.thebasement.be/updating-to-babel-7.4/
// As of Babel 7.4.0, babel-polyfills are used this way:
import "core-js/stable";
import "regenerator-runtime/runtime";
import sentry from "static/js/sentry";
import api from "static/js/app/base/api";
import campaign from "static/js/app/base/campaign";
import candidate from "static/js/app/base/candidate";
import constants from "static/js/app/base/constants";
import events from "static/js/app/base/events";
import storage from "static/js/app/base/storage";
import helpers from "static/js/app/helpers/helpers";
import language from "static/js/app/helpers/language";
import ui from "static/js/app/ui/ui";
import pageScriptService from "static/js/app/pagescripts/pageScriptService";

window.$ = $;
window.app = {
    api,
    constants,
    storage,
    events,
    language,
    helpers,
    ui,
    campaign,
    candidate,
    state: {
        loggedIn: false,
        language: undefined,
    },
    defaults: {
        gameLangSheet: "1MoIdLkV70_4Cjvqcwnj_M6lkJWCYNPkenXJgdu1KGlA"
    },
};

sentry()


if (typeof gtag !== "function") {
    window.gtag = function () {
        console.warn(
            "Google Analytics is not loaded. Call to ga(...) is no-op"
        );
    };
}

// main entry point
$(document).ready(function () {
    app.state.loggedIn = app.api.isTokenValid(app.storage.getRefreshToken());

    app.events.subscribe.languageUpdated(app.language.languageUpdated);

    app.events.subscribe.navigate((target) => {
        let targetWithParams = app.helpers.addDefaultParametersToUrl(target);
        targetWithParams = preserveTracking(targetWithParams);
        // console.log('Navigating to: ' + targetWithParams);
        window.location.href = targetWithParams;
    });

    app.events.subscribe.campaignUpdated((c) => {
        app.campaign.setCampaign(c);
        loadCampaignTranslations();
    });

    return loadCampaignTranslations().finally(startPageHandling);
});

const loadCampaignTranslations = () => {
    let promise = Promise.resolve();
    const c = app.storage.getCampaign();
    if (!c) {
        return promise;
    }
    let language = app.language.getLanguage();
    return promise
        .then(() => app.language.loadLanguage(c.sheet, language))
        .catch((e) => {
            //console.log(e);
            app.ui.error("Campaign currently not available");
            $('#browserErrorBtnContainer').hide();
            console.error(
                "Can't load language sheet: c:'" +
                c.id +
                "' '" +
                language +
                "' '" +
                c.sheet +
                "'"
            );
        });
};

// check if allowed to view the current page and load the individual page script (js)
const startPageHandling = () => {
    let currentPage = (
        document.location.href.split("uri=")[0].match(/[^\/]+$/) || ""
    )
        .toString()
        .split("?")[0]
        .replace(".html", "");
    if (currentPage === "") {
        currentPage = "index";
    }

    if (pageScriptService.shouldGoHome(app.state.loggedIn, currentPage)) {
        app.events.fire.navigate("home.html");
    }

    const pageScript = pageScriptService.loadPageJs(currentPage);

    sendGaEvents();

    if (pageScript !== undefined) {
        pageScript(completeInitialization);
    } else {
        completeInitialization();
    }
};

const sendGaEvents = () => {
    try {
        const paths = document.location.pathname.split("/");
        const pageName = paths[paths.length - 1];

        gtag('event', 'page_view', { page_title: pageName, page_location: document.location.pathname + document.location.search });
    } catch (e) { }
};

const completeInitialization = () => {
    app.ui.initElements();
    if (app.ui.testCompatibility() === true) {
        app.ui.stopPageLoading();
    }
};


const preserveTracking = (targetWithParams) => {
    // Related to linkedin share -- remote work campaign
    // we need to keep all existing query string parameters for Tracking purposes (named: utm*)
    const params = new URLSearchParams(window.location.search);
    let paramObj = {};
    for (var name of params.keys()) {
        if (name.toLowerCase().startsWith("utm")) {
            paramObj[name] = params.get(name);
        }
    }
    let query = new URLSearchParams(paramObj).toString();
    if (query.length > 0) {
        targetWithParams += "&" + query;
    }
    return targetWithParams;
};
