import $ from 'jquery';
import bootbox from 'bootbox';
import mobileUtils from 'static/js/app/game/mobile';
import campaign from 'static/js/app/base/campaign';
import api from "../base/api";

export default {

    loadGameLanguageSheet: function (spreadsheetID, lang, cb) {

        let cachedSheet = sessionStorage.getItem("game-" + spreadsheetID);

        if (cachedSheet) {

            var parentObj,
                data = JSON.parse(cachedSheet),
                d = {},
                el = {},
                entry = data.feed.entry;

            $(entry).each(function (i) {
                parentObj = this.gsx$var.$t.split(".")[0];
                el[this.gsx$var.$t.split(".")[1]] = this["gsx$" + lang].$t;
                d[parentObj] = el;
                try {
                    if (parentObj != (data.feed.entry[i + 1]).gsx$var.$t.split(".")[0]) {
                        el = {};
                    }
                } catch (e) { }
            });

            cb(d);

        } else {
            var gamePageLangUrl = '/pagelang/' + spreadsheetID;
            if (campaign.useNativePageLangTranslations()) {
                gamePageLangUrl = '/api/candidate/campaigns/pagelang/' + spreadsheetID;
            }
            $.getJSON(gamePageLangUrl, (data) => {
                sessionStorage.setItem("game-" + spreadsheetID, JSON.stringify(data));
                this.loadGameLanguageSheet(spreadsheetID, lang, cb);
            }).fail((err) => {
                console.error("Can't load GAME language sheet: '" + lang + "' '" + spreadsheetID + "'");
                console.error(err);
            });
        }
    },

    saveGameData: function (resultArray, gameNr, points) {
        const self = this;

        let keys = app.constants.sheetKeys.submitGameResult;
        let translate = app.language.getTranslation;

        $(window).unbind();
        $('.scrollmsg').remove();
        document.body.removeEventListener('touchstart', mobileUtils.preventTouchmove);

        let line1 = translate(keys.waitLine1) || "Saving data... please wait...";
        let line2 = translate(keys.waitLine2) || "Don't leave the page!!";
        let msg = '<p class="text-center">' + line1 + '<br /><b>' + line2 + '</b></p><img src="static/img/general/spinner.gif" />';

        bootbox.hideAll();
        bootbox.dialog({
            message: msg,
            closeButton: false
        });

        // after successful save
        var success_cb = function () {
            window.onbeforeunload = undefined;
            $('.page__wrapper').show();
            bootbox.hideAll();
            $('a.btn.finalize').show();
        };

        // on fail, try again dialog
        var error_cb = function () {
            let btnLabel = translate(keys.btnLabel) || "Retry";
            let line1 = translate(keys.errorLine1) || "Failed to save game data.";
            let line2 = translate(keys.errorLine2) || "Please check your internet connection and try again.";
            let msgError = '<p class="text-center">' + line1 + '</p><p class="text-center">' + line2 + '</p>';
            bootbox.dialog({
                message: msgError,
                closeButton: false,
                buttons: {
                    confirm: {
                        label: btnLabel,
                        className: 'btn-info text-center',
                        callback: function () {
                            self.saveGameData(resultArray, gameNr, points);
                        }
                    }
                }
            });
            $('.modal-footer').css("text-align", "center");
        };

        var data = {
            gameId: gameNr,
            result: resultArray,
            gameDifficulty: (app.campaign.isLightversion()) ? 'GAME_LIGHT' : 'GAME_FULL',
            points: points,
        };
        app.api.saveGameResult(data, success_cb, error_cb);
    },

    checkIfGameIsPlayed: function (gameNr, init) {
        api.getResults()
            .then((gameResults) => {
                if (gameResults.find((g) => g.game.id === gameNr)) {
                    $('canvas').hide();
                    $('.page__wrapper').show();
                    $('.medal-score-cont span').html(gameResults[gameNr - 1].points);
                    window.onbeforeunload = null;
                } else {
                    if (gameNr == 2) {
                        try {
                            init("highp");
                        } catch (err) {
                            init("mediump")
                            console.log('err', err)
                        }
                    } else {
                        init();
                    }
                }
            }
            );
    },

    getLeftKey: function () {
        return '049';
    },

    getRightKey: function () {
        return '048';
    },

};
