import $ from 'jquery';
import FastClick from 'fastclick';
import Timer from 'static/js/libraries/cs-timer';
import common from 'static/js/app/game/common';
import screenUtils from 'static/js/app/game/screen';
import pauseUtils from 'static/js/app/game/pause';
import mobileUtils from 'static/js/app/game/mobile';

function Game4(parentDiv, langparam, lightversion) {
    var width,
        height,
        widthHalf,
        size,
        resizer = false,
        fbelements = [],
        container = $('#svg-container'),
        svg,
        progress = 0,
        overallscore = 0,
        arrowPaths = [],
        results = [],
        speedavgs = [],
        trialnum,
        trialdata,
        triallist = [],
        triallistAll = [],
        arrows = [
            [],
            [0, 0, 0, 0, 0],
            [1, 1, 1, 1, 1],
            [1, 1, 0, 1, 1],
            [0, 0, 1, 0, 0]
        ],
        block = 0,
        leftTouchArea,
        rightTouchArea,
        rts = [],
        instr,
        timercheck,
        bglayer,
        gridlength,
        fbitems,
        loaded = false,
        practiceround = true,
        started = false,
        taskstarted = false,
        infeedback = false,
        pauseswitch = false,
        homeswitch = false,
        insim = false,
        mobileMode = false,
        path = "gamedata/game4img/",
        list = [
            path + "background.png",
            path + "ii.png",
            path + "x.png",
            path + "powered.png",
            path + "tablet.png",
            path + "dart1.png",
            path + "dart2.png",
            path + "dart1x1.png",
            path + "dart2x1.png",
            path + "tabletRed.png",
            path + "dart1x2.png",
            path + "dart2x2.png",
            path + "fix.png",
            path + "fixw.png"
        ];
        var key;
        var fix;

    function pause() {
        try {
            $(leftTouchArea).remove();
        } catch (e) {
        }
        pauseswitch = true;
        document.onkeydown = null;
        try {
            $(bglayer).add(keyimgs).unbind();
        } catch (e) {
        }
        if (homeswitch) {
            pauseUtils.homemenu(width, height, size, svg, actual_JSON, window.game, 'rgb(61,187,155)');
        } else {
            pauseUtils.pausemenu(width, height, size, svg, actual_JSON, window.game, 'rgb(61,187,155)');
        }
        var tid = window.setTimeout(function () {
        }, 0);
        while (tid--) {
            window.clearTimeout(tid);
        }
    }

    function home() {
        homeswitch = true;
        pause();
    }

    function resume() {
        pause();
        onResize();
        $(menugr).remove();
        pauseswitch = false;
        homeswitch = false;
        if (insim) {
            try {
                $(ArrowPaths).remove();
            } catch (e) {
            }
            if (infeedback) {
                proceed();
            } else {
                startStimulus(triallist[0]);
            }
        } else {
            prepareToStart();
            pauseUtils.makemenu(size, svg, height, width, path, actual_JSON, window.game);
        }
    }

    this.invokeResume = function () {
        return resume();
    }

    this.invokePause = function () {
        return pause();
    }

    this.invokeHome = function () {
        return home();
    }

    this.init = function () {
        common.checkIfGameIsPlayed(4, initializeGame);

        function initializeGame() {
            $(function () {
                FastClick.attach(document.body);
            });

            $('.game-score-box').hide();
            $(parentDiv).css({
                "overflow": "hidden",
                "position": "absolute",
                "width": "100%"
            });
            $(parentDiv).html("<div id=\"svg-container\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\"> </div>");
            $('head').append('<style type="text/css">#svg-container * {font-family: \'Titillium Web\', sans-serif;}</style>');
            container = $('#svg-container');
            container.svg({});
            svg = container.svg('get');
            common.loadGameLanguageSheet(app.defaults.gameLangSheet, langparam, function (response) {
                actual_JSON = response.game4;
                onResize();
            });

            app.events.subscribe.reloadGameLanguage(() => {
                if (app.helpers.checkDeviceOrientation()) {
                    setJSON(app.language.getLanguage());
                }
            });
        }
    }

    function setJSON(l) {
        common.loadGameLanguageSheet(app.defaults.gameLangSheet, l, function (response) {
            actual_JSON = response.game4;
            if (homeswitch) {
                pauseUtils.homemenu(width, height, size, svg, actual_JSON, window.game, 'rgb(61,187,155)');
            } else {
                pauseUtils.pausemenu(width, height, size, svg, actual_JSON, window.game, 'rgb(61,187,155)');
            }
            screenUtils.updateProgress(progress / trialnum, overallscore, practiceround, actual_JSON);
        });
    }

    function prepareToStart() {
        let gamecountdown;
        window.scrollTo(0, 0);
        screenUtils.updateProgress(0, overallscore, practiceround, actual_JSON);
        gridlength = Math.min(Math.min(width - (2 * size), 600), Math.min(height - (6 * size), 600));
        document.onkeydown = null;
        try {
            $(bglayer).add(keyimgs).unbind();
        } catch (e) {
        }
        try {
            $(instr).remove();
        } catch (e) {
        }
        try {
            clearTimeout(gamecountdown);
        } catch (e) {
        }
        instr = svg.group({
            'fill': '#eee',
            'font-size': gridlength / (app.helpers.isMobile() ? 9 : 20) + 'px',
            'text-anchor': 'middle'
        });
        svg.text(instr, width / 2, height * 0.2, actual_JSON.title1.toUpperCase());
        svg.text(instr, width / 2, height * 0.2 + ((app.helpers.isMobile() ? 1.3 : 0.8) * size), actual_JSON.sub1, {
            'font-size': gridlength / (app.helpers.isMobile() ? 13 : 32) + 'px',
        });
        if (app.helpers.isMobile()) {
            for (var i = 0; i < 5; i++) {
                var Xpos = (width / 2 - 8.5 * size) + ((3.5 * size) * i);
                if (i == 2) {
                    svg.image(instr, Xpos + size / 6.4, 0.2 + (6 * size), size * 3, size * 3, path + "dart2.png");
                } else {
                    svg.image(instr, Xpos + size / 6, 0.2 + (6 * size), size * 3, size * 3, path + "dart1.png");
                }
            }
        } else {
            for (var i = 0; i < 5; i++) {
                var Xpos = (width / 2 - 6 * size) + ((2.5 * size) * i);
                if (i == 2) {
                    svg.image(instr, Xpos + size / 6.2, 0.2 + (5.5 * size), size * 2, size * 2, path + "dart2.png");
                } else {
                    svg.image(instr, Xpos + size / 6, 0.2 + (5.5 * size), size * 2, size * 2, path + "dart1.png");
                }
            }
        }
        svg.text(instr, width / 2, height * 0.2 + (4.8 * size), actual_JSON.title2);
        svg.text(instr, width / 2, height * 0.2 + ((app.helpers.isMobile() ? 6.5 : 5.6) * size), actual_JSON.sub2, {
            'font-size': gridlength / (app.helpers.isMobile() ? 14 : 32) + 'px',
        });
        svg.text(instr, width / 2, height * 0.2 + ((app.helpers.isMobile() ? 8.25 : 6.4) * size), actual_JSON.sub3, {
            'font-size': gridlength / (app.helpers.isMobile() ? 14 : 32) + 'px',
        });
        svg.text(instr, width / 2, height * 0.2 + ((app.helpers.isMobile() ? 10 : 7.2) * size), actual_JSON.sub4, {
            'font-size': gridlength / (app.helpers.isMobile() ? 14 : 32) + 'px',
        });
        var size3x = size * (app.helpers.isMobile() ? 2.7 : 1);

        svg.polygon(instr, [
            [width / 2 - 3.4 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 8.8 : 8.3) * size)],
            [width / 2 + 3 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 8.8 : 8.3) * size)],
            [width / 2 + 3.3 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 10 : 9) * size)],
            [width / 2 + 3 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 11.2 : 9.7) * size)],
            [width / 2 - 3.4 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 11.2 : 9.7) * size)],
            [width / 2 - 3.1 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 10 : 9) * size)]
        ], {
            fill: 'rgb(61,187,155)',
            class: 'poligon-btn'
        });
        svg.text(instr, width / 2, height * 0.35 + ((app.helpers.isMobile() ? 10.5 : 9.18) * size), " " + actual_JSON.start + " ", {
            'font-size': size / (app.helpers.isMobile() ? 0.7 : 1.9) + 'px',
            'font-weight': 'bold',
            cursor: 'pointer'
        });
        $('.poligon-btn').bind("click", function () {
            $(instr).remove();
            $('.game-score-box').show();
            gamecountdown = setTimeout(function (e) {
                screenUtils.updateProgress(0, overallscore, practiceround, actual_JSON);
                screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.before, actual_JSON.fingers, actual_JSON.press, 5000000, '#fff', '#4fbadf', '#eee');
                if (!app.helpers.isMobileOrTablet()) {
                    document.onkeydown = function (e) {
                        key = e.location + "" + e.keyCode;
                        switch (key) {
                            case common.getLeftKey():
                            case common.getRightKey():
                                document.onkeydown = null;
                                var msgTime = 5000000;
                                $(keyimgs).remove();
                                screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.keep, "", msgTime, '#fff', '#4fbadf', '#eee');
                                document.onkeydown = function (e) {
                                    $(keyimgs).remove();
                                    screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.i1, actual_JSON.i10, msgTime, '#fff', '#4fbadf', '#eee');
                                    document.onkeydown = function (e) {
                                        instr = svg.group({});
                                        for (var i = 0; i < 5; i++) {
                                            var Xpos = (width / 2 - 6 * size) + ((2.5 * size) * i);
                                            if (i == 2) {
                                                svg.image(instr, Xpos + size / 6.2, 0.2 + (5 * size), size * 2, size * 2, path + "dart2.png");
                                            } else {
                                                svg.image(instr, Xpos + size / 6, 0.2 + (5 * size), size * 2, size * 2, path + "dart1.png");
                                            }
                                        }
                                        screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.i2, actual_JSON.i20, msgTime, '#fff', '#4fbadf', '#eee');
                                        document.onkeydown = function (e) {
                                            screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.i3, actual_JSON.i30, msgTime, '#fff', '#4fbadf', '#eee');
                                            document.onkeydown = function (e) {
                                                $(instr).remove();
                                                instr = svg.group({});
                                                for (var i = 0; i < 5; i++) {
                                                    var Xpos = (width / 2 - 6 * size) + ((2.5 * size) * i);
                                                    if (i == 2) {
                                                        svg.image(instr, Xpos + size / 6.2, 0.2 + (5 * size), size * 2, size * 2, path + "dart1.png");
                                                    } else {
                                                        svg.image(instr, Xpos + size / 6, 0.2 + (5 * size), size * 2, size * 2, path + "dart2.png");
                                                    }
                                                }
                                                screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.i4, actual_JSON.i40, msgTime, '#fff', '#4fbadf', '#eee');
                                                document.onkeydown = function (e) {
                                                    screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.i5, actual_JSON.i50, actual_JSON.i51, msgTime, '#fff', '#4fbadf', '#fff');
                                                    document.onkeydown = function (e) {
                                                        document.onkeydown = null;
                                                        $(keyimgs).remove();
                                                        $(instr).remove();
                                                        screenUtils.countDown(svg, 2, width, height);

                                                        setTimeout(function () {
                                                            results[0] = ["Trial", "RT", "Cue", "Congruency", "Arrow_Location", "Response", "score", "Browser", "OS", "Screen", "Timestamp"];
                                                            started = true;
                                                            startSimulation();
                                                        }, 2100);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                break;
                        }
                    }
                } else {
                    actual_JSON.continuetext = actual_JSON.continuetextTouch;
                    actual_JSON.continue = actual_JSON.continueTouch;
                    actual_JSON.i30 = actual_JSON.i30Touch;
                    actual_JSON.i40 = actual_JSON.i40Touch;
                    screenUtils.showImageInfo(path, actual_JSON, svg, gridlength, width, height, actual_JSON.t1, "", 5000000, "tabletRed.png");
                    $(bglayer).add(keyimgs).click(function () {
                        $(keyimgs).remove();
                        screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.i1, actual_JSON.i10, 5000000, '#fff', '#4fbadf', '#eee');
                        $(bglayer).add(keyimgs).click(function () {
                            try {
                                $(instr).remove();
                            } catch (e) {
                            }
                            instr = svg.group({});
                            for (var i = 0; i < 5; i++) {
                                var Xpos = (width / 2 - 6 * size) + ((2.5 * size) * i);
                                if (i == 2) {
                                    svg.image(instr, Xpos + size / 6.2, 0.2 + ((app.helpers.isMobile() ? 2 : 5) * size), size * 2, size * 2, path + "dart2.png");
                                } else {
                                    svg.image(instr, Xpos + size / 6, 0.2 + ((app.helpers.isMobile() ? 2 : 5) * size), size * 2, size * 2, path + "dart1.png");
                                }
                            }
                            screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.i2, actual_JSON.i20, 5000000, '#fff', '#4fbadf', '#eee');
                            $(bglayer).add(keyimgs).click(function () {
                                screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.i3, actual_JSON.i30, 5000000, '#fff', '#4fbadf', '#eee');
                                $(bglayer).add(keyimgs).click(function () {
                                    $(instr).remove();
                                    instr = svg.group({});
                                    for (var i = 0; i < 5; i++) {
                                        var Xpos = (width / 2 - 6 * size) + ((2.5 * size) * i);

                                        if (i == 2) {
                                            svg.image(instr, Xpos + size / 6.2, 0.2 + ((app.helpers.isMobile() ? 2 : 5) * size), size * 2, size * 2, path + "dart1.png");
                                        } else {
                                            svg.image(instr, Xpos + size / 6, 0.2 + ((app.helpers.isMobile() ? 2 : 5) * size), size * 2, size * 2, path + "dart2.png");
                                        }
                                    }
                                    screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.instructions, actual_JSON.i4, actual_JSON.i40, 5000000, '#fff', '#4fbadf', '#eee');
                                    $(bglayer).add(keyimgs).click(function () {
                                        screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.i5, actual_JSON.i50, actual_JSON.i51, 5000000, '#fff', '#4fbadf', '#fff');
                                        $(bglayer).add(keyimgs).click(function () {
                                            $(bglayer).add(keyimgs).unbind();
                                            $(keyimgs).remove();
                                            $(instr).remove();
                                            screenUtils.countDown(svg, 2, width, height);
                                            setTimeout(function () {
                                                if (results.length == 0) {
                                                    results.push(["Trial", "RT", "Cue", "Congruency", "Arrow_Location", "Response", "score", "Browser", "OS", "Screen", "Timestamp"]);
                                                }
                                                started = true;
                                                startSimulation();
                                            }, 2500);
                                        });
                                    });
                                });
                            });
                        });
                    });
                }
            }, 20);
        });
    }

    function startSimulation() {
        triallist = [];

        var triallistFile = 'gamedata/attention_long.csv';
        if (lightversion)
            triallistFile = 'gamedata/attention_short.csv';

        if (trialdata === undefined) {
            try {
                $.get(triallistFile + '?' + Math.random(10), function (data) {
                    trialdata = data;
                    generateTrials(data)
                });
            } catch (e) {
                console.warn('game 4 | ', e);
                startSimulation();
            }
        } else {
            generateTrials(trialdata);
        }

        function generateTrials(data) {
            var orders = data.split('\n');
            if (triallistAll.length == 0) {
                triallistAll.push.apply(triallistAll, orders[Math.floor((Math.random() * 9))].split(','));
                trialnum = triallistAll.length;
            }

            if (!lightversion) {
                if (block == 0) {
                    triallist = triallistAll.slice(0, 24);
                } else {
                    var blockindex = 24 + (block - 1) * 26;
                    triallist = triallistAll.slice(blockindex, blockindex + 26);
                }
            } else {
                triallist = triallistAll.slice(block * 16, (block + 1) * 16)
            }

            screenUtils.updateProgress(progress / trialnum, overallscore, practiceround, actual_JSON);

            for (var i = 0; i < triallist.length; i++) {

                triallist[i] = triallist[i].replace(1211, '11');
                triallist[i] = triallist[i].replace(1221, '12');
                triallist[i] = triallist[i].replace(1212, '13');
                triallist[i] = triallist[i].replace(1222, '14');

                triallist[i] = triallist[i].replace(1111, '21');
                triallist[i] = triallist[i].replace(1121, '22');
                triallist[i] = triallist[i].replace(1112, '23');
                triallist[i] = triallist[i].replace(1122, '24');

                triallist[i] = triallist[i].replace(2211, '31');
                triallist[i] = triallist[i].replace(2221, '32');
                triallist[i] = triallist[i].replace(2212, '33');
                triallist[i] = triallist[i].replace(2222, '34');

                triallist[i] = triallist[i].replace(2111, '41');
                triallist[i] = triallist[i].replace(2121, '42');
                triallist[i] = triallist[i].replace(2112, '43');
                triallist[i] = triallist[i].replace(2122, '44');

                triallist[i] = triallist[i].replace(3211, '51');
                triallist[i] = triallist[i].replace(3221, '52');
                triallist[i] = triallist[i].replace(3212, '53');
                triallist[i] = triallist[i].replace(3222, '54');

                triallist[i] = triallist[i].replace(3111, '61');
                triallist[i] = triallist[i].replace(3121, '62');
                triallist[i] = triallist[i].replace(3112, '63');
                triallist[i] = triallist[i].replace(3122, '64');

                triallist[i] = triallist[i].replace(4211, '71');
                triallist[i] = triallist[i].replace(4221, '72');
                triallist[i] = triallist[i].replace(4212, '73');
                triallist[i] = triallist[i].replace(4222, '74');

                triallist[i] = triallist[i].replace(4111, '81');
                triallist[i] = triallist[i].replace(4121, '82');
                triallist[i] = triallist[i].replace(4112, '83');
                triallist[i] = triallist[i].replace(4122, '84');
            }


            insim = true;
            onResize();
            if (block == 1) {
                practiceround = false;
                overallscore = 0;
                screenUtils.updateProgress(progress / trialnum, overallscore, practiceround, actual_JSON);
                screenUtils.showXLargeInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.realgame, actual_JSON.realgame2, "", 5000000, '#fff', '#4fbadf', '#fff');
                if (!app.helpers.isMobileOrTablet()) {
                    document.onkeydown = function () {
                        $(keyimgs).remove();
                        document.onkeydown = null;
                        startStimulus(triallist[0]);
                    }
                } else {
                    $(bglayer).add(keyimgs).click(function () {
                        $(keyimgs).remove();
                        $(bglayer).add(keyimgs).unbind();
                        startStimulus(triallist[0]);
                    });
                }
            } else {
                startStimulus(triallist[0]);
            }
        }
    }

    function startStimulus(TType) {
        var stim = [],
            timer = new Timer(),
            cor;

       // onResize();
        resizer = false;

        // present fix
        if (!pauseswitch) {
            taskstarted = true;
            try {
                $(fix).remove();
            } catch (e) {
            }
            var tooearlybashed = false;
            $(bglayer).click(function () {
                tooearlybashed = true;
                $(bglayer).unbind();
                checkAnswer(0);
            });
            document.onkeydown = function () {
                document.onkeydown = function () {
                };
                tooearlybashed = true;
                $(bglayer).unbind();
                checkAnswer(0);
            }
            fix = svg.image(width / 2 - size * 1.2, height / 2 - size * 1.2, size * 2.4, size * 2.4, path + 'fix.png');
            let fix_duration = 200 + (Math.random() * 900);
            setTimeout(function (e) {
                var cue;
                if (!tooearlybashed) {
                    if (TType[0] == 1 || TType[0] == 2) { // no cue
                        setTimeout(function (e) {
                            setTimeout(function (e) {
                                drawArrows();
                            }, 400);
                        }, 130);
                    }
                    if (TType[0] == 3 || TType[0] == 4) { // Center cue
                        $(fix).remove();
                        fix = svg.image(width / 2 - size * 1.2, height / 2 - size * 1.2, size * 2.4, size * 2.4, path + 'fixw.png');
                        setTimeout(function (e) {
                            $(fix).remove();
                            fix = svg.image(width / 2 - size * 1.2, height / 2 - size * 1.2, size * 2.4, size * 2.4, path + 'fix.png');
                            setTimeout(function (e) {
                                drawArrows();
                            }, 400);
                        }, 130);
                    }
                    if (TType[0] == 5 || TType[0] == 8) { // spatial cue down
                        cue = svg.image(width / 2 - size, height / 2 + 3.4 * size, size * 2, size * 2, path + 'fixw.png');
                        setTimeout(function (e) {
                            $(cue).remove();
                            setTimeout(function (e) {
                                drawArrows();
                            }, 400);
                        }, 130);
                    }
                    if (TType[0] == 6 || TType[0] == 7) { // spatial cue up
                        cue = svg.image(width / 2 - size, height / 2 - 5.4 * size, size * 2, size * 2, path + 'fixw.png');
                        setTimeout(function (e) {
                            $(cue).remove();
                            setTimeout(function (e) {
                                drawArrows();
                            }, 400);
                        }, 130);
                    }
                }
            }, fix_duration);
            // present flankers
            function drawArrows() {
                if (!pauseswitch && !tooearlybashed) {
                    var Ypos = height / 2 - 6.2 * size; //flanker height
                    if (TType[0] == 1 || TType[0] == 3 || TType[0] == 5 || TType[0] == 7) {
                        Ypos = height / 2 + 2.8 * size; //flanker height 2
                    }
                    stim = arrows[TType[1]];
                    try {
                        arrowPaths.forEach(function (el) {
                            $(el).remove();
                        });
                        arrowPaths = [];
                    } catch (e) {
                    }
                    for (var s = 0; s < stim.length; s++) {
                        var Xpos = (width / 2 - 9.8 * size) + ((4.1 * size) * s),
                            el;
                        if (stim[s] === 0) {
                            el = svg.image(Xpos, Ypos, size * 3, size * 3, path + "dart2.png");
                        } else {
                            el = svg.image(Xpos + size / 6, Ypos, size * 3, size * 3, path + "dart1.png");
                        }
                        arrowPaths.push(el);
                    }
                    try {
                        $(leftTouchArea).remove();
                        $(rightTouchArea).remove();
                    } catch (e) {
                    }
                    leftTouchArea = svg.rect(size, 0, widthHalf, height, {
                        'opacity': 0
                    });
                    rightTouchArea = svg.rect(widthHalf, 0, widthHalf - size, height, {
                        'opacity': 0
                    });
                    $(leftTouchArea).on('touchstart mousedown', function (e) {
                        checkAnswer(0);
                    });
                    $(rightTouchArea).on('touchstart mousedown', function (e) {
                        checkAnswer(1);
                    });
                    document.onkeydown = function (e) {
                        key = e.location + "" + e.keyCode;
                        switch (key) {
                            case common.getLeftKey():
                                if (!pauseswitch) {
                                    checkAnswer(0);
                                }
                                break;
                            case common.getRightKey():
                                if (!pauseswitch) {
                                    checkAnswer(1);
                                }
                                break;
                        }
                    };
                    $(bglayer).unbind();
                    timer.start();
                    timercheck = setInterval(function () {
                        if (timer.getDuration() > 1000 && app.helpers.checkDeviceOrientation()) {
                            checkAnswer(-1);
                            if (!practiceround) {
                                overallscore = Math.max(overallscore - 999, 0);
                                screenUtils.updateProgress(progress / trialnum, overallscore, practiceround, actual_JSON);
                            }
                        }
                    }, 5);
                }
            }

            // collect response
            function checkAnswer(answer) {
                var end = timer.getDuration();
                clearInterval(timercheck);
                timer.reset();
                let y = height / 2 - 6.2 * size; //fb height
                let x = (width / 2 - 9.8 * size) + ((4.1 * size) * 2);
                document.onkeydown = null;
                $(rightTouchArea).unbind('touchstart mousedown').remove();
                $(leftTouchArea).unbind('touchstart mousedown').remove();
                var cue, Arrow_Location, congruency, responses = ["Left", "Right"],
                    points = 0,
                    a = 0;
                switch (TType[0]) {
                    case "1":
                    case "2":
                        cue = "NO_CUE";
                        break;
                    case "3":
                    case "4":
                        cue = "CENTER_CUE";
                        break;
                    case "5":
                    case "6":
                        cue = "SPATIAL_CUE_VALID";
                        break;
                    case "7":
                    case "8":
                        cue = "SPATIAL_CUE_INVALID";
                        break;
                }
                switch (TType[0]) {
                    case "1":
                    case "3":
                    case "5":
                    case "7":
                        Arrow_Location = "BOTTOM";
                        y = height / 2 + 2.8 * size; //flan
                        break; //fb height 2
                    case "2":
                    case "4":
                    case "6":
                    case "8":
                        Arrow_Location = "TOP";
                        break;
                }
                switch (TType[1]) {
                    case "1":
                        congruency = "LEFT_congruent";
                        break;
                    case "2":
                        congruency = "RIGHT_congruent";
                        break;
                    case "3":
                        congruency = "LEFT_incongruent";
                        break;
                    case "4":
                        congruency = "RIGHT_incongruent";
                        break;
                }
                let t = 0;
                if (end < 100) {
                    $(fix).remove();
                    arrowPaths.forEach(function (el) {
                        $(el).remove();
                    });
                    screenUtils.showInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.tooearly, 2000, '#fff', "#fc004d");
                    t = 2000;
                    if (block != 0) {
                        app.helpers.shuffleArray(triallist);
                    }
                    points = -1;
                    responses[answer] = responses[answer].concat(" (too early)");
                    a = 1;
                } else if (answer == -1) {
                    points = -999;
                    showPoints(points, 500, "#fc004d");
                    arrowPaths.forEach(function (el) {
                        $(el).remove();
                    });
                    setTimeout(function () {
                        $(fix).remove();
                        if (app.helpers.checkDeviceOrientation()) {
                            screenUtils.showInfo(actual_JSON, svg, gridlength, width, height, actual_JSON.toolate, 1000000, '#fff', "#fc004d");
                            setTimeout(function () {
                                if (app.helpers.checkDeviceOrientation()) {
                                    $(feedbackFrame).remove();
                                    $(feedbackText).remove();
                                }
                            }, 1000);
                        }
                    }, 700);
                    t = 2000;
                    if (block != 0) {
                        app.helpers.shuffleArray(triallist);
                    }
                    responses[answer] = "(too late)";
                    a = 1;
                } else {
                    points = Math.round(250 - (end - 250) / 3);
                    points = Math.max(0, points);
                    cor = false;
                    if (answer == stim[2] && !resizer) cor = true;
                    if (points < -999) points = -999;

                    //-------------------------- ANIMATION --------------------------------------------------------------- ///
                    var line2,
                        line1 = svg.line(width - (answer * 1.2 * width), y + size * 2, width / 2, y + size * 1.5, {
                            strokeWidth: size / 3,
                            stroke: '#50bbe1',
                        });
                    $(line1).css('opacity', '0.0');
                    $(line1).css('transition', '0.15s all');
                    setTimeout(function () {
                        $(line1).css('opacity', '0.8');
                    }, 1);
                    var pic = arrowPaths[2].href.animVal.replace(".png", "x1.png");
                    if (pic.indexOf("dart1") > -1) {
                        x = x + size / 6;
                    }
                    $(arrowPaths[2]).remove();
                    arrowPaths[2] = svg.image(x, y, 3 * size, 3 * size, pic);
                    $(arrowPaths[0]).add(arrowPaths[1]).add(arrowPaths[3]).add(arrowPaths[4]).css("transition", "all 0.15s");
                    $(arrowPaths[0]).add(arrowPaths[1]).add(arrowPaths[3]).add(arrowPaths[4]).css("opacity", "0.3");
                    if (cor) {
                        setTimeout(function () {
                            line2 = svg.line((answer * 1.2 * width), y + size * 1.5, width / 2, y + size * 1.5, {
                                strokeWidth: size / 3,
                                stroke: '#50bbe1',
                            });
                            $(line2).css('opacity', '0.0');
                            $(line2).css('transition', '0.15s all');
                            setTimeout(function () {
                                $(line2).css('opacity', '0.8');
                            }, 1);
                            setTimeout(function () {
                                $(line1).css('opacity', '0.0');
                            }, 150);
                            setTimeout(function () {
                                $(line2).css('opacity', '0.0');
                            }, 300);
                            $(arrowPaths[2]).remove();
                            arrowPaths[2] = svg.image(x, y, 3 * size, 3 * size, pic.replace("x1", "x2"));
                            $(arrowPaths[0]).add(arrowPaths[1]).add(arrowPaths[3]).add(arrowPaths[4]).css("transition", "all 0.1s");
                            $(arrowPaths[0]).add(arrowPaths[1]).add(arrowPaths[3]).add(arrowPaths[4]).css("opacity", "0.3");
                        }, 175);
                    }
                    if (!cor) points = 0;
                    showPoints(points, 500, 'lightgrey');
                    overallscore = Math.max(0, overallscore += points);
                    if (!resizer) {
                        progress += 1;
                        triallist.shift();
                    }
                }

                if (!resizer) {
                    // log trial result
                    rts.push(end);

                   results.push([progress + a, end, cue, congruency, Arrow_Location, responses[answer], points, app.helpers.getBrowserName(), navigator.platform, screen.width + 'x' + screen.height, app.helpers.getTimestamp() ]);
                }

                $(leftTouchArea).remove();
                $(rightTouchArea).remove();

                //after block

                if (a == 0 && (triallist.length == 0)) {
                    setTimeout(function (e) {
                        try {
                            $(line2).remove();
                        } catch (e) {
                        }
                        $(line1).remove();
                        arrowPaths.forEach(function (el) {
                            $(el).remove();
                        });
                        screenUtils.updateProgress(progress / trialnum, overallscore, practiceround, actual_JSON);
                        speedavgs.push(app.helpers.median(rts));
                        if (block == 0) {
                            block++;
                            startSimulation();
                        } else {
                            feedback();
                        }
                    }, 500 + t);
                } else {

                    //else start next trial

                    setTimeout(function (e) {
                        try {
                            $(line2).remove();
                        } catch (e) {
                        }
                        $(line1).remove();
                        try {
                            arrowPaths.forEach(function (el) {
                                $(el).remove();
                            });
                        } catch (e) {
                        }
                        screenUtils.updateProgress(progress / trialnum, overallscore, practiceround, actual_JSON);
                        if (!pauseswitch) {
                            startStimulus(triallist[0]);
                        }
                    }, 500 + t);
                }
            }
        }
    }

    function feedback() {

        var fbtextcol = '#eee',
            xo = (screenUtils.getWindowWidthOrMin() - (size * 20)) / 2,
            fbopacity = 0.3,
            fbtexts = ["", actual_JSON.fb1text, actual_JSON.fb2text, actual_JSON.fb3text, actual_JSON.fb4text, actual_JSON.fb5text],
            fbtoptext = fbtexts[block],
            fbsubtext = actual_JSON.fbfirst,
            circlepath = [],
            radius = (size * 20) / 14,
            steps = 100,
            xStart,
            yStart = height / 2,
            blockAmount = 5;

        if (lightversion)
            blockAmount = 2;

        if(app.helpers.isMobile()){
            radius*=2;
        }

        $(fix).remove();
        fbelements = [];
        infeedback = true;

        var getXStart = function (j) {
            var ret = xo + ((size * 2) + (size * 20) / blockAmount * j);
            if(app.helpers.isMobile()){
                ret -= 4*size;
                ret += j*size*2;
            }
            return ret;
        };

        var pushTexts = function(xStart,text1,text2,text3,text4,opacity) {
            var sizes =[60,45];
            var pos = [50,-180,-40,-13];
            if(app.helpers.isMobile()){
                sizes =[40,30];
                 pos = [20,-280,-20,-8];
            }

            fbelements.push(drawText(xStart, pos[0], sizes[0], text1, opacity));
            fbelements.push(drawText(xStart, pos[1], sizes[1], text2, opacity));
            fbelements.push(drawText(xStart, pos[2], sizes[0],text3, opacity));
            fbelements.push(drawText(xStart, pos[3], sizes[1], text4, opacity));
        };

        for (var j = block; j < blockAmount; j++) {
            xStart = getXStart(j);
            fbelements.push(drawCircle(createPath(xStart).slice(0, 80), 'lightgrey', fbopacity, xStart, 80));
            pushTexts(xStart,actual_JSON.goodluck,actual_JSON.befaster,actual_JSON.nextround,"--",fbopacity);
        }

        for (var j = 0; j < speedavgs.length - 1; j++) {

            xStart = getXStart(j);

            if (lightversion && j == 0) {
                xStart = xo + ((size * 20) / 10 + (size * 20) / blockAmount * 0.6);
                if(app.helpers.isMobile()){
                    xStart-=4*size;
                }
            }

            var currentopacity = fbopacity,
                ctext1 = actual_JSON.thiswas,
                ctext2 = actual_JSON.speed,
                ctext3 = actual_JSON.inpart + " " + (j + 1),
                s = 700 - speedavgs[j + 1];

            s = s * 3 / 4;
            s = Math.min(300, s);
            s = Math.max(2, s);

            if (j == block - 1)
                currentopacity = 1;

            if (j > 0) {
                ctext1 = Math.round(speedavgs[j + 1]) - Math.round(speedavgs[j]);
                ctext2 = actual_JSON.faster;
                fbsubtext = actual_JSON.fbfaster;
                if (ctext1 > 0) {
                    ctext2 = actual_JSON.slower;
                    fbsubtext = actual_JSON.fbslower;
                }
                ctext1 = (ctext1.toString()).replace("-", "") + " ms";
            }

            circlepath = createPath(xStart).slice(0, 80);

            var fbpoly = drawCircle(circlepath, 'lightgrey', 1, xStart, 80);
            fbelements.push(fbpoly);

            if (s > 140) {

                var circlepath2 = circlepath.slice(Math.max(1, Math.floor(s / 3.7)) - 1, circlepath.length);
                $(fbpoly).attr("stroke", app.helpers.getColor(1 - s / 300));
                fbelements.push(drawCircle(circlepath2, 'lightgrey', 1, xStart, 80));

            } else {

                var circlepath2 = circlepath.slice(0, Math.max(1, Math.floor(s / 3.7)) - 1);
                fbelements.push(drawCircle(circlepath2, app.helpers.getColor(1 - s / 300), 1, xStart, 80));

            }
            if (j != block) {

                fbelements.push(drawCircle(circlepath, 'black', 1 - currentopacity, xStart, 70));

            }

            pushTexts(xStart,ctext1,ctext2,ctext3,Math.round(speedavgs[j + 1]) + "ms",currentopacity);


        }

        if (block == 5 || (lightversion && block == 2)) {
            fbsubtext = actual_JSON.fblast;
        }
        fbelements.push(svg.text(xo + (size * 20) / 2, height * 0.2*(app.helpers.isMobile()?1.1:1), fbtoptext, {
            'font-size': size*(app.helpers.isMobile()?1.4:1),
            'fill': fbtextcol,
            'text-anchor': 'middle'
        }));
        fbelements.push(svg.text(xo + (size * 20) / 2, height * 0.25*(app.helpers.isMobile()?1.15:1), fbsubtext, {
            'font-size': size / 2*(app.helpers.isMobile()?1.8:1),
            'fill': fbtextcol,
            'text-anchor': 'middle'
        }));
        var size3x = size * (app.helpers.isMobile() ? 3.7 : 1.5);


        setTimeout(function (e) {
            fbelements.push(svg.polygon([
                [width / 2 - 3.4 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 8.8 : 8.3) * size)],
                [width / 2 + 3 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 8.8 : 8.3) * size)],
                [width / 2 + 3.3 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 10 : 9) * size)],
                [width / 2 + 3 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 11.2 : 9.7) * size)],
                [width / 2 - 3.4 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 11.2 : 9.7) * size)],
                [width / 2 - 3.1 * size3x, height * 0.35 + ((app.helpers.isMobile() ? 10 : 9) * size)]
            ], {
                fill: 'rgb(61,187,155)'
            }));
            fbelements.push(svg.text(xo + (size * 20) / 2, height * 0.35 + ((app.helpers.isMobile() ? 10.5 : 9.18) * size), " " + actual_JSON.continue + " ", {
                'font-size': size / (app.helpers.isMobile() ? 0.7 : 1.9) + 'px',
                'font-weight': 'bold',
                'fill': fbtextcol,
                'text-anchor': 'middle'
            }));
            try {
                $(leftTouchArea).remove();
            } catch (e) {
            }
            leftTouchArea = svg.rect(0, 0, width, height, {
                cursor: 'pointer',
                'opacity': 0
            });
            $(leftTouchArea).on('click', function (e) { // click
                proceed();
            });
            $(document).keydown(function (e) {
                proceed();
            });
            pauseUtils.makemenu(size, svg, height, width, path, actual_JSON, window.game);
        }, 2000);


        function drawText(xPosition, yPosition, fsize, ftext, opac) {

            if(app.helpers.isMobile()){
                fsize/=1.4;
            }

            return svg.text(xPosition, height / 2 - ((size * 20) / yPosition), ftext, {
                'font-size': (size * 20) / fsize,
                'fill': fbtextcol,
                'text-anchor': 'middle',
                'opacity': opac
            });
        }

        function drawCircle(cPath, strokeColor, opac, xPosition, sw) {

            return svg.polyline(cPath, {
                stroke: strokeColor,
                strokeWidth: Math.floor((size * 20) / sw),
                opacity: opac,
                transform: 'rotate(' + (128) + ',' + (xPosition) + ',' + (yStart) + ')'
            })

        }

        function createPath(xPosition) {

            var c = [];

            for (var i = 0; i < steps; i++) {
                var x = (xPosition + radius * Math.cos(2 * Math.PI * i / steps));
                var y = (yStart + radius * Math.sin(2 * Math.PI * i / steps));
                c.push([x, y]);
            }

            return c;

        }
    }

    function proceed() {
        $(leftTouchArea).remove();
        $(document).unbind('keydown');
        $(bglayer).add(keyimgs).unbind();
        if (block == 5 || (lightversion && block == 2)) {
            screenUtils.showResults(4, overallscore, container, parentDiv, results);
        } else {
            rts = [];
            fbelements.forEach(function (entry) {
                try {
                    entry.remove();
                } catch (e) {
                }
            });
            block++;
            infeedback = false;
            startSimulation();
        }
    }

    function showPoints(s, t, col) {
        try {
            $(fbitems).remove();
        } catch (e) {
        }
        fbitems = svg.group({});
        svg.text(fbitems, width / 2, height / 2 + size * 2.4, s.toString(), {
            'fill': col,
            'font-size': 0.85 * size,
            'text-anchor': 'middle',
        });
        var circlepath = [];
        var radius = size * 2;
        var steps = 100;
        var xStart = width / 2;
        var yStart = height / 2;
        for (var i = 0; i < steps; i++) {
            var x = (xStart + radius * Math.cos(2 * Math.PI * i / steps));
            var y = (yStart + radius * Math.sin(2 * Math.PI * i / steps));
            circlepath.push([x, y]);
        }
        circlepath = circlepath.slice(0, 80);
        var col2 = 'lightgrey'
        if (col == "#fc004d") {
            col2 = col;
        }
        var fbpoly = svg.polyline(fbitems, circlepath, {
            stroke: col2,
            strokeWidth: Math.floor(size / 3),
            transform: 'rotate(' + (128) + ',' + (xStart) + ',' + (yStart) + ')'
        });
        if (col != "#fc004d" && s > 0) {
            if (s > 140) {
                var circlepath2 = circlepath.slice(Math.max(1, Math.floor(s / 3.7)) - 1, circlepath.length);
                $(fbpoly).attr("stroke", app.helpers.getColor(1 - s / 300));
                svg.polyline(fbitems, circlepath2, {
                    stroke: 'lightgrey',
                    strokeWidth: Math.floor(size / 3),
                    transform: 'rotate(' + (128) + ',' + (xStart) + ',' + (yStart) + ')'
                });
            } else {
                var circlepath2 = circlepath.slice(0, Math.max(1, Math.floor(s / 3.7)) - 1);
                svg.polyline(fbitems, circlepath2, {
                    stroke: app.helpers.getColor(1 - s / 300),
                    strokeWidth: Math.floor(size / 3),
                    transform: 'rotate(' + (128) + ',' + (xStart) + ',' + (yStart) + ')'
                });
            }
        }
        try {
            $(fix).remove();
        } catch (e) {
        }
        fix = svg.image(width / 2 - size * 1.2, height / 2 - size * 1.2, size * 2.4, size * 2.4, path + 'fix.png');
        setTimeout(function () {
            $(fbitems).remove();
        }, t);
    }

    function onResize() {
        width = screenUtils.getWindowWidthOrMin();
        height = screenUtils.getWindowHeightOrMin(parentDiv);
        size = Math.min(width, height) / 20;
        widthHalf = width / 2;
        svg.configure({
            width: '100%',
            height: height
        });
        $(parentDiv).css("height", height + "px");
        var callback = function () {
            resizer = true;
            if (!pauseswitch) {
                $(leftTouchArea).trigger("mousedown");
            }
            $(ltext).remove();
            $(background).remove();
            if (width / height >= 1) {
                background = svg.image(0, 0, height, height, path + 'background.png', {
                    transform: 'scale(' + width / height +
                    ', ' + 1 + ')'
                });
            } else {
                background = svg.image(0, 0, width, width, path + 'background.png', {
                    transform: 'scale(' + 1 + ', ' + 1 / (width / height) + ')'
                });
            }
            try {
                $(bglayer).remove();
            } catch (err) {
            }
            bglayer = svg.rect(0, 0, width, height, {
                fill: 'black'
            });
            $(bglayer).css('opacity', '0.8');
            try {
                $(powered).remove();
            } catch (err) {
            }
            powered = svg.image(width - 214, height - 40, 214, 40, path + 'powered.png', {
                cursor: "pointer"
            });
            $(powered).on("click", function () {
                if (!app.helpers.isMobileOrTablet()) {
                    window.open('http://brainsfirst.com/', '_blank');
                }
            });
            if (app.helpers.isMobile()) {
                $(powered).remove();
            }

            if (!mobileMode && app.helpers.isMobile()) {
                mobileUtils.activateMobileMode();
                mobileMode = true;
            }
            if (!started && !pauseswitch) {
                prepareToStart();
            }
            if (pauseswitch || infeedback) pause();

            pauseUtils.makemenu(size, svg, height, width, path, actual_JSON, window.game);

            $(window).blur(function () {
                if (app.helpers.checkDeviceOrientation() && !pauseswitch) {
                    try {
                        pause();
                    } catch (e) {
                    }
                }
            });

            $('.header').add('.subheader *').unbind();
            $('.header').add('.subheader *').click(function () {
                if (app.helpers.checkDeviceOrientation() && !pauseswitch) {

                    pause();
                }
            });
            $('.logo, .logo img ').unbind();
            $('.logo, .logo img ').click(function (e) {
                if (app.helpers.checkDeviceOrientation()) {
                    home();
                }
                e.preventDefault();
                return false;
            });
            app.helpers.firefoxGameHack();
        };
        loaded = screenUtils.checkedAndLoaded(loaded, path, list, svg, width, height, size, callback, actual_JSON);
        if (!app.helpers.checkDeviceOrientation() && taskstarted) {
            pauseswitch = true;
        }
    }

    $(window).resize(function () {
        onResize();
        onResize();
        if (app.helpers.isMobile()) {
            mobileUtils.fullscreenCheck();
        }
    });

    this.initDummy = function () {
        $(parentDiv).css({
            "overflow": "hidden",
            "position": "absolute",
            "width": "100%"
        });
        $(parentDiv).html("<div id=\"svg-container\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\"> </div>");
        $('head').append('<style type="text/css">#svg-container * {font-family: \'Titillium Web\', sans-serif;}</style>');
        container = $('#svg-container');
        container.svg({});
        svg = container.svg('get');

        results[0] = ["Trial", "RT", "Cue", "Congruency", "Arrow_Location", "Response", "score", "Browser", "OS", "Screen", "Timestamp"];
        for (var i = 0; i < 151; i++) {
            results.push([i, 111, "CENTER_CUE", "LEFT_INCONGRUENT", "BOTTOM", 1, 666, app.helpers.getBrowserName(), navigator.platform, screen.width + 'x' + screen.height, app.helpers.getTimestamp() ]);
        }

        screenUtils.showResults(4, 666, container, parentDiv, results);
    }

}

export default Game4;
