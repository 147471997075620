
const handleCampaignError = (e, key) => {
    app.ui.error("Invalid link.");
    if (e) console.error("Can't activate account (key: '" + key + "'): ", e);
};

export default function () {
    const campaignId = app.helpers.getURLParameter("c") || -1;
    const key = app.helpers.getURLParameter("key") || -1;
    if (campaignId === -1 || key === -1) {
        return handleCampaignError();
    }
    if (app.state.loggedIn === true) {
        return app.events.fire.navigate('home.html');
    }
    return app.api.loadCampaignById(campaignId)
        .then(() => app.api.registrationConfirm(key))
        .then((res) => {
            if (app.helpers.isMobile()) {
                app.ui.error(app.language.getTextAccountActivatedCantPlayOnMobile());
            } else {
                app.candidate.loginAndGoHome(res);
            }
        })
        .catch((e) => handleCampaignError(e, key));
};
