import $ from 'jquery';
import storage from "static/js/app/base/storage";
import liquid from "../../../themes/liquid/liquid";
import * as Sentry from "@sentry/browser";

// this will inject select 2 into jquery
import "select2";

export default {

    startPageLoading: function () {
        $(window).bind("unload", function () { });
        $('.page-spinner-bar').remove();
        $('body').append('<div class="page-spinner-bar"><div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div></div>');
    },

    stopPageLoading: function () {

        $('.page-loading, .page-spinner-bar').remove();
    },

    error: function (text) {
        $('#browserErrorText').html(text);
        $('#browserErrorMsg').modal('show');
        // show "go home" button if we have campaign information
        let campaignId = app.helpers.getURLParameter("c") || -1;
        if (campaignId === -1) {
            const c = app.storage.getCampaign() || {};
            campaignId = c.id;
        }
        if (campaignId > 0) {
            $('#browserErrorBtnContainer').show();
            $('#browserErrorBtn').prop("href", "/?c=" + campaignId);
        }
    },

    // check if game can run in browser: needs sessionStorage and webGl APIs
    testCompatibility: function () {
        if (app.storage.isEnabled() === false) {
            this.error("The neurolympics games cannot be run on your browser. (session storage is not available)");
            Sentry.captureMessage("This browser does not have session storage available")
            return false;
        } else if (app.helpers.supportsWebGL() === false) {
            $('#noWebGL').show();
            $('#noWebGL input').addClass("email-field");
            this.error('');
            Sentry.captureMessage("This browser does not have webGL available")
            return false;
        }
        return true;
    },

    // push to their corresponding dom elements
    setPageLang: function () {
        const gameNumber = storage.getCampaign().games.length
        const patternMatch = /\$\{(.+?)\}/g;
        const patternReplace = (a, b) => {
            if (app.language.getTranslation(b)) {
                return app.language.getTranslation(b)
            } else {
                if (b === "playingTime") {
                    return gameNumber === 1 ? 10 :
                        gameNumber === 2 ? 20 :
                            gameNumber === 3 ? 35 :
                                45
                } else if (b === "gameNumber") {
                    return gameNumber
                }
                return b
            }
        };
        const replaceNestedKeys = (str) => (str || "").replace(patternMatch, patternReplace);

        $('body [data-text]').each(function (i, el) {
            const val = $(el).data('text');
            const found = replaceNestedKeys(app.language.getTranslation(val));
            if (found) $(el).html(found);
        });

        $('body [data-placeholder]').each(function (i, el) {
            var val = $(el).data('placeholder');
            const found = replaceNestedKeys(app.language.getTranslation(val));
            if (found) $(el).attr("placeholder", found);
        });

        $('body [data-placeholder-select]').each(function (i, el) {
            var val = $(el).data('placeholder-select');
            const found = replaceNestedKeys(app.language.getTranslation(val));
            if (found) $(el).attr("placeholder", found);
        });

        $('body [data-src]').each(function (i, el) {
            var val = $(el).data('src');
            const found = replaceNestedKeys(app.language.getTranslation(val));
            if (found) $(el).attr("src", found);
        });

        $('body [data-style]').each(function (i, el) {
            var val = $(el).data('style');
            const found = replaceNestedKeys(app.language.getTranslation(val));
            if (found) $(el).attr("style", found);
        });

        $('body [data-background]').each(function (i, el) {
            var val = $(el).data('background');
            const found = replaceNestedKeys(app.language.getTranslation(val));
            if (found) $(el).css("background", found);
        });

    },

    initElements: function () {
        if (app.campaign.isDemo()) {
            $('.demobadge').css('display', 'inherit');
        }
        if (app.storage.getLightversion() == "true") {
            $('.demobadge').css('display', 'inherit');
            $('.lightbadge').css('display', 'none');
            $('.header .logo').css('margin-left', '38px');
        }

        let c = app.storage.getCampaign() || {};
        if (c.games) {
            c.games.map(e => $('.subheader-list li[data-gameid=' + e.id + ']').css("display", "block"));
        }

        // init topbar dropdowns
        $('body').on('click', '.dropdown-menu.hold-on-click', function (e) {
            e.stopPropagation();
        });

        // change language dropdown in header
        $('#lang>span').text(app.language.getLanguageForPresentation());
        var langTxt = $('#lang_dropdown .text');

        // change language on landing page
        $('.language-btn[data-lang=' + app.language.getLanguage() + ']').addClass('active');

        // add language change handler
        $('.language-btn').unbind().on('click', function (e) {
            e.preventDefault();

            $('.language-btn').removeClass('active');
            $(this).addClass('active');

            var lang = $(this).data('lang');
            if (langTxt.text() === lang) return;
            langTxt.text(lang);

            // prevents language picker from crashing when no campaign has been initialized yet
            const campaign = app.storage.getCampaign();
            const sheetVariable = campaign ? campaign.sheet : false;

            app.language.loadLanguage(sheetVariable, lang)
                .then(() => {
                    app.ui.initElements();
                    // close mobile menu
                    $('.btn-open-menu.visible-xs').click();
                    app.events.fire.reloadGameLanguage();
                })
                .catch((err) => {
                    app.ui.error("Campaign currently not available");
                    console.error("Cannot load language sheet. ", err);
                });

            $('body').removeAttr('data-lang').attr('data-lang', lang);
        });

        //set logged in user header
        if (app.state.loggedIn === true) {
            var candidate = app.storage.getCandidate();
            if (candidate && candidate.firstName && candidate.lastName) {
                $('.account-name').text(candidate.firstName + ' ' + candidate.lastName);
            } else {
                $('.account-name').text('Menu');
            }
            $('.account-dropdown').show();
        }

        // DEPRECATED: used only on mobile.html -- attach reminder functionality click-handler
        $('.btn-send-reminder').click(function () {
            // translations not updated here because all these reminder features may be something we will drop in the near future
            let txt = "Incorrect email address";
            $('.help-block').html(txt);
            $('.help-block').hide();
            var email = $('.email-field').val();
            if (!app.helpers.validateEmail(email)) {
                $('.help-block').show();
            } else {
                app.api.sendReminder(email,
                    function () {
                        $('.email_placeholder').html(email);
                        $('.reminder-sent').show();
                        $('.reminder-form').hide();
                    },
                    function (result) {
                        $('.help-block').html(result.responseJSON.message);
                        $('.help-block').show();
                    });
            }
        });

        // mobile menu
        $('.btn-open-menu').unbind().on('click', function (e) {
            e.preventDefault();
            if (window.innerWidth < 768) {
                $('.header-navbar').slideToggle();
            }
        });

        liquid.liquidStyle();

        this.select2init();
    },

    removeCookieBar: function () {
        $('.cookiebar').slideUp(200);
        app.storage.setCookieBarDisabled();
    },

    checkCookieBar: function () {
        if (app.storage.getCookieBarDisabled()) {
            $('.cookiebar').hide();
        }
    },

    select2init: function () {
        $('.select2me').each(function () {
            var placeholder = $(this).attr('placeholder');
            $(this).select2({
                placeholder: placeholder,
                minimumResultsForSearch: Infinity,
            });
        });
    },

    select2destroy: function () {
        $('.select2me').each(function () {
            $(this).select2('destroy');
        })
    }
};
