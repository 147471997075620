import $ from 'jquery';
import BaseForm from './BaseForm';
import ui from 'static/js/app/ui/ui';

//
// Show this form when:
//    (closed campaign) campaign.registrationClose === true
//
class ExternalIdLoginForm extends BaseForm {
    constructor(tabReference, buttonReference, campaign) {
        super(tabReference, buttonReference, campaign);

        // there are no tabs, only the login form
        $('.sign-up-in-tab-buttons').hide();
        $('.sign-up-tab').hide();
        this.tabReference.removeClass('start-hidden');
        this.tabReference.addClass('sign-up-tab');
        this.tabReference.show();

        this.firstNameField = this.tabReference.find('.fields .autofill-username');
        this.externalIdField = this.tabReference.find('.fields .autofill-externalId');
        this.tryAutoFill();
    }

    tryAutoFill() {
        const af = app.storage.pullAutoFill(af);
        if (!(af && af.username && af.externalId)) {
            // we don't have auto fill data stored, which means that this candidate did not followed a pre-registration link.
            // therefore, we don't need the auto fill feature
            return;
        }
        this.firstNameField.val(af.username);
        this.externalIdField.val(af.externalId);
    }

    validateForm() {
        let isValid = true;
        if (!this.validate.required(this.firstNameField)) {
            isValid = false;
        }
        if (!this.validate.required(this.externalIdField)) {
            isValid = false;
        }
        return isValid;
    }

    submit() {
        var data = {
            firstName: this.firstNameField.val(),
            externalId: this.externalIdField.val(),
            campaign: {
                id: this.campaign.id
            }
        };
        return app.api.loginPredefined(data)
            .then((res) => app.candidate.loginAndGoHome(res))
            .catch((e) => {
                this.tabReference.find('.fields > .help-block[data-text=default_error]').addClass(this.errorClass);
                ui.stopPageLoading();
            });
    }
}

export default ExternalIdLoginForm;
