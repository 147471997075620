export default {
    isEnabled: function () {
        var test = "test";
        try {
            sessionStorage.setItem(test, test);
            sessionStorage.removeItem(test);
            return true;
        } catch (e) {
            console.error("This website needs session storage to function.", e);
            return false;
        }
        // This validation method is better than :
        //    "sessionStorage" in window => true
        // because, most browsers can return true and then throw errors when you actually try to use the storage
        // For example, Chrome does this when "Content Settings" prevents setting any data
    },

    clear: function () {
        var arr = [];
        for (var i = 0; i < sessionStorage.length; i++) {
            if (sessionStorage.key(i).startsWith("game")) {
                arr.push(sessionStorage.key(i));
            }
        }
        for (var i = 0; i < arr.length; i++) {
            sessionStorage.removeItem(arr[i]);
        }
    },

    clearCampaign: function () {
        sessionStorage.removeItem("game-campaign");
    },

    setPageLangSheet: function (value) {
        let sheetId = "default-sheet";
        if (this.getCampaign()) {
            sheetId = this.getCampaign().sheet;
        }
        sessionStorage.setItem("game-" + sheetId, JSON.stringify(value));
    },

    getPageLangSheet: function () {
        let sheetId = "default-sheet";
        if (this.getCampaign()) {
            sheetId = this.getCampaign().sheet;
        }
        return JSON.parse(sessionStorage.getItem("game-" + sheetId));
    },

    setRefreshToken: function (value) {
        sessionStorage.setItem("game-refresh-token", value);
    },

    getRefreshToken: function () {
        return sessionStorage.getItem("game-refresh-token");
    },

    setToken: function (value) {
        sessionStorage.setItem("game-token", value);
    },

    getToken: function () {
        return sessionStorage.getItem("game-token");
    },

    clearBacklink: function () {
        sessionStorage.removeItem("game-backlink");
    },

    setCandidate: function (value) {
        sessionStorage.setItem("game-candidate", JSON.stringify(value));
    },

    getCandidate: function () {
        return JSON.parse(sessionStorage.getItem("game-candidate"));
    },

    setProfile: function (value) {
        sessionStorage.setItem("game-profile", JSON.stringify(value));
    },

    getProfile: function () {
        return JSON.parse(sessionStorage.getItem("game-profile"));
    },

    setCampaign: function (value) {
        sessionStorage.setItem("game-campaign", JSON.stringify(value));
    },

    getCampaign: function () {
        const campaign =  JSON.parse(sessionStorage.getItem("game-campaign"));
        if(typeof campaign === "string"){
            return null;
        }
        return campaign;
    },

    setSheet: function (value) {
        sessionStorage.setItem("game-sheet", value);
    },

    getSheet: function () {
        return sessionStorage.getItem("game-sheet");
    },

    setReferralId: function (value) {
        sessionStorage.setItem("game-ref", value);
    },

    getReferralId: function () {
        return sessionStorage.getItem("game-ref");
    },

    // use only from app.language obj
    setLanguage: function (value) {
        sessionStorage.setItem("game-lang", value);
    },

    // use only from app.language obj
    getLanguage: function () {
        return sessionStorage.getItem("game-lang");
    },

    setLightversion: function (value) {
        sessionStorage.setItem("game-lightversion", value);
    },

    getLightversion: function () {
        return sessionStorage.getItem("game-lightversion");
    },

    setCookieBarDisabled: function () {
        sessionStorage.setItem("game-cookiebar-disabled", "1");
    },

    getCookieBarDisabled: function () {
        return sessionStorage.getItem("game-cookiebar-disabled");
    },

    pullAutoFill: function () {
        let data = JSON.parse(sessionStorage.getItem("login-auto-fill"));
        sessionStorage.removeItem("login-auto-fill");
        return data;
    },

    setAutoFill: function (data) {
        sessionStorage.setItem("login-auto-fill", JSON.stringify(data));
    },

    setFlagProfilePageGoesToGames: function () {
        sessionStorage.setItem("temporary-flag-profilePageGoesToGames", true);
    },

    pullFlagProfilePageGoesToGames: function () {
        let data = JSON.parse(
            sessionStorage.getItem("temporary-flag-profilePageGoesToGames") ||
                false
        );
        sessionStorage.removeItem("temporary-flag-profilePageGoesToGames");
        return data;
    },

    setSelectedJobUri: function (uri) {
        sessionStorage.setItem("selected-job-uri", uri);
    },
    getSelectedJobUri: function () {
        return sessionStorage.getItem("selected-job-uri");
    },

    clearSelectedJobUri: function () {
        sessionStorage.removeItem("selected-job-uri");
    },

    setSelectedJobTitle: function (label) {
        sessionStorage.setItem("selected-job-title", label);
    },
    getSelectedJobTitle: function () {
        return sessionStorage.getItem("selected-job-title");
    },

    clearSelectedJobTitle: function () {
        sessionStorage.removeItem("selected-job-title");
    },

    setEscoCode: function (code) {
        sessionStorage.setItem("esco-code", code);
    },

    getEscoCode: function (code) {
        return sessionStorage.getItem("esco-code", code);
    },
    clearEscoCode: function (code) {
        sessionStorage.removeItem("esco-code");
    },
};
