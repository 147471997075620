class Validator {
    constructor(errorClass, campaignId) {
        this.errorClass = errorClass;
        this.campaignId = campaignId;
    }

    required(fieldReference) {
        let value = fieldReference.val();
        if (!value) {
            fieldReference.closest('.form-group').find('.help-block[data-text=field_required]').addClass(this.errorClass);
            return false;
        }
        return true;
    }

    passwords(fieldReference1, fieldReference2) {
        if (!this.required(fieldReference1)) {
            return false;
        }
        let value1 = fieldReference1.val();
        let value2 = fieldReference2.val();
        if (!app.helpers.checkPasswordStrength(value1)) {
            fieldReference1.closest('.form-group').find('.help-block[data-text=password_8_chars]').addClass(this.errorClass);
            return false;
        }
        if (value1 != value2) {
            fieldReference2.closest('.form-group').find('.help-block[data-text=password_not_match]').addClass(this.errorClass);
            return false;
        }
        return true;
    }

    email(fieldReference) {
        let value = fieldReference.val();
        if (!app.helpers.validateEmail(value)) {
            fieldReference.closest('.form-group').find('.help-block[data-text=incorrect_email]').addClass(this.errorClass);
            return false;
        }
        if (this.campaignId === 508 && !app.helpers.validateDeloitteEmail(value)) {
            fieldReference.closest('.form-group').find('.help-block[data-text=incorrect_email_domain]').addClass(this.errorClass);
            return false;
        }
        return true;
    }
}

export default Validator;
