//
// We have language code everywhere.
// This file is a start to bring all the language logic together in a single place and ease the
// eventually internationalization support rebuild.
//
export default {

    // the object built from Google Sheet data for the currently selected language
    lang_JSON: undefined,

    mapCodeToName: {
        zh: 'chinese',
        nl: 'dutch',
        en: 'english',
        fr: 'french',
        de: 'german',
        it: 'italian',
        ja: 'japanese',
        pt: 'portuguese',
        ru: 'russian',
        es: 'spanish',
        tr: 'turkish'
    },

    mapNameToCode: {
        chinese: 'zh',
        dutch: 'nl',
        english: 'en',
        french: 'fr',
        german: 'de',
        italian: 'it',
        japanese: 'ja',
        portuguese: 'pt',
        russian: 'ru',
        spanish: 'es',
        turkish: 'tr'
    },

    mapLangPresentation: {
        chinese: '中国人',
        dutch: 'Nederlands',
        english: 'English',
        french: 'Français',
        german: 'Deutsch',
        italian: 'Italiano',
        japanese: '日本',
        portuguese: 'Português',
        russian: 'Русский',
        spanish: 'Español',
        turkish: 'Türkçe'
    },

    defineLanguage: function () {
        let language = app.language.getLanguage();
        return (app.language.mapNameToCode[language] ? app.language.mapNameToCode[language] : "en")
    },

    // DEPRECATED: used only on mobile.html
    buildRegistrationReminderLanguageDto: function () {
        // id code from DB table 'languages'
        let dto;
        switch (app.language.getLanguage()) {
            case "dutch":
                dto = { id: 82, code: "nl" };
                break;
            case "spanish":
                dto = { id: 27, code: "es" };
                break;
            case "german":
                dto = { id: 23, code: "de" };
                break;
            case "french":
                dto = { id: 34, code: "fr" };
                break;
            case "italian":
                dto = { id: 51, code: "it" };
                break;
            case "japanese":
                dto = { id: 53, code: "ja" };
                break;
            case "portuguese":
                dto = { id: 89  , code: "pt" };
                break;
            case "russian":
                dto = { id: 94, code: "ru" };
                break;
            case "turkish":
                dto = { id: 122, code: "tr" };
                break;
            case "chinese":
                dto = { id: 134, code: "zh" };
                break;
            default:
                dto = { id: 1, code: "en" };
        }
        return dto;
    },

    updateFromUrl: function () {
        let code = app.helpers.getURLParameter('lang');
        let name = app.language.mapCodeToName[code];
        app.storage.setLanguage(name || 'english');
    },

    getLanguage: () => {
        const lang = app.storage.getLanguage();
        if (lang) {
            return lang;
        }

        const langStr = (navigator.language || navigator.userLanguage);
        const browserLanguage = langStr.indexOf("nl") > -1 ? "dutch" :
            langStr.indexOf("es") > -1 ? "spanish" :
                langStr.indexOf("de") > -1 ? "german" :
                    langStr.indexOf("ru") > -1 ? "russian" :
                    langStr.indexOf("tr") > -1 ? "turkish" :
                    langStr.indexOf("fr") > -1 ? "french" :
                    langStr.indexOf("ja") > -1 ? "japanese" :
                    langStr.indexOf("it") > -1 ? "italian" :
                    langStr.indexOf("pt") > -1 ? "portuguese" :
                    langStr.indexOf("zh") > -1 ? "chinese" :
                    "english";

        return browserLanguage;
    },

    getLanguageForPresentation: () => {
        let language = app.language.getLanguage();
        return !app.language.mapLangPresentation[language] ? "English" : app.language.mapLangPresentation[language];
    },

    getTextAccountActivatedCantPlayOnMobile: function () {
        var langStr = (navigator.language || navigator.userLanguage);
        var text = "Your account has been activated ! However, you need a minimum screen width of 768px to play the NeurOlympics. Please login with a PC/Laptop or Tablet.";
        if (langStr.indexOf("nl") > -1) {
            text = "Je account is geactiveerd! Om de NeurOlympics te spelen heb je een scherm nodig van minimaal 768px. Log daarom in op een PC, laptop of tablet.";
        } else if (langStr.indexOf("es") > -1) {
            text = "Tu cuenta está activada! Para jugar los NeurOlympics, necesitas una pantalla que tenga al menos 768px. Por favor inicia con un ordenador, portatil o tableta.";
        }
        return text;
    },

    getTranslation: (key) => {
        return app.language.lang_JSON[key];
    },

    languageUpdated: (data) => {
        app.storage.setLanguage(data.language);
        app.language.lang_JSON = data.translations;
        app.ui.setPageLang();
    },

    loadLanguage: (sheetID, language) => {
        return app.campaign.loadCampaignLanguage(sheetID, language)
            .then((translations) => app.events.fire.languageUpdated({ language, translations }))
    }
};
