import $ from 'jquery';
import Validator from "./Validator";
import ui from 'static/js/app/ui/ui';

class BaseForm {

    constructor(tabReference, buttonReference, campaign) {
        this.tabReference = $(tabReference);
        this.buttonReference = $(buttonReference);
        this.campaign = campaign;
        this.errorClass = 'has-error';
        this.validate = new Validator(this.errorClass, campaign.id);

        if (this.tabReference.length !== 1) throw new Error(`Can't create Form: selector '${tabReference}' returns no DOM element`);
        if (this.buttonReference.length !== 1) throw new Error(`Can't create Form: selector '${buttonReference}' returns no DOM element`);
    }

    init() {
        this.clearErrors();
        this.bindButton();
    }

    bindButton() {
        this.buttonReference
            .on('click touch', (e) => {
                e.preventDefault();
                this.buttonReference.prop('disabled', true);
                return Promise.resolve()
                    .then(() => this.clearErrors())
                    .then(() => { if (this.validateForm() === false) throw new Error("Form Validation failed") })
                    .then(() => ui.startPageLoading())
                    .then(() => this.submit())
                    .finally(() => {
                        app.helpers.fixIESignUpInBox(this.tabReference)
                        this.buttonReference.prop('disabled', false)
                    })
                    .catch((e) => {
                        console.error("Unhandled Form Error:", e)
                        ui.stopPageLoading()
                    });
            });
    }

    clearErrors() {
        this.tabReference.find('.help-block').removeClass(this.errorClass);
    }

    /**
     * Executes in-memory form validation and updates the DOM with errors, if any.
     *
     * @returns boolean, false when there are errors
     */
    validateForm() {
        throw new Error('Derived class (from BaseForm) must implement the validate() method');
    }

    submit() {
        throw new Error('Derived class (from BaseForm) must implement the submit() method');
    }
}

export default BaseForm;
