import $ from 'jquery';
import api from "static/js/app/base/api";

export default function (cb) {
    
    if(app.helpers.isMobile()) {
        app.ui.error("We're sorry, but we cannot measure your brain profile when playing the games on a mobile device. Please login with a PC/Laptop or Tablet.");
        return;
    }

    const unplayedGames = sessionStorage.getItem('unplayed-games');
    const nextGame = unplayedGames.length > 0 ? unplayedGames[0] : null;
    const nextPage = nextGame ? `game${nextGame}.html` : "game-types.html"

    $('#btn-next').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        // even if the user goes back to the home page, we don't show the intro-steps multiple times
        sessionStorage.setItem('game-sawIntro', "true");

        app.events.fire.navigate(nextPage);
    });

    //proceed with common page initialisation
    cb();

};
