import $ from "jquery";
import ErrorCodes from "./api-errors";
import { saveAs } from "file-saver";

export default {
    log: function (xmlHttpErrorObj) {
        let x = xmlHttpErrorObj || {};
        console.error(
            "Api Error: " +
            "HTTP " +
            x.status +
            ": " +
            x.statusText +
            " | " +
            JSON.stringify(x.responseJSON)
        );
    },

    isTokenValid: function (token) {
        try {
            const tokenPayload = token.split(".")[1];
            const { exp } = JSON.parse(atob(tokenPayload));
            const currentEpoch = new Date().getTime() / 1000;
            return exp > currentEpoch + 60;
        } catch (e) {
            return false;
        }
    },

    endpointIsPublic: function (url) {
        const openControllers = [
            "/api/candidate/authentication",
            "/api/candidate/health",
            "/api/candidate/campaigns",
            "/api/candidate/site-texts",
            "/api/candidate/registration/forms",
            "/api/candidate/reminder",
            "/api/candidate/questionnaires",
            "/api/candidate/email",
            "/api/candidate/sso-info",
        ];
        return openControllers.some((x) => url.startsWith(x));
    },

    sendRequestAsPromised: function (paramObject) {
        let self = this;
        return new Promise(function (resolve, reject) {
            paramObject.success = resolve;
            paramObject.error = (e) => {
                self.log(e);
                return reject(e && e.responseJSON);
            };
            self.sendRequest(paramObject);
        });
    },

    // DONT USE this method
    sendRequest: function (paramObject) {
        //
        // use sendRequestAsPromised() instead
        // when we don't have any more dependencies on sendRequest,
        // we can move $.ajax() to fetch()

        paramObject.contentType =
            paramObject.contentType || app.constants.http.contentType.json;

        const token = app.storage.getToken();

        const mainRequest = function (token) {
            paramObject.headers = paramObject.headers || {};
            paramObject.xhrFields = paramObject.xhrFields || {};

            paramObject.headers["X-Auth-Token"] = token;
            paramObject.headers["Accept-Language"] =
                app.language.defineLanguage();

            if (
                paramObject.type.toLowerCase() === "get" ||
                paramObject.type.toLowerCase() === "options" ||
                paramObject.type === undefined
            ) {
                // without CSRF token
                $.ajax(paramObject);
            } else {
                // with CSRF token
                $.ajax({
                    url: "/api/candidate/authentication/csrf",
                    type: "GET",
                    success: function (resp) {
                        paramObject.headers["X-CSRF-TOKEN"] = resp.value;
                        $.ajax(paramObject);
                    },
                    error: paramObject.error || function () { },
                });
            }
        };

        if (
            app.api.endpointIsPublic(paramObject.url) ||
            app.api.isTokenValid(token)
        ) {
            return mainRequest(token);
        }

        const refreshToken = app.storage.getRefreshToken();

        if (app.api.isTokenValid(refreshToken)) {
            $.ajax({
                url: "/api/candidate/authentication/refreshToken",
                type: "GET",
                headers: { "Refresh-Token": refreshToken },
                success: (res) => {
                    app.storage.setToken(res.token);
                    mainRequest(res.token);
                },
                error: paramObject.error || function () { },
            });
        } else if (paramObject.error) {
            paramObject.error({
                status: 401,
                statusText: "Unauthorized",
                responseJSON: [{ message: "refresh token expired" }],
            });
        }
    },

    // DEPRECATED : use promised based loadCampaignById() instead
    getCampaign: function (campaignId, success_cb, error_cb) {
        this.sendRequest({
            url: "/api/candidate/campaigns/" + campaignId,
            type: "GET",
            success: function (res) {
                app.events.fire.campaignUpdated(res);
                if (success_cb !== undefined) {
                    success_cb(res);
                }
            },
            error: function (err) {
                if (error_cb !== undefined) {
                    error_cb(err);
                }
            },
        });
    },

    loadCampaignById: function (id) {
        return this.sendRequestAsPromised({
            url: "/api/candidate/campaigns/" + id,
            type: "GET",
        }).then((c) => {
            app.events.fire.campaignUpdated(c);
            return c;
        });
    },

    loadCampaignByPath: function (path) {
        return this.sendRequestAsPromised({
            url: "/api/candidate/campaigns/path?path=" + path,
            type: "GET",
        }).then((c) => {
            app.events.fire.campaignUpdated(c);
            return c;
        });
    },

    autoRegister: function (campaignId) {
        return this.sendRequestAsPromised({
            url: "/api/candidate/authentication/auto/" + campaignId,
            type: "POST",
        });
    },

    getPreRegistrationDataByCode: function (code, campaignParam) {
        return this.sendRequestAsPromised({
            url:
                "/api/candidate/authentication/auto/" +
                campaignParam +
                "/code/" +
                code,
            type: "POST",
        });
    },

    // log in with username and external id
    loginPredefined: function (data) {
        return this.sendRequestAsPromised({
            url: "/api/candidate/authentication/predefined/login/auto",
            type: "POST",
            data: JSON.stringify(data),
        });
    },

    logout: function (succes_cb) {
        this.sendRequest({
            url: "/api/candidate/authentication/logout",
            type: "GET",
            success: function (result) {
                if (succes_cb !== undefined) {
                    succes_cb(result);
                }
            },
        });
    },

    login: function (data) {
        return this.sendRequestAsPromised({
            url: "/api/candidate/authentication/login",
            type: "POST",
            data: JSON.stringify(data),
        });
    },

    ssoLogin: function () {
      return this.sendRequestAsPromised({
          url: "/api/candidate/sso-info",
          type: "GET",
      });
  },

    registration: function (data) {
        return this.sendRequestAsPromised({
            url: "/api/candidate/authentication/register",
            type: "POST",
            data: JSON.stringify(data),
        }).then((res) => {
            return res;
        });
    },

    registrationConfirm: function (key) {
        return this.sendRequestAsPromised({
            url: "/api/candidate/authentication/register_confirm?key=" + key,
            type: "GET",
        }).then((res) => {
            return res;
        });
    },

    resetPasswordMailRequest: function (email, success_cb, error_cb) {
        this.sendRequest({
            url:
                "/api/candidate/authentication/password_change?email=" +
                encodeURIComponent(email),
            type: "GET",
            success: function (result) {
                if (success_cb !== undefined) {
                    success_cb(result);
                }
            },
            error: function (err) {
                if (error_cb !== undefined) {
                    error_cb(err);
                }
            },
        });
    },

    resetPasswordConfirm: function (password, key) {
        var data = { password: password };
        return this.sendRequestAsPromised({
            url: "/api/candidate/authentication/password_change_confirm/" + key,
            type: "PUT",
            data: JSON.stringify(data),
        });
    },

    getProfile: function () {
        // load the profile for the current logged in candidate
        return this.sendRequestAsPromised({
            url: "/api/candidate/profiles",
            type: "GET",
        })
            .then((profile) => {
                app.storage.setProfile(profile);
                return profile;
            })
            .catch((json) => {
                // when the profile does not exist, we return promise.resolved with 'false'
                if (json.code === ErrorCodes.PROFILE_NOT_CREATED) return false;
                throw "Can't load profile information from API";
            });
    },

    getProfileOptions: function () {
        return this.sendRequestAsPromised({
            url: "/api/candidate/profiles/data",
            type: "GET",
        });
    },

    createProfile: function (data, success_cb, error_cb) {
        if (!data.client) {
            data = Object.assign({}, data, { client: {} });
        }
        data.client.id = app.storage.getCandidate().id;

        this.sendRequest({
            url: "/api/candidate/profiles",
            type: "POST",
            data: JSON.stringify(data),
            success: function (result) {

                if (success_cb !== undefined) {
                    success_cb(result);
                }
            },
            error: function (result) {
                if (error_cb !== undefined) {
                    error_cb(result);
                }
            },
        });
    },

    updateProfile: function (data, success_cb, error_cb) {
        if (!data.client) {
            data.client = {};
        }
        data.client.id = data.id = app.storage.getCandidate().id;

        this.sendRequest({
            url: "/api/candidate/profiles",
            type: "PUT",
            data: JSON.stringify(data),
            success: function (result) {
                if (success_cb !== undefined) {
                    success_cb(result);
                }
            },
            error: function (result) {
                if (error_cb !== undefined) {
                    error_cb(result);
                }
            },
        });
    },

    saveGameResult: function (data, success_cb, error_cb) {
        var lineArray = [];
        data.result.forEach(function (el) {
            el.forEach(function (field, i) {
                // Replace semicolons with commas in string fields
                if (typeof field === 'string') {
                    el[i] = field.replaceAll(';', ',');
                }
            });
            var line = el.join(";");
            lineArray.push(line);
        });
        data.resultString = lineArray.join("\n");
        let body = JSON.stringify(data);
        this.sendRequest({
            url: "/api/candidate/games/result",
            type: "POST",
            data: body,
            success: function (result) {
                if (success_cb !== undefined) {
                    success_cb(result);
                }
            },
            error: function (result) {
                if (error_cb !== undefined) {
                    error_cb(result);
                }
            },
        });
    },

    getResults: function () {
        return this.sendRequestAsPromised({
            url: "/api/candidate/games/results",
            type: "GET",
            data: {
                clientId: app.storage.getCandidate().id,
            },
        });
    },

    getTermsAndConditions: function (campaignId) {
        return this.sendRequestAsPromised({
            url: "/api/candidate/campaigns/" + campaignId + "/condition",
            type: "GET",
        });
    },

    // DEPRECATED: used only on mobile.html
    sendReminder: function (email, success_cb, error_cb) {
        this.sendRequest({
            url: "/api/candidate/reminder/registration",
            type: "POST",
            data: JSON.stringify({
                email: email,
                campaignId: app.storage.getCampaign().id || 1,
                language: app.language.buildRegistrationReminderLanguageDto(),
            }),
            success: function (result) {
                if (success_cb !== undefined) {
                    success_cb(result);
                }
            },
            error: function (result) {
                if (error_cb !== undefined) {
                    error_cb(result);
                }
            },
        });
    },

    getCustomProfileForm: function (success_cb, error_cb, overrideID) {
        const id = overrideID || app.storage.getCampaign().id;

        this.sendRequest({
            url: "/api/candidate/registration/forms/campaigns/" + id,
            type: "GET",
            success: function (result) {
                if (success_cb !== undefined) {
                    success_cb(result);
                }
            },
            error: function (result) {
                if (error_cb !== undefined) {
                    error_cb(result);
                }
            },
        });
    },

    downloadCandidateReport: function () {
        //
        // cant download the file with $.ajax because that wont allow access to raw response binary
        //
        // why is fetch here? see BS-125

        function handleErrors(response) {
            // HTTP 400, 500 errors
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response;
        }

        // the client ID is obtained from the logged in client (BE)
        const options = {
            method: "GET",
            headers: {
                "X-Auth-Token": app.storage.getToken(),
                "Accept-Language": app.language.defineLanguage(),
            },
        };
        const url =
            "/api/candidate/campaigns/" + app.storage.getCampaign().id + "/pdf";

        return fetch(url, options)
            .catch((err) => {
                console.error("Network Failure", err);
                // network failures only, not HTTP failures
                throw new Error("Network Failure");
            })
            .then(handleErrors)
            .then((res) => res.blob())
            .then((blob) =>
                saveAs(blob, app.constants.downloads.candidateReportFileName)
            );
    },

    downloadCandidateArchetypeReport: function () {
        const candidateId = app.storage.getCandidate().id;
        const archetypePDfReportFilename = 'Neurolympics_Career_Advice_' + candidateId + '.pdf';
        function handleErrors(response) {
            // HTTP 400, 500 errors
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response;
        }

        // the client ID is obtained from the logged in client (BE)
        const options = {
            method: "GET",
            headers: {
                "X-Auth-Token": app.storage.getToken(),
                "Accept-Language": app.language.defineLanguage(),
            },
        };
        const url =
            "/api/candidate/campaigns/" +
            app.storage.getCampaign().id +
            "/pdf/archetype";

        return fetch(url, options)
            .catch((err) => {
                console.error("Network Failure", err);
                // network failures only, not HTTP failures
                throw new Error("Network Failure");
            })
            .then(handleErrors)
            .then((res) => res.blob())
            .then((blob) => saveAs(blob, archetypePDfReportFilename));
    },

    getAllGameBatteries: function (candidateId) {
        return this.sendRequestAsPromised({
            url: "/api/candidate/" + candidateId + "/gamebatteries",
            type: "GET",
        });
    },

    createNewGameBattery: function (candidateId) {
        return this.sendRequestAsPromised({
            url: "/api/candidate/" + candidateId + "/gamebatteries",
            type: "POST",
        });
    },

    getTopTraits: function (id) {
        return this.sendRequestAsPromised({
            url: "/api/candidate/games/top-trait-text",
            type: "GET",
            data: {
                clientId: id,
            },
        });
    },

    getTopArchetype: function (id) {
        return this.sendRequestAsPromised({
            url: "/api/candidate/games/top-archetype-description",
            type: "GET",
            data: {
                clientId: id,
            },
        });
    },

    postClientConsents: function (body) {
        return this.sendRequestAsPromised({
            url: "/api/candidate/clients/consent",
            type: "POST",
            contentType: "application/json",
            data: JSON.stringify(body),
        });
    },

    getClientConsents: function () {
        return this.sendRequestAsPromised({
            url: "/api/candidate/clients/consent",
            type: "GET",
            contentType: "application/json",
        });
    },

    /*
    Usage:

    app.api.polling(checkFunction, interval, timeout)
        .then((res) => resumeFunction(res))
        .catch(err => console.error("timeout error here: ", err));

    checkFunction:
        Must return a promise.
        This function does the ajax query and REJECTs while the result we are polling is not there.
        When the results are there, it RESOLVEs the promise with the result.

    resumeFunction
        The 'callback' we will invoke once, when the polling is done.
        We will pass the result from the last check.
    */
    polling: function (check, interval, timeout) {
        const pollTimeout = timeout || 10000;
        const pollInterval = interval || 3000;
        var endTime = new Date().getTime() + pollTimeout;
        return new Promise(function (resolve, reject) {
            (function tryAgain() {
                check()
                    .then((res) => resolve(res))
                    .catch((err) => {
                        // check errors are meaningless
                        // we keep polling until the timeout passes
                        if (new Date().getTime() < endTime) {
                            setTimeout(tryAgain, pollInterval);
                        } else {
                            return reject("timeout on polling");
                        }
                    });
            })();
        });
    },
};
