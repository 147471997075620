import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole } from "@sentry/integrations";

export default function () {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        tracesSampleRate: parseInt(process.env.SENTRY_TRACE_SAMPLE_RATE),
        environment: process.env.NODE_ENV,
        release: process.env.SENTRY_RELEASE,
        debug: process.env.DEBUG,
        integrations: [new BrowserTracing()],
        // Only send calls to/from our endpoints
        // (i.e. exclude calls to other domains, such as analytics ones)
        whitelistUrls: [window.location.host || window.location.hostname],
        normalizeDepth: 10,
    });
}
