import $ from "jquery";
import storage from "static/js/app/base/storage";

export const fetchSuggests = (suggests, text, lang, job_selector, api_error) =>
    fetch(
        `https://ec.europa.eu/esco/api/search?type=occupation&language=${lang}&text=${text}&offset=0&limit=20`,
        {
            method: "GET",
            headers: {
                cookie: "escoLanguage=en",
                Connection: "keep-alive",
                Pragma: "no-cache",
                "Cache-Control": "no-cache",
                "sec-ch-ua":
                    '"(Not(A:Brand";v="8", "Chromium";v="99", "Google Chrome";v="99"',
                Accept: "text/html, */*; q=0.01",
                "X-Requested-With": "XMLHttpRequest",
                "sec-ch-ua-mobile": "?0",
                "User-Agent":
                    "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.51 Safari/537.36",
                "sec-ch-ua-platform": '"macOS"',
                "Sec-Fetch-Site": "same-origin",
                "Sec-Fetch-Mode": "cors",
                "Sec-Fetch-Dest": "empty",
                Referer:
                    "https://ec.europa.eu/esco/portal/occupation?uri=http%3A%2F%2Fdata.europa.eu%2Fesco%2Fisco%2FC2519&conceptLanguage=en&full=true",
                "Accept-Language":
                    "ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,de-DE;q=0.6,de;q=0.5",
                Cookie: 'JSESSIONID=gVOYUsQ1wsqkwL3W4go8p43RfVo5jgt7Gj7i9GuXatfT07oy7Wq5\\u0021337774366; cck1={"cm":false,"all1st":false,"closed":false}',
            },
        }
    )
        .then((response) => {
            $(job_selector).add("required");
            $(api_error).hide();
            $(job_selector).removeClass("disabled");

            response.json().then((data) => {
                suggests(transformData(data));
            });
        })
        .catch((err) => {
            console.error("ESCO API error", err);
            $(job_selector).removeClass("required");
            $(api_error).show();
            $(job_selector).addClass("disabled");
            $(job_selector).val("");
            $(job_selector).blur();
            $(job_selector).attr("placeholder", "");
            storage.setSelectedJobTitle("not_found");
            storage.clearSelectedJobUri();
            storage.clearEscoCode();
        });

const transformData = (responseData) =>
    responseData._embedded.results.map((result) => ({
        value: `${result.title}`,
        label: `${result.title}`,
        uri: `${result.uri}`,
        title: `${result.preferredLabel.en}`,
        escoCode: `${result.code}`,
    }));

export const getEscoTitle = (
    escoCode,
    escoUri,
    lang,
    job_selector,
    api_error
) => {
    fetch(
        `https://ec.europa.eu/esco/api/resource/occupation?conceptLanguage=${lang}&uri=${escoUri}`,
        {
            method: "GET",
            headers: {
                cookie: "escoLanguage=en",
                Connection: "keep-alive",
                Pragma: "no-cache",
                "Cache-Control": "no-cache",
                "sec-ch-ua":
                    '"(Not(A:Brand";v="8", "Chromium";v="99", "Google Chrome";v="99"',
                Accept: "text/html, */*; q=0.01",
                "X-Requested-With": "XMLHttpRequest",
                "sec-ch-ua-mobile": "?0",
                "User-Agent":
                    "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.74 Safari/537.36",
                "sec-ch-ua-platform": '"macOS"',
                "Sec-Fetch-Site": "same-origin",
                "Sec-Fetch-Mode": "cors",
                "Sec-Fetch-Dest": "empty",
                Referer:
                    "https://ec.europa.eu/esco/portal/occupation?uri=http%3A%2F%2Fdata.europa.eu%2Fesco%2Fisco%2FC2519&conceptLanguage=en&full=true",
                "Accept-Language":
                    "ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,de-DE;q=0.6,de;q=0.5",
                Cookie: 'JSESSIONID=SY-2FsgufJypBq_jNjrhXfD6NFoBwAGO4Be1yer2RLzjBL5UkjEw\\u0021-1019942662; cck1={"cm":false,"all1st":false,"closed":false}',
            },
        }
    )
        .then((response) => {
            response.json().then((data) => {
                if (!$(".noOccupation_checkbox").is(':checked')){
                    $(job_selector).val(
                        `${data.preferredLabel[lang]}`
                    );
                    $(job_selector).attr(
                        "title",
                        `${data.preferredLabel[lang]}`
                    );
                    storage.setEscoCode(
                            `${escoCode}`
                        );
                    storage.setSelectedJobTitle(`${ data.preferredLabel[lang]}`)
                }
            });
        })
        .catch((err) => {
            $(job_selector).removeClass("required");
            $(api_error).show();
            $(job_selector).addClass("disabled");
            $(job_selector).val("");
            $(job_selector).blur();
            $(job_selector).attr("placeholder", "");
        });
};
