import $ from "jquery";
import Game1 from "static/js/app/game/game1";
import Game2 from "static/js/app/game/game2";
import Game3 from "static/js/app/game/game3";
import Game4 from "static/js/app/game/game4";
import Game5 from "static/js/app/game/game5";
import GamesDummy from "static/js/app/game/dummy";
import mobileUtils from "static/js/app/game/mobile";
import constants from "static/js/app/base/constants";
import ui from "static/js/app/ui/ui";
import { selectResultTemplate } from "./game-types";
import * as Sentry from '@sentry/browser';

// TODO FIX
// global variables that several games JS assume to exist and 'share'
window.actual_JSON = {};
window.feedbackText = {};
window.background = {};
window.ltext = {};
window.powered = {};
window.pausebutton = {};
window.menugr = {};
window.b = {};
window.keyimgs = {};
window.feedbackFrame = {};
window.cnum = {};
window.game = {}; // represents the current game running

function startGame(nr, cb) {

    if (
        app.storage
            .getCampaign()
            .games.map((el) => el.id)
            .indexOf(nr) === -1
    ) {
        // tried to play a game ID that is not allowed on the campaign
        app.events.fire.navigate("game-types.html");
        return;
    }

    setupDom(nr);

    if (app.helpers.isMobile()) {
        mobileUtils.activateMobileCss();
    }

    if (app.campaign.isDemo()) {
        // demo campaign have no consents
        cb();
        return;
    }

    return app.api
        .getClientConsents()
        .then((consents) => {
            const consent =
                consents.find(
                    (x) => x.key === constants.consents.sharePartner
                ) || {};
            if (consent.accepted === true) {
                cb();
                return;
            }
            // the consent can be read from the api, but it is not marked as "accepted"
            // the games cannot be played

            Sentry.captureMessage(`Cannot play game '${nr}' because Consent '${constants.consents.sharePartner
                }' is not accepted. Client ID ${app.storage.getCandidate().id
                } Campaign ID ${app.storage.getCampaign().id}`);




            const name = (app.storage.getCampaign().partner || {}).name || "us";
            let msg = `You have not consented to share your game results with ${name}. Please update your consents or contact us at support@brainsfirst.com`;
            app.ui.error(msg);
        })
        .catch((err) => {
            console.error("Can't read client consents: ", err);
            app.ui.error(
                "We are unable to confirm you have accepted the game's conditions. Please try again later."
            );
        });
}

function setupDom(nr) {
    if (!app.campaign.isDemo()) {
        $(".render-if-demo").css("display", "none");
        window.onbeforeunload = () =>
            "Leaving the page will cause you to lose your results!";
        return;
    }

    $(".render-if-not-demo").css("display", "none");
    $("#btn-demo-next-game").click(function () {
        app.events.fire.navigate("game" + (nr + 1) + ".html");
    });
    $("#btn-demo-previous-game").click(function () {
        app.events.fire.navigate("game" + (nr - 1) + ".html");
    });

    const campaignGames = app.storage.getCampaign().games.map((e) => e.id);
    if (campaignGames.indexOf(nr + 1) == -1) {
        $("#btn-demo-next-game").remove();
    }
    if (campaignGames.indexOf(nr - 1) === -1) {
        $("#btn-demo-previous-game").remove();
    }
}

function game1(cb) {
    startGame(1, function () {
        const game = new Game1(
            ".gamecontainer",
            app.language.getLanguage(),
            app.campaign.isLightversion()
        );
        window.game = game;
        game.init();

        nextOrResults(1);

        game.initDummyAllGames = function () {
            new GamesDummy(app).initDummyAllGames();
        };
        //proceed with common page initialisation

        cb();
    });
}

function game2(cb) {
    startGame(2, function () {
        const game = new Game2(
            ".gamecontainer",
            app.language.getLanguage(),
            app.campaign.isLightversion()
        );
        window.game = game;
        game.init();

        nextOrResults(2);

        game.initDummyAllGames = function () {
            new GamesDummy(app).initDummyAllGames();
        };
        //proceed with common page initialisation

        cb();
    });
}

function game3(cb) {
    startGame(3, function () {
        const game = new Game3(
            ".gamecontainer",
            app.language.getLanguage(),
            app.campaign.isLightversion()
        );
        window.game = game;
        game.init();

        nextOrResults(3);

        game.initDummyAllGames = function () {
            new GamesDummy(app).initDummyAllGames();
        };
        //proceed with common page initialisation

        cb();
    });
}

function game4(cb) {
    startGame(4, function () {
        const game = new Game4(
            ".gamecontainer",
            app.language.getLanguage(),
            app.campaign.isLightversion()
        );
        window.game = game;
        game.init();
        const campaignGames = app.storage.getCampaign().games.map((e) => e.id);
        if (campaignGames[campaignGames.length - 1] === 4) {
            sessionStorage.setItem("unplayed-games", "");
            $(".btn-proceed").bind("click", () =>
                selectResultTemplate()
            );
        } else {
            $(".btn-proceed").attr("data-text", "next_game");
            $(".btn-proceed").bind("click", () =>
                app.events.fire.navigate("game-types.html")
            );
        }

        //proceed with common page initialisation
        cb();
    });
}

function game5(cb) {
    startGame(5, function () {
        const game = new Game5();
        window.game = game;
        game.init(cb);
        $(".btn-proceed").bind("click", () =>
            app.events.fire.navigate("resultscreens1.html")
        );
    });
}

const nextOrResults = (currentGame) => {
    let unplayedGames = sessionStorage.getItem("unplayed-games");
    let newUnplayed =
        unplayedGames.replace(/\s/g, "") &&
        unplayedGames.split(",").filter((g) => g != currentGame);
    sessionStorage.setItem("unplayed-games", newUnplayed);

    if (newUnplayed.length > 0) {
        let nextGame = newUnplayed.shift();
        $(".btn-proceed").bind("click", function () {
            app.events.fire.navigate(`game${nextGame}.html`);
        });
    } else {
        $(".btn-proceed").data("text", "to_highscore");
        ui.setPageLang();
        if (app.campaign.isPyramid()) {
            $(".btn-proceed").bind("click", () =>
                app.events.fire.navigate('resultscreens1-one-topskill.html')
            );
        } else {
            $(".btn-proceed").bind("click", () =>
                app.events.fire.navigate('resultscreens1.html')
            );
        }
    }
};

export default {
    game1,
    game2,
    game3,
    game4,
    game5,
};
