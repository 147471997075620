
import $ from 'jquery';
import BaseForm from './BaseForm';
import Constants from "../base/constants";
import ErrorCodes from '../base/api-errors';

//
// This form always appears together with the LoginForm (2 tabs)
//
class RegistrationForm extends BaseForm {

    constructor(tabReference, buttonReference, campaign, onSuccess) {
        super(tabReference, buttonReference, campaign);
        this.emailField = this.tabReference.find('.emailAddress-sign-up-form');
        this.passwordField = this.tabReference.find('.password-sign-up-form');
        this.password2Field = this.tabReference.find('.passwordConfirm-sign-up-form');
        this.firstNameField = this.tabReference.find('.firstName-sign-up-form');
        this.lastNameField = this.tabReference.find('.lastName-sign-up-form');
        this.onSuccess = onSuccess || function () { };
        this.loginForm = null;
        this.formSwitcher = null;
    }

    handle(loginForm) {
        this.loginForm = loginForm;
    }

    setFormSwitcher(domElement) {
        const self = this;
        // called when the user switches between "SIGN UP" and "LOG IN" tabs
        self.formSwitcher = domElement;
        self.formSwitcher.click(function () {
            const parent = $(this).closest('.sign-up-in-tab-buttons');
            parent.find('a').css("transition-duration", Constants.ui.animationSpeed / 1000 + "s").removeClass('active');
            $(this).addClass('active');
            $('.sign-up-in-box .sign-up-in-tabs').stop().animate({ "left": $(this).index() * -100 + "%" }, Constants.ui.animationSpeed);
            if (self.loginForm != null) {
                self.loginForm.lastPassFix();
            }
        });
    }

    init() {
        super.init();
        if (this.loginForm != null) {
            this.loginForm.init()
        }
    }

    validateForm() {
        let isValid = true;
        if (!this.validate.required(this.firstNameField)) {
            isValid = false;
        }
        if (!this.validate.required(this.lastNameField)) {
            isValid = false;
        }
        if (!(this.validate.required(this.emailField) && this.validate.email(this.emailField))) {
            isValid = false;
        }
        if (!this.validate.required(this.passwordField)) {
            isValid = false;
        }
        if (!this.validate.required(this.password2Field)) {
            isValid = false;
        }
        if (!this.validate.passwords(this.passwordField, this.password2Field)) {
            isValid = false;
        }
        return isValid;
    }

    displayApiError(e) {
        let errorCode = -1;
        const haveAnErrorCode = e && e.length > 0 && e[0].code > 0;
        if (haveAnErrorCode) {
            errorCode = e[0].code;
        }
        switch (errorCode) {
            case ErrorCodes.EMAIL_EXISTS:
                this.emailField.closest('.form-group').find('p.help-block[data-text=email_already_exists]').addClass(this.errorClass);
                break;
            case ErrorCodes.REGISTRATION_NOT_CONFIRMED:
                this.emailField.closest('.form-group').find('p.help-block[data-text=email_confirmationLink_not_used]').addClass(this.errorClass);
                break;
            default:
                this.tabReference.closest('.form-group').find('p.help-block[data-text=default_error]').addClass(this.errorClass);
        }
    }

    submit() {
        let data = {
            email: this.emailField.val(),
            password: this.passwordField.val(),
            password2: this.password2Field.val(),
            firstName: app.helpers.toTitleCase(this.firstNameField.val()),
            lastName: app.helpers.toTitleCase(this.lastNameField.val()),
            campaign: {
                id: app.storage.getCampaign().id,
            },
            referral: app.storage.getReferralId() || "0000"
        };
        return app.api.registration(data)
            .then(() => {
                this.tabReference.closest('.sign-up-in-box').css("display", "none");
                $('.account-created-popup').css("display", "flex");
                this.onSuccess();
            })
            .catch((e) => {
                this.displayApiError(e);
            })
            .finally(() => { ui.stopPageLoading() });
    }
}

export default RegistrationForm;
