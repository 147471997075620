import $ from 'jquery';
import common from 'static/js/app/game/common';
import screenUtils from 'static/js/app/game/screen';

// This injects the source-controlled seed-random-lib into Math object
//   using the npm package was not loading the code (v3.0.5 latest version)
//   could be because the v3 release has braking changes ('Math' is no longer injected with stuff)
//   we don't know what version we have committed here
import 'static/js/libraries/seedrandom';

export default class Game5 {
    constructor() {
        this.animSpeed = 500;
        this.tickRate = 50;
        this.decisionTime = 3;
        this.wordsPerMinute = 250;
        this.trialFile = undefined;
        this.trialListFile = 'gamedata/personality.json';
        this.lastTime = Date.now();
        this.reminder = undefined;
        this.keys = [];
        this.resultData = [["Trial", "TType", "Response_Code", "Total_RT", "Browser", "OS", "Timestamp"]];
        this.trialNumber = 0;
        this.$me = $('.personality-carousel');

        $('.reminder').css({
            'opacity': '0',
            'transition-property': 'opacity',
            'transition-duration': (this.animSpeed / 1000) + 's'
        });

        app.events.subscribe.reloadGameLanguage(() => {
            const self = this;
            const lang = app.language.getLanguage();
            $('[data-personality-key]').each(function () {
                const value = self.trialFile[$(this).attr('data-personality-key')][lang];
                $(this).html(value);
            })
        });
    }

    init(cb) {
        this.updateTick(this.tickRate);

        app.api.sendRequest({
            url: this.trialListFile,
            type: 'GET',
            contentType: 'application/json',
            success: (result) => {
                this.trialFile = result;

                Math.seedrandom(app.storage.getCandidate().id);
                this.keys = this.shuffle(Object.keys(this.trialFile));
                this.questionIndex = 0;

                this.numPages = this.$me.children().length + this.keys.length;
                this.pagesDone = 0;

                app.helpers.ieFlexFix();

                // cycle carousel when clicking a next-page type of button
                $('.personality-carousel .next').on('click touch', () => {
                    this.next();
                });

                $(".down_arrow").on('click touch', function () {
                    $('html, body').animate({
                        scrollTop: $(window).scrollTop() + $(window).height()
                    });
                });

                cb();
            },
            error: (e) => {
                app.ui.error("Personality data not found.");
                console.error(e);
            }
        });
    }

    newQuestion() {
        if (this.questionIndex >= this.keys.length) {
            return false
        }

        const thisChild = $('.personality-carousel').children().filter("[data-slide-type=exc]").last();

        const key = this.keys[this.questionIndex];
        const value = this.trialFile[key][app.language.getLanguage()];

        thisChild.find(".var-heading").attr("data-personality-key", key).html(value);
        thisChild.find("input").attr("var", key);
        const leftOption = thisChild.find(".option.left");
        const leftOptionValue = leftOption.find("input").attr("value");
        const rightOption = thisChild.find(".option.right");
        const rightOptionValue = rightOption.find("input").attr("value");

        leftOption.find("label").attr("for", key + "#" + leftOptionValue);
        leftOption.find("input").attr("id", key + "#" + leftOptionValue);
        leftOption.find("input").attr("var", key);
        rightOption.find("label").attr("for", key + "#" + rightOptionValue);
        rightOption.find("input").attr("id", key + "#" + rightOptionValue);
        rightOption.find("input").attr("var", key);

        const that = this;
        thisChild.find('input[type=radio]').on('click', function () {
            if ($(this).prop('checked')) { // was the (hidden) radio button actually changed?
                that.updateData($(this));
                that.next();
            }
        });

        if (this.questionIndex < this.keys.length - 1) {
            thisChild.clone().insertAfter(thisChild);
        }

        return this.questionIndex++;
    }

    shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }

        return a;
    }

    getOffset() {
        let offset = parseFloat(this.$me[0].style.left || 0);
        let pageNr = Math.abs(Math.round(offset / 100));

        return [offset, pageNr];
    }

    next() { // functionality for next page animation
        this.disableReminder();

        let inputs = $('input[type=radio]');
        if (inputs.attr('disabled')) {
            return;
        } else inputs.attr('disabled', true);

        let pageNr = this.getOffset()[1];
        let pages = this.$me.children().filter(':visible');

        if ($(window).scrollTop() > 3) {
            $('html, body').animate({
                scrollTop: 0
            });
        }

        if (pages.eq(pageNr + 1).attr("data-slide-type") === "exc") {
            const questionAdded = this.newQuestion();
            if (questionAdded > 1) {
                pages.filter('[data-slide-type=exc]:visible:first').hide();
                this.$me.css("left", (parseInt(this.$me[0].style.left) + 100) + "%");
            }
        }

        let offset = this.getOffset()[0];
        this.$me.animate({
            'left': (offset - 100) + "%"
        }, this.animSpeed);

        screenUtils.updateProgress(++this.pagesDone / this.numPages);

        window.setTimeout(() => {
            inputs.attr('disabled', false);
            this.lastTime = Date.now();
            this.setReminder(pages.eq(pageNr + 1));
            if (pageNr == pages.length - 2) {
                common.saveGameData(this.resultData, 5, 0);
            }
        }, this.animSpeed);
    }

    setReminder($page) {
        if ($page.attr("data-slide-type") == "exc") {
            let words = 0;

            let time = (this.decisionTime + words / this.wordsPerMinute * 60) * 1000;
            this.reminder = setTimeout(() => {
                $page.find('.reminder').css({
                    "opacity": 1,
                    "visibility": "visible"
                });
            }, time);
        }
    }

    disableReminder() {
        clearTimeout(this.reminder);
        $('.reminder').css({
            "opacity": 0,
            "visibility": "hidden"
        });
    }

    updateData($radio) {
        this.resultData.push([this.trialNumber++, $radio.attr("var"), parseInt($radio.attr("value")), Date.now() - this.lastTime, app.helpers.getBrowserName(), navigator.platform, app.helpers.getTimestamp()]);
    }

    updateTick(tickrate) {
        if ($(window).scrollTop() + $(window).height() + 10 > $(document).height()) {
            $('.down_arrow').hide();
        } else {
            $('.down_arrow').show();
        }

        setTimeout(this.updateTick, tickrate);
    }
}
