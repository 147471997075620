import $ from 'jquery';
import FastClick from 'fastclick';
import * as PIXI from 'pixi.js'
import Timer from 'static/js/libraries/cs-timer';
import common from 'static/js/app/game/common';
import screenUtils from 'static/js/app/game/screen';
import mobileUtils from 'static/js/app/game/mobile';

function Game2(parentDiv, langparam, lightversion) {
    var path = "gamedata/game2img/",
        list = [path + "background.png?v"+Math.random(), path + "x.png", path + "powered.png", path + "ii.png",
            path + "canup.png", path + "canupg.png", path + "canup2.png", path + "canup2g.png",
            path + "canstraight.png", path + "canstraightg.png", path + "canstraight2.png", path + "canstraight2g.png",
            path + "boxes.png", path + "0.png", path + "1.png", path + "2.png", path + "3.png", path + "4.png", path + "5.png",
            path + "6.png", path + "7.png", path + "bulletred.png", path + "bulletred2.png", path + "bulletred3.png",
            path + "0ex1.png", path + "0ex2.png", path + "1ex1.png", path + "1ex2.png", path + "2ex1.png", path + "2ex2.png",
            path + "kb_0.png", path + "kb_1.png", path + "kb_all.png", path + "kb_o.png", path + "kb_q.png", path + "tablet.png",
            path + "tabletCol.png", path + "tabletGrey.png"
        ],
        bulletimgs = ["bulletred3.png", "bulletred2.png", "bulletred.png", "bulletred.png"],
        shotDurations = [500, 500, 1000, 1000],
        explImgs = [0, 0, 0, 0, 0, 0, 1, 2],
        neuron_duration = 5000,
        explCols = [0x4fbadf, 0x4fbadf, 0x4fbadf, 0xe9b600, 0xff1946, 0x4fbadf, 0xff1946, 0xe9b600],
        bg, height, width, size,
        stage, renderer, animationLoop, resizeProc,
        prog = 0,
        overallscore = 0,
        practiceround = true,
        practiceround2 = false,
        instructionPhase = true,
        xo, fieldHeight, fieldWidth, plsize, ploffset,
        cannons = [], cannonsDeactivated = [],
        cannonTexts = ["", "", "", ""],
        ctexts = [],
        bulletTimer = [],
        bullets = [],
        horizontalCounter = 0,
        iti = 5400,
        ititimer;

    var blacklayer;
    var bglayer;
    var homebutton;
    var msgFrame;
    var msgText;
    var msgText2;
    var keyimg;
    var contFrame;
    var contText;
    var msgText3;
    var resolution = "";
    //----------------------------------------INIT-------------------------------------------------------------------
    this.init = function () {
        common.checkIfGameIsPlayed(2, initializeGame);

        function initializeGame(rsl) {
            $(function () {
                FastClick.attach(document.body);
            });
            resolution = rsl;
            common.loadGameLanguageSheet(app.defaults.gameLangSheet, langparam, function (response) {
                actual_JSON = response;
                actual_JSON = actual_JSON.game2;
                $(parentDiv).css({
                    "overflow": "hidden",
                    "position": "absolute",
                    "width": "100%"
                });
                $(parentDiv).html("<div id=\"svg-container\"> </div>");
                $('.game-score-box').hide();

                PIXI.settings.PRECISION_FRAGMENT = resolution; //this makes text looks better
                PIXI.settings.RENDER_OPTIONS.roundPixels = true;

                renderer = PIXI.autoDetectRenderer(screenUtils.getWindowWidthOrMin(parentDiv), screenUtils.getWindowHeightOrMin(parentDiv), {
                    preseveDrawingBuffer: true,
                    roundpixels: true,
                    antialias: false
                });
                stage = new PIXI.Container();
                renderer.view.style.position = "absolute";
                renderer.view.style.display = "block";
                renderer.view.style.overflow = "hidden";
                renderer.view.mozOpaque = true;
                document.body.appendChild(renderer.view);
                var text = new PIXI.Text('', {
                    fontFamily: 'Titillium Web',
                    fontSize: 18,
                    fill: '#fff',
                    align: 'center'
                });
                text.anchor.x = 0.5;
                text.anchor.y = 0.5;
                text.x = renderer.width / 2;
                text.y = screenUtils.getWindowHeightOrMin(parentDiv) / 2;
                stage.addChild(text);
                renderer.render(stage);
                PIXI.loader
                    .add(list)
                    .on("progress", loadProgressHandler)
                    .load(setup);

                function loadProgressHandler(loader, resource) {
                    text.setText('loading.. ' + Math.floor(loader.progress) + "%");
                    renderer.render(stage);
                }

                function setup() {
                    renderer.clearBeforeRender = false;
                    if (app.helpers.isMobile()) {
                        mobileUtils.activateMobileMode();
                    }
                    onResize();
                }

                var lastOrientation = window.orientation;

                function resizeOnOrientationChange() {
                    if (lastOrientation != window.orientation) {
                        $(window).trigger('resize');
                    }
                    lastOrientation = window.orientation;
                }

                setTimeout(resizeOnOrientationChange, 20);

                $(window).resize(function () {
                    if (!resizeProc) {
                        onResize();
                        if (app.helpers.isMobile()) {
                            setTimeout(function () {
                                mobileUtils.fullscreenCheck();
                            }, 120);
                        }
                    }
                });
            });

            app.events.subscribe.reloadGameLanguage(() => setJSON(app.language.getLanguage()));
        }
    }
    // ------------------------------------------ ON RESIZE -----------------------------------------------------------------------
    function onResize() {

        resizeProc = true;
        cancelAnimationFrame(animationLoop);
        $('html,body').css('cursor', 'default');
        if (!instructionPhase) {
            pause();
        }
        stage.destroy(true);
        stage = new PIXI.Container();

        var id = window.setTimeout(function () {
        }, 0);
        while (id--) {
            window.clearTimeout(id);
        }
        renderer.resize(screenUtils.getWindowWidthOrMin(parentDiv), screenUtils.getWindowHeightOrMin(parentDiv));
        setTimeout(function () {
            resizeProc = false;
        }, 10);
        setTimeout(function () {
            width = screenUtils.getWindowWidthOrMin(parentDiv);
            height = screenUtils.getWindowHeightOrMin(parentDiv);
            size = Math.min(width, height) / 20;
            renderer.resize(width, height);
            defineField();
            if (app.helpers.checkDeviceOrientation()) {
                if (!pauseswitch) {
                    resume();
                } else {
                    pause();
                }
            } else {
                deviceError();
            }
            app.helpers.firefoxGameHack();

        }, 1);
    }

    function deviceError() {
        prepareToStart();
        pauseswitch = true;
        document.onkeydown = bglayer.mousedown = bglayer.touchstart = function () {
        };
        screenUtils.updateProgress(prog, overallscore, practiceround, actual_JSON, practiceround2);
        stage.removeChildren();
        defineField();
        drawBg();
        drawErrorMessage(actual_JSON.device1, actual_JSON.device2, "tablet.png");

    }

    //--------------------------------SET JSON LANG ------------------------------------------------------
    function setJSON(l) {
        common.loadGameLanguageSheet(app.defaults.gameLangSheet, l, function (response) {
            actual_JSON = response;
            actual_JSON = actual_JSON.game2;
            pause();
            screenUtils.updateProgress(prog, overallscore, practiceround, actual_JSON, practiceround2);
        });
    }

    //-------------------------------------------START GAME---------------------------------------------------
    function startGame() {
        document.onkeydown = bglayer.mousedown = bglayer.touchstart = function () {
        }
        removeMessage();
        try {
            stage.removeChild(text);
        } catch (e) {
        }
        var text = new PIXI.Text('', {
            fontFamily: 'Titillium Web',
            fontSize: 100,
            fill: '#fff',
            align: 'center'
        });
        text.anchor.x = 0.5;
        text.anchor.y = 0.5;
        text.x = width / 2;
        text.y = screenUtils.getWindowHeightOrMin(parentDiv) / 2;
        stage.addChild(text);
        renderer.render(stage);
        text.setText("2");
        setTimeout(function () {
            text.setText("1");
            setTimeout(function () {
                if (!pauseswitch) {
                    $('.game-score-box').show();
                    instructionPhase = false;
                    ititimer = new Timer();
                    resumeGame();
                    ititimer.start();
                    startStimulus(getStimulus());
                }
            }, 1000);
        }, 1000);
    }

    //---------------------------------RESUME-----------------------------------------------------------------
    function resumeGame() {
        document.onkeydown = bglayer.mousedown = bglayer.touchstart = function () {
        }
        screenUtils.updateProgress(prog, overallscore, practiceround, actual_JSON, practiceround2);
        stage.removeChildren();
        defineField();
        drawBg();
        drawHangar();
        for (var i = 0; i < 4; i++) {
            if ((practiceround2 && i < 2) || (practiceround && i >= 2) || (!practiceround && !practiceround2)) {
                drawCannon(i, true);
            }
            bullets[i] = new PIXI.Sprite.fromImage(path + bulletimgs[i]);
            bullets[i].anchor.x = 0.5;
            bullets[i].visible = false;
            bullets[i].cacheAsBitmap = false;
            stage.addChild(bullets[i]);
        }
        for (var i = 0; i < 4; i++) {
            if ((practiceround2 && i < 2) || (practiceround && i >= 2) || (!practiceround && !practiceround2)) {
                drawCannonText(i);
                activateCannons(i);
            }
        }
        makemenu();
        for (var i = 0; i < 6; i++) {
            if (neuronTimer[i] != null && neuronTimer[i].getDuration() > 0) {
                stage.addChild(neurons[i]);
            }
        }
        if (ititimer.getStatus() == "paused") ititimer.resume();
        if (leveltimer.getStatus() == "paused") leveltimer.resume();

        for (var i = 0; i < 6; i++) {
            if (neuronTimer[i] != null && neuronTimer[i].getStatus() == "paused") {
                neuronTimer[i].resume();
            }
        }

        function animate() {
            if (!resizeProc) {
                gameController();
                animateMenu();
                animateBullets();
                animateNeurons();
                detectHits();
                animateExplosions();
                animateScores();
            }
            animationLoop = requestAnimationFrame(animate);
            renderer.render(stage);
        }

        cancelAnimationFrame(animationLoop);
        animate();
    }

    //---------------------------------------------GAME CONTROLLER -------------------------------------------------------------
    var level = 0,
        kills = 0,
        firedShots = 0,
        missedNeurons = 0,
        smallNeurons,
        leveltimer = new Timer(),
        challenge = false,
        results = [],
        last_4 = [],
        actualpoints,
        missedNeuronsMax = 4;

    if (lightversion)
        missedNeuronsMax = 2;


    function gameController() {


        function pauseGame() {
            ititimer.pause();

            for (var i = 0; i < 4; i++) {
                if ((practiceround2 && i < 2) || (practiceround && i >= 2)) {
                    drawCannon(i, false);
                    drawCannonText(i);
                }
            }

            stage.interactive = true;
            var id = window.setTimeout(function () {
            }, 0);
            while (id--) {
                window.clearTimeout(id);
            }

            for (var i = 0; i < 6; i++) {
                if (neuronTimer[i] != null) {
                    neuronTimer[i].pause();
                }
            }
            for (var i = 0; i < 4; i++) {
                if (bulletTimer[i]) {
                    bulletTimer[i] = null;
                    bullets[i].visible = false;
                }
            }

        }

        function continueGame() {
            for (var i = 0; i < 4; i++) {
                if ((practiceround2 && i < 2) || (practiceround && i >= 2) || (!practiceround && !practiceround2)) {
                    drawCannon(i, true);
                    drawCannonText(i);
                    activateCannons(i);
                }
            }
            for (var i = 0; i < 6; i++) {
                if (neuronTimer[i] && neuronTimer[i].getStatus() == 'paused') {
                    neuronTimer[i].resume();
                }
            }
            stage.interactive = false;
            ititimer.resume();
            makemenu();
        }

        //only horizontal cannons used
        if (horizontalCounter > 7) {
            horizontalCounter = 0;
            pauseGame();
            drawMessage(actual_JSON.horizontal, actual_JSON.horizontal1, actual_JSON.horizontal2, true);

            document.onkeydown = stage.mousedown = stage.touchstart = function () {
                document.onkeydown = stage.mousedown = stage.touchstart = function () {
                };
                removeMessage();

                continueGame();
            }

        }

        //practiceround 1 -> practiceround 2
        if (kills > 4 && practiceround) {
            practiceround = false;
            practiceround2 = true;

            ititimer.reset();
            pauseGame();

            firedShots = 0;
            kills = 0;
            missedNeurons = 0;

            for (var i = 0; i < 4; i++) {
                cannonsDeactivated[i].visible = true;
            }

            screenUtils.updateProgress(prog, overallscore, practiceround, actual_JSON, practiceround2);
            drawMessage("", actual_JSON.practice2,"", true);
            drawMessage(actual_JSON.practice2_1, actual_JSON.practice2_2, actual_JSON.practice2_3, true);

            document.onkeydown = stage.mousedown = stage.touchstart = function () {
                document.onkeydown = stage.mousedown = stage.touchstart = function () {
                };

                removeMessage();
                resume();
                startStimulus(getStimulus());
            }
        }
        //practiceround 2 -> real game
        if (kills > 4 && practiceround2) {
            practiceround2 = false;

            ititimer.reset();
            pauseGame();

            firedShots = 0;
            kills = 0;
            missedNeurons = 0;
            overallscore = 0;
            prog += 1 / 7;
            iti = 4600;
            level = 1;

            if (lightversion) {
                prog = .15;
                iti = 3600;
                level = 2;
            }

            screenUtils.updateProgress(prog, overallscore, practiceround, actual_JSON, practiceround2);
            drawMessage(actual_JSON.welldone, actual_JSON.welldone1, actual_JSON.welldone2, true);

            document.onkeydown = stage.mousedown = stage.touchstart = function () {
                document.onkeydown = stage.mousedown = stage.touchstart = function () {
                };

                removeMessage();
                continueGame();
                startStimulus(getStimulus());
                leveltimer.start();
            }
        }
        //decrease size
        if (!smallNeurons) {
            plsize = fieldWidth / (app.helpers.isMobile() ? 14 : 16);
            ploffset = (app.helpers.isMobile() ? -fieldWidth / 256 : 0);
        } else {
            plsize = fieldWidth / (app.helpers.isMobile() ? 17 : 21);
            ploffset = (app.helpers.isMobile() ? fieldWidth / 400 : fieldWidth / 128);
        }
        if (overallscore >= 2000 && !practiceround && !practiceround2) {
            smallNeurons = true;
        }

        var challengePoints = 4000,
            finallevel = 3;

        if (lightversion && !practiceround && !practiceround2) {
            challengePoints = 2000;
            finallevel = 2;
        }

        //activate challenge mode
        actualpoints = overallscore;
        if (results.length > 1) {
            actualpoints = overallscore - results[results.length - 1][7];
        }
        if (actualpoints >= challengePoints && !challenge) {
            prog += 1 / 7;
            if (lightversion) prog = .5;

            screenUtils.updateProgress(prog, overallscore, practiceround, actual_JSON, practiceround2);
            logstats();
            challenge = true;
            last_4 = [];
        }
        // level up
        if (challenge && missedNeurons > missedNeuronsMax) {
            prog += 1 / 7;
            if (lightversion) prog = 1;

            screenUtils.updateProgress(prog, overallscore, practiceround, actual_JSON, practiceround2);
            logstats();
            iti = 4600;
            if (lightversion)
                iti = 3600;
            ititimer.pause();
            level++;
            challenge = false;
            setTimeout(function () {
                drawMessage("", "LEVEL " + level, "", false);
                setTimeout(function () {
                    removeMessage();
                    startStimulus(getStimulus());
                    ititimer.reset();
                }, 2000);
            }, 4000);
        }
        //exit  game
        if (level > finallevel) {
            document.onkeydown = function () {
            }
            cancelAnimationFrame(animationLoop);
            stage.destroy(true);
            $('canvas').hide();
            $('.medal-score-cont span').html(overallscore);
            common.saveGameData(results, 2, overallscore);
        }
        if (ititimer.getDuration() > iti) {
            startStimulus(getStimulus());
            ititimer.reset();
        }
    }

    results[0] = ["Level", "time in level", "iti", "points", "shots", "hits", "misses", "score", "Browser", "OS", "Screen", "Timestamp"];

    function logstats() {
        var lvlstring = level.toString();
        if (challenge) {
            lvlstring += "C";
        }

        results.push([lvlstring, leveltimer.getDuration(), iti, actualpoints, firedShots, kills, missedNeurons, overallscore, app.helpers.getBrowserName(), navigator.platform, screen.width + 'x' + screen.height, app.helpers.getTimestamp() ]);
        firedShots = 0;
        kills = 0;
        missedNeurons = 0;
        leveltimer.reset();
    }

    function checkIti() {
        if (challenge) {
            if (last_4.length > 3) {
                let trues = 0;
                for (var i = 0; i < 4; i++) {
                    if (last_4[i]) {
                        trues++;
                    }
                }
                if (trues == 0 || trues == 1 || trues == 2) {
                    iti = iti + 100;
                }
                if (trues == 4) {
                    iti = Math.max(iti - 100, 500);
                }
            }
        }
    }

    function getStimulus() {
        if (level < 2) {
            return 1;
        } else if (level == 2) {
            return Math.floor((Math.random() * 3) + 2);
        } else if (level == 3) {
            return Math.floor((Math.random() * 3) + 5);
        }
    }

    //----------------------------------------PAUSE--------------------------------------------------------------------------------------
    function pause() {
        if (ititimer && ititimer.getStatus() == "started") ititimer.pause();
        if (leveltimer && leveltimer.getStatus() == "started") leveltimer.pause();

        for (var i = 0; i < 6; i++) {
            if (neuronTimer[i] != null) {
                neuronTimer[i].pause();
            }
        }
        for (var i = 0; i < 4; i++) {
            if (bulletTimer[i]) {
                bulletTimer[i] = null;
            }
        }
        $('html,body').css('cursor', 'default');
        try {
            document.onkeydown = bglayer.mousedown = bglayer.touchstart = function () {
            }
        } catch (e) {
        }
        if (homeswitch) {
            homemenu();
        } else {
            pausemenu();
        }
    }

    function resume() {
        if (instructionPhase) {
            prepareToStart();
        } else {
            resumeGame();
        }
    }

    //---------------------------------------------COLLISION DETECTION------------------------------------------------
    function detectHits() {
        for (var i = 0; i < 4; i++) {
            if (bulletTimer[i]) {
                if ((bulletTimer[i].getDuration() < shotDurations[i])) {
                    for (var j = 0; j < 6; j++) {
                        if (neuronTimer[j]) {
                            if (!currentHits[j]) {
                                if (isIntersecting(bullets[i], neurons[j])) {
                                    makeExplosion(i, j);
                                }
                            }
                        }
                    }
                } else if (bulletTimer[i].getDuration() >= shotDurations[i]) {
                    bulletTimer[i] = null;
                    bullets[i].visible = false;
                    drawScore(i, "-50", bullets[i].x, bullets[i].y);
                    if (!practiceround && !practiceround2) {
                        overallscore = Math.max(0, overallscore - 50);
                        screenUtils.updateProgress(prog, overallscore, practiceround, actual_JSON, practiceround2);
                    }
                }
            }
        }
    }

    //-------------------------EXPLOSION---------------------------------------
    var explosions = [],
    explosionPhases = [],
    explosionTimeouts = [],
    explosionTypes = [],
    scoreTexts = [],
    scoreTimers = [];

    function makeExplosion(i, j) {
        explosionTypes[j] = neurons[j].texture.baseTexture.imageUrl.replace(path, "").replace(".png", "");
        currentHits[j] = true;
        neurons[j].setTexture(PIXI.Texture.fromImage(path + explImgs[explosionTypes[j]] + 'ex1.png'));
        neuronTimer[j] = null;
        bullets[i].visible = false;
        bulletTimer[i] = null;
        explosions[j] = new PIXI.Graphics();
        explosions[j].lineStyle(fieldWidth / 100, explCols[explosionTypes[j]], 1);
        explosions[j].alpha = 0.1;
        explosions[j].moveTo(neurons[j].x + plsize / 2, neurons[j].y + plsize / 2);
        explosions[j].lineTo(-10, Math.random() * fieldHeight * 0.6);
        explosions[j].moveTo(neurons[j].x + plsize / 2, neurons[j].y + plsize / 2);
        explosions[j].lineTo(window.innerWidth + 10, Math.random() * fieldHeight * 0.6);
        explosions[j].moveTo(neurons[j].x + plsize / 2, neurons[j].y + plsize / 2);
        explosions[j].lineTo(xo + Math.random() * fieldWidth, window.innerHeight + 10);
        bglayer.addChild(explosions[j]);
        explosionPhases[j] = 1;
        explosionTimeouts[j] = setTimeout(function () {
            if (neurons[j]) {
                neurons[j].setTexture(PIXI.Texture.fromImage(path + explImgs[explosionTypes[j]] + 'ex2.png'));
            }
            explosionTimeouts[j] = setTimeout(function () {
                if (!pauseswitch && level < 4) {
                    drawScore(i, "+" + getPoints(i, j), neurons[j].x + neurons[j].width / 2.3, neurons[j].y - neurons[j].height / 4);
                    screenUtils.updateProgress(prog, overallscore + getPoints(i, j), practiceround, actual_JSON, practiceround2);
                }
                if (neurons[j]) {
                    neurons[j].setTexture(PIXI.Texture.fromImage(path + explImgs[explosionTypes[j]] + 'ex1.png'));
                }
                explosionPhases[j] = 2;
                explosionTimeouts[j] = setTimeout(function () {
                    if (!pauseswitch) {
                        overallscore += getPoints(i, j);
                        kills++;
                        if (challenge) {
                            if (last_4.length > 3) {
                                last_4.shift();
                            }
                            last_4.push(true);
                            checkIti();
                        }
                    }
                    clearTimeout(explosionTimeouts[j]);
                    neurons[j].visible = false;
                    bglayer.removeChild(explosions[j]);
                    lane_busy[j] = false;
                    explosionPhases[j] = 0;
                    explosions[j].destroy(true);
                    explosionTimeouts[j] = null;
                }, 400);
            }, 150);
        }, 150);
    }

    function animateExplosions() {
        for (var i = 0; i < 6; i++) {
            if (explosions[i]) {
                if (explosionPhases[i] == 1) {
                    blacklayer.alpha = Math.max(0.2, blacklayer.alpha - 0.1);
                    explosions[i].alpha = Math.min(0.9, explosions[i].alpha + 0.05);
                } else if (explosionPhases[i] == 2) {
                    neurons[i].alpha -= 0.03;
                    explosions[i].alpha = Math.max(0.0, explosions[i].alpha - 0.08);
                    if (explosionPhases.indexOf(1) == -1) {
                        blacklayer.alpha = Math.min(0.8, blacklayer.alpha + 0.1);
                    }
                }
            }
        }
    }

    function isIntersecting(r1, r2) {
        return !((r2.x + r2.width / 2) > (r1.x + r1.width) ||
        ((r2.x + r2.width / 2) + (r2.width / 1.5)) < r1.x ||
        r2.y + r2.height / 3.3 > (r1.y + r1.height) ||
        (r2.y + r2.height / 1.13) < r1.y);
    }

    function getPoints(b, n) {
        var a = 100;
        if (((b == 2) && (n == 5)) || ((b == 3) && (n == 0))) {
            a = 600;
        }
        if (((b == 2) && (n == 4)) || ((b == 3) && (n == 1))) {
            a = 500;
        }
        if (((b == 2) && (n == 3)) || ((b == 3) && (n == 2))) {
            a = 400;
        }
        if (((b == 2) && (n == 2)) || ((b == 3) && (n == 3))) {
            a = 300;
        }
        if (((b == 2) && (n == 1)) || ((b == 3) && (n == 4))) {
            a = 200;
        }
        return a;
    }

    //----------------------------------------------ANIMATE SCORES------------------------------------------------------
    function drawScore(i, val, x, y) {
        if (!pauseswitch) {
            stage.removeChild(scoreTexts[i]);
            scoreTexts[i] = new PIXI.Text(val, {
                fontFamily: 'Titillium Web',
                fontSize: fieldWidth / (app.helpers.isMobile() ? 20 : 36),
                fill: '#fff',
                align: 'center'
            });
            scoreTexts[i].anchor.x = 0.5;
            scoreTexts[i].x = x;
            scoreTexts[i].y = y;
            stage.addChild(scoreTexts[i]);
            scoreTimers[i] = new Timer();
        }
    }

    function animateScores() {
        for (var i = 0; i < 4; i++) {
            if (scoreTimers[i]) {
                scoreTexts[i].y -= fieldHeight / 700;
                scoreTexts[i].alpha -= 0.035;
                if (scoreTimers[i].getDuration() > 1200) {
                    scoreTimers[i] = null;
                    stage.removeChild(scoreTexts[i]);
                    scoreTexts[i].destroy(true);
                }
            }
        }
    }

    //---------------------------------------------START NEURON--------------------------------------------------------
    var lane_busy = [],
        times = [],
        neurons = [],
        neuronTimer = [],
        currentHits = [],
        swingDir = [], extra = [],
        xchange = [];

    function startStimulus(TType) {
        var all_busy = true;
        for (var i = 0; i < 6; i++) {
            if (!lane_busy[i]) {
                all_busy = false;
            }
        }
        if (!pauseswitch && !all_busy) {
            var Xpos = Math.floor(Math.random() * (5 - 0 + 1)) + 0;
            while (lane_busy[Xpos]) {
                var Xpos = Math.floor(Math.random() * (5 - 0 + 1)) + 0;
            }
            times[Xpos] = neuron_duration;
            if (TType == 3) {
                times[Xpos] = neuron_duration / 1.5;
            }
            if (TType == 4) {
                times[Xpos] = neuron_duration / 2;
            }
            xchange[Xpos] = 0;
            swingDir[Xpos] = 1;
            var randomposition = Math.round(Math.random() * (2));
            if ((randomposition == 1 && Xpos != 0) || Xpos == 5) {
                swingDir[Xpos] = -1;
            }
            lane_busy[Xpos] = true;
            currentHits[Xpos] = false;
            if (stage.children.indexOf(neurons[Xpos]) > -1) {
                neurons[Xpos].setTexture(PIXI.Texture.fromImage(path + TType + '.png'));
                neurons[Xpos].visible = true;
            } else {
                neurons[Xpos] = new PIXI.Sprite.fromImage(path + TType + ".png");
                stage.addChild(neurons[Xpos]);
            }
            neurons[Xpos].alpha = 1;
            neurons[Xpos].height = Math.floor(neurons[Xpos].width = plsize);
            neurons[Xpos].x = Math.floor(xo + fieldWidth / 6.4 + Xpos * fieldWidth / 8 + ploffset);
            neurons[Xpos].y = Math.floor(fieldHeight * 0.037 + ploffset / 10);
            neuronTimer[Xpos] = new Timer();
            neuronTimer[Xpos].start();
        }
    }

    //------------------------------------ANIMATE NEURONS ------------------------------------------------
    function animateNeurons() {
        for (var i = 0; i < 6; i++) {
            if (neurons[i] && neurons[i].visible) {
                neurons[i].height = Math.floor(neurons[i].width = plsize);
                extra[i] = 1;
                if (neurons[i].texture.baseTexture.imageUrl.replace(path, "").replace(".png", "") == 7) {
                    extra[i] = 1.1;
                }


                neurons[i].x = Math.floor(xo + fieldWidth / 6.4 + i * fieldWidth / 8 + xchange[i] + ploffset * extra[i]);
                if (neuronTimer[i] != null && neuronTimer[i].getDuration() >= 1000 && lane_busy[i]) {
                    neurons[i].visible = true;
                    neurons[i].y = Math.floor(fieldHeight * 0.037 + (neuronTimer[i].getDuration() - 1000) / times[i] * fieldHeight + ploffset / 10);
                    if ((neurons[i].texture.baseTexture.imageUrl.replace(path, "").replace(".png", "") == 6) && neuronTimer[i].getDuration() >= 2500) {
                        neurons[i].y = Math.floor(fieldHeight * 0.037 + (neuronTimer[i].getDuration() - 1000) / (times[i] / 2) * fieldHeight - (fieldHeight / 3.4) + ploffset / 10);
                    }
                    if (neurons[i].texture.baseTexture.imageUrl.replace(path, "").replace(".png", "") == 7) {
                        xchange[i] = ((Math.sin(((neuronTimer[i].getDuration() - 1000) / times[i] - 0.58) * 13.5)) + 1) / 2;
                        xchange[i] *= (fieldWidth / 8) * swingDir[i];
                    }
                    if (((neuronTimer[i].getDuration() - 1000) > times[i]) && !currentHits[i]) {
                        neuronTimer[i] = null;
                        neurons[i].visible = false;
                        lane_busy[i] = false;
                        if (!practiceround && !practiceround2) {
                            missedNeurons++;
                        }
                        if (challenge) {
                            if (last_4.length > 3) {
                                last_4.shift();
                            }
                            last_4.push(false);
                            checkIti();
                        }
                    }
                }
            }
        }
    }

    //-----------------------------------------SHOOT------------------------------------------------------------------
    var shotTimeouts = [], shotDur = [];

    function shoot(i) {
        if (!practiceround2) {
            (i < 2) ? horizontalCounter++ : horizontalCounter = 0;
        }
        if (!practiceround && !practiceround2) {
            firedShots++;
        }
        shotDur[i] = shotDurations[i];
        if (!challenge) {
            shotDur[i] = 1500;
        } else {
            shotDur[i] = 1000;
        }
        if ((practiceround2 && i < 2) || (practiceround && i >= 2) || (!practiceround && !practiceround2)) {
            drawCannon(i, false);
            drawCannonText(i);
        }
        bullets[i].visible = true;
        bulletTimer[i] = new Timer();
        bulletTimer[i].start();
        bullets[i].width = (fieldWidth * 6 / 8) * .03;
        bullets[i].height = (fieldWidth * 6 / 8) * .03;
        shotTimeouts[i] = setTimeout(function () {
            if (!pauseswitch) {
                if ((practiceround2 && i < 2) || (practiceround && i >= 2) || (!practiceround && !practiceround2)) {
                    drawCannon(i, true);
                    drawCannonText(i);
                    activateCannons(i);
                }
                shotTimeouts[i] = null;

            }
        }, shotDur[i]);
    }

    //----------------------------------ANIMATE BULLETS-----------------------------------------------------------------
    function animateBullets() {
        for (var i = 0; i < 4; i++) {
            if (bulletTimer[i] != null) {
                bullets[i].visible = true;
                switch (i) {
                    case 0:
                        bullets[i].x = Math.floor(xo + fieldWidth / 11 + ((fieldWidth / 2.7) * bulletTimer[i].getDuration() / 500));
                        bullets[i].y = Math.floor(fieldHeight * 0.746);
                        break;
                    case 1:
                        bullets[i].x = Math.floor(xo + fieldWidth * 10 / 11 - ((fieldWidth / 2.7) * bulletTimer[i].getDuration() / 500));
                        bullets[i].y = Math.floor(fieldHeight * 0.746);
                        break;
                    case 2:
                        bullets[i].x = Math.floor(xo + fieldWidth * 0.08 + ((fieldWidth / 1.3) * bulletTimer[i].getDuration() / 1000));
                        bullets[i].y = Math.floor(fieldHeight * 0.866 - ((fieldHeight / 1.36) * bulletTimer[i].getDuration() / 1000));
                        break;
                    case 3:
                        bullets[i].x = Math.floor(xo + fieldWidth * 0.92 - ((fieldWidth / 1.3) * bulletTimer[i].getDuration() / 1000));
                        bullets[i].y = Math.floor(fieldHeight * 0.866 - ((fieldHeight / 1.36) * bulletTimer[i].getDuration() / 1000));
                        break;
                }
            }
        }
    }

    //-----------------------------------------ACTIVATE CANNONS -----------------------------------------------------------------
    function activateCannons(i) {
        cannons[i].interactive = true;
        cannons[i].mousedown = cannons[i].touchstart = function () {
            if (cannonsDeactivated[i].visible == false) {
                shoot(i);
            }
        }
        document.onkeydown = function (e) {
            for (var j = 0; j < 4; j++) {
                if (String.fromCharCode(e.keyCode) == cannonTexts[j]) {
                    if (cannonsDeactivated[j].visible == false) {
                        shoot(j);
                    }
                }
            }
        }
    }

    // ------------------------------------------ INSTRUCTIONS CONTROLLER -----------------------------------------------------------------------
    function instructions() {
        $('html,body').css('cursor', 'default');
        stage.removeChildren();
        defineField();
        drawBg();
        drawHangar();
        for (var i = 0; i < 4; i++) {
            drawCannon(i, false);
        }
        makemenu();
        if (app.helpers.isMobileOrTablet()) {
            actual_JSON.continuetext = actual_JSON.continuetextTouch;
            mobileInstructions();
        } else {
            desktopInstructions();
        }

        function animate() {
            animateMenu();
            animationLoop = requestAnimationFrame(animate);
            renderer.render(stage);
        }

        cancelAnimationFrame(animationLoop);
        animate();
    }

    //----------------------------------------------- DRAW CANNON -----------------------------------------------------
    function drawCannon(nr, flag) {
        var redraw = false;
        if (stage.children.indexOf(cannons[nr]) > -1) {
            redraw = true;
        }

        switch (nr) {
            case 2:
                if (!redraw) {
                    cannons[nr] = PIXI.Sprite.fromImage(path + 'canup.png');
                    cannonsDeactivated[nr] = PIXI.Sprite.fromImage(path + 'canup' + 'g' + '.png');
                }
                cannonsDeactivated[nr].x = cannons[nr].x = xo - fieldWidth / 50;
                cannonsDeactivated[nr].y = cannons[nr].y = fieldHeight * 0.82;
                cannonsDeactivated[nr].width = cannons[nr].width = fieldWidth / 7
                cannonsDeactivated[nr].height = cannons[nr].height = fieldWidth / 10;
                break;
            case 3:
                if (!redraw) {
                    cannons[nr] = PIXI.Sprite.fromImage(path + 'canup2.png');
                    cannonsDeactivated[nr] = PIXI.Sprite.fromImage(path + 'canup2' + 'g' + '.png');
                }
                cannonsDeactivated[nr].x = cannons[nr].x = xo + fieldWidth - (fieldWidth / 8);
                cannonsDeactivated[nr].y = cannons[nr].y = fieldHeight * 0.82;
                cannonsDeactivated[nr].width = cannons[nr].width = fieldWidth / 7;
                cannonsDeactivated[nr].height = cannons[nr].height = fieldWidth / 10;
                break;
            case 0:
                if (!redraw) {
                    cannons[nr] = PIXI.Sprite.fromImage(path + 'canstraight.png');
                    cannonsDeactivated[nr] = PIXI.Sprite.fromImage(path + 'canstraight' + 'g' + '.png');
                }
                cannonsDeactivated[nr].x = cannons[nr].x = xo - fieldWidth / 50;
                cannonsDeactivated[nr].y = cannons[nr].y = fieldHeight * 0.69;
                cannonsDeactivated[nr].width = cannons[nr].width = fieldWidth / 7;
                cannonsDeactivated[nr].height = cannons[nr].height = fieldWidth / 14;
                break;
            case 1:
                if (!redraw) {
                    cannons[nr] = PIXI.Sprite.fromImage(path + 'canstraight2.png');
                    cannonsDeactivated[nr] = PIXI.Sprite.fromImage(path + 'canstraight2' + 'g' + '.png');
                }
                cannonsDeactivated[nr].x = cannons[nr].x = xo + fieldWidth - (fieldWidth / 8);
                cannonsDeactivated[nr].y = cannons[nr].y = fieldHeight * 0.69;
                cannonsDeactivated[nr].width = cannons[nr].width = fieldWidth / 7;
                cannonsDeactivated[nr].height = cannons[nr].height = fieldWidth / 14;
                break;
        }
        if (!redraw) {
            stage.addChild(cannons[nr]);
            stage.addChild(cannonsDeactivated[nr]);

        }
        if (flag) {
            cannonsDeactivated[nr].visible = false;
        } else {
            cannonsDeactivated[nr].visible = true;

        }

    }

    //----------------------------------------------- DRAW CANNON TEXTS -----------------------------------------------------
    function drawCannonText(nr) {
        var ctextOptions = {
            fontFamily: 'Titillium Web',
            fill: '#111111',
            align: 'center',
            fontSize: fieldWidth / 48,
            dropShadow: false,
            dropShadowColor: '#111111',
            dropShadowDistance: 1,
            dropShadowAngle: 135
        }
        stage.removeChild(ctexts[nr]);
        if (ctexts[nr]) {
            try {
                ctexts[nr].destroy(true);
            } catch(e) {
                console.warn('game 2 | ', e);
            }
        }
        ctexts[nr] = new PIXI.Text(cannonTexts[nr], ctextOptions);
        ctexts[nr].anchor.x = 0.5;
        switch (nr) {
            case 0:
                ctexts[nr].x = xo + fieldWidth * 0.073;
                ctexts[nr].y = fieldHeight * 0.744;
                break;
            case 1:
                ctexts[nr].x = xo + fieldWidth * 0.927;
                ctexts[nr].y = fieldHeight * 0.744;
                break;
            case 2:
                ctexts[nr].x = xo + fieldWidth * 0.07;
                ctexts[nr].y = fieldHeight * 0.873;
                break;
            case 3:
                ctexts[nr].x = xo + fieldWidth * 0.93;
                ctexts[nr].y = fieldHeight * 0.873;
                break;
        }
        stage.addChild(ctexts[nr]);
    }

    function defineField() {
        var a = width / height;
        if (a < 1.777) {
            fieldHeight = width * (9 / 16);
            fieldWidth = width;
            xo = 0;
        } else {
            fieldWidth = height * (16 / 9);
            fieldHeight = height;
            xo = (screenUtils.getWindowWidthOrMin(parentDiv) - fieldWidth) / 2;
        }
    }

    function drawHangar() {
        stage.removeChild(hangar);
        var hangar = PIXI.Sprite.fromImage(path + 'boxes.png');
        hangar.x = xo + (1 / 8) * fieldWidth;
        hangar.y = -fieldWidth * 0.02;
        hangar.width = fieldWidth * 0.748;
        hangar.height = fieldWidth * 0.05;
        stage.addChild(hangar);
    }

    // ------------------------------------------ MOBILE INSTRUCTIONS -----------------------------------------------------------------------
    function mobileInstructions() {
        bglayer.interactive = true;
        drawImageMessage(actual_JSON.j10, actual_JSON.j11, "tabletCol.png");
        bglayer.mousedown = bglayer.touchstart = function () {
            drawImageMessage(actual_JSON.t1, actual_JSON.t10, "tabletGrey.png");
            bglayer.mousedown = bglayer.touchstart = function () {
                drawImageMessage(actual_JSON.t2, actual_JSON.t20, "tabletGrey.png");
                bglayer.mousedown = bglayer.touchstart = function () {
                    drawMessage(actual_JSON.j2, actual_JSON.j20, actual_JSON.j21, true);
                    bglayer.mousedown = bglayer.touchstart = function () {
                        drawMessage(actual_JSON.j3, actual_JSON.j30, actual_JSON.j31, true);
                        bglayer.mousedown = bglayer.touchstart = function () {
                            drawMessage(actual_JSON.j4, actual_JSON.j40, actual_JSON.j41, true);
                            bglayer.mousedown = bglayer.touchstart = function () {
                                startGame();
                            }
                        }
                    }
                }
            }
        }
    }

// ------------------------------------------ DESKTOP INSTRUCTIONS -----------------------------------------------------------------------
    function desktopInstructions() {
        drawMessage(actual_JSON.before, actual_JSON.fingers, actual_JSON.press, true);
        bglayer.interactive = true;
        document.onkeydown = bglayer.mousedown = bglayer.touchstart = function () {
            bglayer.mousedown = bglayer.touchstart = function () {
            }
            drawKeyboardMessage(actual_JSON.i1, actual_JSON.i10, actual_JSON.i11, "kb_1.png");
            document.onkeydown = function (e) {
                if (e.keyCode == 49) {
                    cannonTexts[0] = "1";
                    drawCannonText(0);
                    drawKeyboardMessage(actual_JSON.i2, actual_JSON.i20, "", "kb_1.png");
                    document.onkeydown = function (e) {
                        if (e.keyCode == 49) {
                            drawKeyboardMessage(actual_JSON.i3, actual_JSON.i30, "", "kb_0.png");
                            document.onkeydown = function (e) {
                                if (e.keyCode == 48) {
                                    cannonTexts[1] = "0";
                                    drawCannonText(1);
                                    drawKeyboardMessage("", actual_JSON.i4, "", "kb_0.png");
                                    document.onkeydown = function (e) {
                                        if (e.keyCode == 48) {
                                            drawKeyboardMessage(actual_JSON.i5, actual_JSON.i50, "", "kb_q.png");
                                            document.onkeydown = function (e) {
                                                if (e.keyCode != 48 && e.keyCode != 49) {
                                                    cannonTexts[2] = String.fromCharCode(e.keyCode);
                                                    drawCannonText(2);
                                                    drawKeyboardMessage("", actual_JSON.i6, "", "kb_q.png");
                                                    document.onkeydown = function (e) {
                                                        if (String.fromCharCode(e.keyCode) == cannonTexts[2]) {
                                                            drawKeyboardMessage(actual_JSON.i7, actual_JSON.i70, "", "kb_o.png");
                                                            document.onkeydown = function (e) {
                                                                if (e.keyCode != 48 && e.keyCode != 49 && String.fromCharCode(e.keyCode) != cannonTexts[2]) {
                                                                    cannonTexts[3] = String.fromCharCode(e.keyCode);
                                                                    drawCannonText(3);
                                                                    drawKeyboardMessage("", actual_JSON.i8, "", "kb_o.png");
                                                                    document.onkeydown = function (e) {
                                                                        if (String.fromCharCode(e.keyCode) == cannonTexts[3]) {
                                                                            drawKeyboardMessage(actual_JSON.i9, actual_JSON.i90, actual_JSON.i91, "kb_all.png");
                                                                            document.onkeydown = function (e) {
                                                                                drawMessage(actual_JSON.j1, actual_JSON.j10, actual_JSON.j11, true);
                                                                                document.onkeydown = function (e) {
                                                                                    drawMessage(actual_JSON.j2, actual_JSON.j20, actual_JSON.j21, true);
                                                                                    document.onkeydown = function (e) {
                                                                                        drawMessage(actual_JSON.j3, actual_JSON.j30, actual_JSON.j31, true);
                                                                                        document.onkeydown = function (e) {
                                                                                            drawMessage(actual_JSON.j4, actual_JSON.j40, actual_JSON.j41, true);
                                                                                            document.onkeydown = function (e) {
                                                                                                drawMessage(actual_JSON.practice1_1, actual_JSON.practice1_2, actual_JSON.practice1_3, true);
                                                                                                document.onkeydown = function (e) {
                                                                                                    startGame();
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    function getMsgGridlength() {
        var a = Math.min(Math.min(width - (2 * size), 600), Math.min(height - (6 * size), 600));
        if (app.helpers.isMobile()) {
            a *= 1.5;
            a = Math.min(a, window.innerHeight);
        }
        return a;
    }

//--------------------------------------DRAW IMAGE MESSAGE --------------------------------------------------------------------------
    function drawImageMessage(text, text2, img) {
        removeMessage();
        var gridlength = getMsgGridlength();

        var msgTextOptions = {
            fontFamily: 'Titillium Web',
            fill: '#fff',
            align: 'center',
            fontSize: gridlength / 20,
        }
        msgFrame = new PIXI.Graphics();
        msgFrame.beginFill(0x4fbadf, 1);
        msgFrame.drawRoundedRect(width / 2 - gridlength / 1.5, height / 2 - gridlength * 0.4, gridlength * 1.333, gridlength * 0.75, 6);
        stage.addChild(msgFrame);
        msgText = new PIXI.Text(text, msgTextOptions);
        msgText.anchor.x = 0.5;
        msgText.x = width / 2;
        msgText.y = height / 2 - gridlength / 2.7;
        msgText2 = new PIXI.Text(text2, msgTextOptions);
        msgText2.anchor.x = 0.5;
        msgText2.x = width / 2;
        msgText2.y = height / 2 - gridlength / 3.3;
        stage.addChild(msgText);
        stage.addChild(msgText2);
        keyimg = PIXI.Sprite.fromImage(path + img);
        keyimg.anchor.x = 0.5;
        keyimg.x = width / 2;
        keyimg.y = height / 2 - gridlength / 5;
        keyimg.width = gridlength / 1.66;
        keyimg.height = gridlength / 2.35;
        stage.addChild(keyimg);
        contFrame = new PIXI.Graphics();
        contFrame.beginFill(0x00000, 0.2);
        contFrame.drawRect(width / 2 - gridlength / 1.5, height / 2 + gridlength / 3.7, gridlength * 1.333, gridlength / 12, 6);
        stage.addChild(contFrame);
        var contTextOptions = {
            fontFamily: 'Titillium Web',
            fill: '#fff',
            align: 'center',
            fontSize: gridlength / 24,
        }
        contText = new PIXI.Text(actual_JSON.continuetext, contTextOptions);
        contText.anchor.x = 0.5;
        contText.x = width / 2;
        contText.y = height / 2 + gridlength / 3.5;
        stage.addChild(contText);
    }

    function drawErrorMessage(text, text2, img) {
        removeMessage();
        var gridlength = Math.min(Math.min(width - (2 * size), 600), Math.min(height - (6 * size), 600));
        if (app.helpers.isMobile()) {
          //  gridlength *= 1.3;
            gridlength = Math.min(gridlength, window.innerHeight);
        }
        var msgTextOptions = {
            fontFamily: 'Titillium Web',
            fill: '#fff',
            align: 'center',
            fontSize: gridlength / 20,
        }
        msgFrame = new PIXI.Graphics();
        msgFrame.beginFill(0x4fbadf, 1);
        msgFrame.drawRoundedRect(width / 2 - gridlength / 1.5, height / 2 - gridlength * 0.4, gridlength * 1.333, gridlength * 0.75, 6);
        stage.addChild(msgFrame);
        msgText = new PIXI.Text(text, msgTextOptions);
        msgText.anchor.x = 0.5;
        msgText.x = width / 2;
        msgText.y = height / 2 - gridlength / 2.7;
        msgText2 = new PIXI.Text(text2, msgTextOptions);
        msgText2.anchor.x = 0.5;
        msgText2.x = width / 2;
        msgText2.y = height / 2 - gridlength / 3.3;
        stage.addChild(msgText);
        stage.addChild(msgText2);
        keyimg = PIXI.Sprite.fromImage(path + img);
        keyimg.anchor.x = 0.5;
        keyimg.x = width / 2;
        keyimg.y = height / 2 - gridlength / 5;
        keyimg.width = gridlength / 1.66;
        keyimg.height = gridlength / 2.35;
        stage.addChild(keyimg);

    }


//--------------------------------------DRAW KEYBOARD MESSAGE --------------------------------------------------------------------------
    function drawKeyboardMessage(text, text2, text3, img) {
        removeMessage();
        var gridlength = getMsgGridlength();
        var msgTextOptions = {
            fontFamily: 'Titillium Web',
            fill: '#fff',
            align: 'center',
            fontSize: Math.floor(gridlength / 20),
        }
        msgFrame = new PIXI.Graphics();
        msgFrame.beginFill(0x4fbadf, 1);
        msgFrame.drawRoundedRect(width / 2 - gridlength / 1.5, height / 2 - gridlength * 0.4, gridlength * 1.333, gridlength * 0.75, 6);
        stage.addChild(msgFrame);
        msgText = new PIXI.Text(text, msgTextOptions);
        msgText.anchor.x = 0.5;
        msgText.x = width / 2;
        msgText.y = height / 2 - gridlength / 2.7;
        msgText2 = new PIXI.Text(text2, msgTextOptions);
        msgText2.anchor.x = 0.5;
        msgText2.x = width / 2;
        msgText2.y = height / 2 - gridlength / 3.3;
        msgText3 = new PIXI.Text(text3, msgTextOptions);
        msgText3.anchor.x = 0.5;
        msgText3.x = width / 2
        msgText3.y = height / 2 - gridlength / 4.2;
        stage.addChild(msgText);
        stage.addChild(msgText2);
        stage.addChild(msgText3);
        keyimg = PIXI.Sprite.fromImage(path + img);
        keyimg.anchor.x = 0.5;
        keyimg.x = width / 2;
        keyimg.y = height / 2 - gridlength / 8;
        keyimg.width = gridlength / 0.77;
        keyimg.height = gridlength / 2.15;
        stage.addChild(keyimg);
    }

//--------------------------------------DRAW MESSAGE --------------------------------------------------------------------------
    function drawMessage(text, text2, text3, continueMsg) {
        removeMessage();
        var gridlength = getMsgGridlength();

        var msgTextOptions = {
            fontFamily: 'Titillium Web',
            fill: '#fff',
            align: 'center',
            fontSize: gridlength / 20,
        }
        msgFrame = new PIXI.Graphics();
        msgFrame.beginFill(0x4fbadf, 1);
        msgFrame.drawRoundedRect(width / 2 - gridlength / 1.5, height / 2 - gridlength / 6, gridlength * 1.333, gridlength / 3, 6);
        stage.addChild(msgFrame);
        msgText = new PIXI.Text(text, msgTextOptions);
        msgText.anchor.x = 0.5;
        msgText.x = width / 2;
        msgText.y = height / 2 - gridlength / 7;
        msgText2 = new PIXI.Text(text2, msgTextOptions);
        msgText2.anchor.x = 0.5;
        msgText2.x = width / 2;
        msgText2.y = height / 2 - gridlength / 14;
        msgText3 = new PIXI.Text(text3, msgTextOptions);
        msgText3.anchor.x = 0.5;
        msgText3.x = width / 2;
        msgText3.y = height / 2;
        stage.addChild(msgText);
        stage.addChild(msgText2);
        stage.addChild(msgText3);
        if (!continueMsg) {
            msgFrame.height = gridlength / 4;
            msgFrame.y = height / 10.4;
        } else {
            contFrame = new PIXI.Graphics();
            contFrame.beginFill(0x00000, 0.2);
            contFrame.drawRect(width / 2 - gridlength / 1.5, height / 2 + gridlength / 11, gridlength * 1.333, gridlength / 12, 6);
            stage.addChild(contFrame);
            var contTextOptions = {
                fontFamily: 'Titillium Web',
                fill: '#fff',
                align: 'center',
                fontSize: gridlength / 24,
            }
            contText = new PIXI.Text(actual_JSON.continuetext, contTextOptions);
            contText.anchor.x = 0.5;
            contText.x = width / 2;
            contText.y = height / 2 + gridlength / 10;
            stage.addChild(contText);
        }
    }

//----------------------- REMOVE MESSAGE ----------------------------------------------------------------------//
    function removeMessage() {
        try {
            stage.removeChild(msgFrame);
        } catch (e) {
        }
        try {
            stage.removeChild(msgText);
        } catch (e) {
        }
        try {
            stage.removeChild(msgText2);
        } catch (e) {
        }
        try {
            stage.removeChild(keyimg);
        } catch (e) {
        }
        try {
            stage.removeChild(contFrame);
        } catch (e) {
        }
        try {
            stage.removeChild(contText);
        } catch (e) {
        }
        try {
            stage.removeChild(msgText3);
        } catch (e) {
        }
    }

//----------------------- PAUSE MENU ----------------------------------------------------------------------//
    var homebuttontimer, pausebuttontimer, homebuttoncount, pausebuttoncount, ptext, btext, pauseswitch, homeswitch;

    function home() {
        homeswitch = true;
        pause();
    }

    function homemenu() {
        var gridlength = Math.min(Math.min(width - (2 * size), 800), Math.min(height - (6 * size), 800));
        if (app.helpers.isMobile()) {
            gridlength *= 2;
        }
        cancelAnimationFrame(animationLoop);
        stage.removeChildren();
        bglayer = new PIXI.Graphics();
        bglayer.beginFill(0xD3D3D3, 1);
        bglayer.drawRect(0, 0, width, height);
        stage.addChild(bglayer);
        var largeTextOptions = {
            fontFamily: 'Titillium Web',
            fill: '#222',
            align: 'center',
            fontSize: gridlength / 20
        }
        var textOptions = {
            fontFamily: 'Titillium Web',
            fill: '#222',
            align: 'center',
            fontSize: gridlength / 30
        }
        var smallTextOptions = {
            fontFamily: 'Titillium Web',
            fill: '#fff',
            align: 'center',
            fontSize: gridlength / 25
        }
        var text2 = new PIXI.Text(actual_JSON.sure.toUpperCase(), largeTextOptions);
        text2.anchor.x = 0.5;
        text2.x = width / 2;
        text2.y = screenUtils.getWindowHeightOrMin(parentDiv) * (app.helpers.isMobile() ? 0.25 : 0.35);
        stage.addChild(text2);
        var text3 = new PIXI.Text(actual_JSON.sure2, textOptions);
        text3.anchor.x = 0.5;
        text3.x = width / 2;
        text3.y = screenUtils.getWindowHeightOrMin(parentDiv) * (app.helpers.isMobile() ? 0.36 : 0.41);
        stage.addChild(text3);
        var startButtonShape = new PIXI.Graphics();
        startButtonShape.beginFill(0xE9B600); // Yellow
        startButtonShape.drawPolygon(app.helpers.isMobile() ? drawArrowMobile(width / 2, height * 0.505) : drawArrow(width / 2 + (size * 3.6), height * 0.48));

        startButtonShape.interactive = true;
        var buttonTimer = false; // used for button grow animation (instead of css)
		startButtonShape.mouseover = function (mouseData) {
            buttonTimer = true;
            $('html,body').css('cursor', 'pointer');
        }
        startButtonShape.mouseout = function (mouseData) {
            buttonTimer = false;
            $('html,body').css('cursor', 'default');
        }
        stage.addChild(startButtonShape);
        var quitButtonShape = new PIXI.Graphics();
        quitButtonShape.beginFill(0xA9A9A9); // GREY
        quitButtonShape.drawPolygon(app.helpers.isMobile() ? drawArrowMobile(width / 2, height * 0.675) : drawArrow(width / 2 - (size * 3.6), height * 0.48));
        quitButtonShape.interactive = true;
        var buttonTimer2 = false;
        quitButtonShape.mouseover = function (mouseData) {
            buttonTimer2 = true;
            $('html,body').css('cursor', 'pointer');
        }
        quitButtonShape.mouseout = function (mouseData) {
            buttonTimer2 = false;
            $('html,body').css('cursor', 'default');
        }
        stage.addChild(quitButtonShape);
        var text6 = new PIXI.Text(actual_JSON.resume, smallTextOptions);
        text6.anchor.x = 0.5;
        text6.x = width / 2 + (app.helpers.isMobile() ? 0 : size * 3.6);
        text6.y = height * (app.helpers.isMobile() ? 0.5 : 0.48) + gridlength * 0.025;
        stage.addChild(text6);
        var text7 = new PIXI.Text(actual_JSON.quit, smallTextOptions);
        text7.anchor.x = 0.5;
        text7.x = width / 2 - (app.helpers.isMobile() ? 0 : size * 3.6);
        text7.y = height * (app.helpers.isMobile() ? 0.673 : 0.48) + gridlength * 0.025;
        stage.addChild(text7);
        var count = 0;
        var count2 = 0;
        startButtonShape.mousedown = startButtonShape.touchstart = function () {
            cancelAnimationFrame(animationLoop);
            pauseswitch = false;
            homeswitch = false;
            homebuttontimer = false;
            pausebuttontimer = false;
            onResize();
        }
        quitButtonShape.mousedown = quitButtonShape.touchstart = () => app.events.fire.navigate('game-types.html');

        function animate() {
            if (buttonTimer) {
                count = Math.min(1.12, count + 0.013);
            } else {
                count = Math.max(1, count - 0.013);
            }
            if (buttonTimer2) {
                count2 = Math.min(1.12, count2 + 0.013);
            } else {
                count2 = Math.max(1, count2 - 0.013);
            }
            if (stage.children.indexOf(quitButtonShape) > -1) {
                quitButtonShape.setTransform(0, 0, count2, 1);
                quitButtonShape.x = (1- count2) * screenUtils.getWindowWidthOrMin() / 2.5;
                startButtonShape.setTransform(0, 0, count, 1);
                startButtonShape.x = (1 - count) * screenUtils.getWindowWidthOrMin() / 1.8;
                animationLoop = requestAnimationFrame(animate);
                renderer.render(stage);
            }
        }

        cancelAnimationFrame(animationLoop);
        animate();
    }

    function pausemenu() {
        var gridlength = Math.min(Math.min(width - (2 * size), 800), Math.min(height - (6 * size), 800));
        if (app.helpers.isMobile()) {
            gridlength *= 2;
        }
        cancelAnimationFrame(animationLoop);
        stage.removeChildren();
        bglayer = new PIXI.Graphics();
        bglayer.beginFill(0xD3D3D3, 1);
        bglayer.drawRect(-2, -2, width+2, height+2);
        stage.addChild(bglayer);
        var largeTextOptions = {
            fontFamily: 'Titillium Web',
            fill: '#222',
            align: 'center',
            fontSize: gridlength / 20
        }
        var smallTextOptions = {
            fontFamily: 'Titillium Web',
            fill: '#fff',
            align: 'center',
            fontSize: gridlength / 25,
        }
        var text2 = new PIXI.Text(actual_JSON.pause.toUpperCase() + "...", largeTextOptions);
        text2.anchor.x = 0.5;
        text2.x = width / 2;
        text2.y = screenUtils.getWindowHeightOrMin(parentDiv) * (app.helpers.isMobile() ? 0.38 : 0.4);
        stage.addChild(text2);
        var startButtonShape = new PIXI.Graphics();
        startButtonShape.beginFill(0xE9B600); // Yellow
        startButtonShape.drawPolygon(app.helpers.isMobile() ? drawArrowMobile(width / 2, height * 0.485) : drawArrow(width / 2, height * 0.48));
        startButtonShape.interactive = true;
        var buttonTimer = false;
        startButtonShape.mouseover = function (mouseData) {
            buttonTimer = true;
            $('html,body').css('cursor', 'pointer');
        }
        startButtonShape.mouseout = function (mouseData) {
            buttonTimer = false;
            $('html,body').css('cursor', 'default');
        }
        var text6 = new PIXI.Text(actual_JSON.resume, smallTextOptions);
        text6.anchor.x = 0.5;
        text6.x = width / 2;
        text6.y = height * 0.48 + gridlength * 0.025;
        stage.addChild(startButtonShape);
        stage.addChild(text6);
        var count = 0;
        startButtonShape.mousedown = startButtonShape.touchstart = function () {
            cancelAnimationFrame(animationLoop);
            pauseswitch = false;
            homebuttontimer = false;
            pausebuttontimer = false;
            onResize();
        }

        function animate() {
            if (buttonTimer) {
                count = Math.min(1.12, count + 0.013);
            } else {
                count = Math.max(1, count - 0.013);
            }
            if (stage.children.indexOf(startButtonShape) > -1) {
                startButtonShape.setTransform(0, 0, count, 1);
                startButtonShape.x = (1 - count) * screenUtils.getWindowWidthOrMin() / 2;
                animationLoop = requestAnimationFrame(animate);
                renderer.render(stage);
            }
        }

        cancelAnimationFrame(animationLoop);
        animate();
    }

    function makemenu() {
        var size3 = (app.helpers.isMobile()) ? size * 2 : size;
        homebutton = new PIXI.Graphics();
        homebutton.beginFill(0xe5e3db, 1);
        homebutton.drawRect(-2 * size3, height * 0.5 - size3 / 2, size3 * 3, size3);
        homebutton.mouseover = function (mouseData) {
            homebuttontimer = true;
            $('html,body').css('cursor', 'pointer');
        }
        homebutton.mouseout = function (mouseData) {
            homebuttontimer = false;
            $('html,body').css('cursor', 'default');
        }
        homebuttoncount = 0;
        homebutton.interactive = true;
        stage.addChild(homebutton);
        var homeicon = PIXI.Sprite.fromImage(path + 'x.png');
        homeicon.width = size3;
        homeicon.height = size3;
        homeicon.x = 0;
        homeicon.y = height / 2 - size3 / 2;
        stage.addChild(homeicon);
        pausebutton = new PIXI.Graphics();
        pausebutton.beginFill(0xe5e3db, 1);
        pausebutton.drawRect(width - size3, height * 0.5 - size3 / 2, size3 * 3, size3);
        pausebutton.mouseover = function (mouseData) {
            pausebuttontimer = true;
            $('html,body').css('cursor', 'pointer');
        }
        pausebutton.mouseout = function (mouseData) {
            pausebuttontimer = false;
            $('html,body').css('cursor', 'default');
        }
        pausebuttoncount = 0;
        pausebutton.interactive = true;
        stage.addChild(pausebutton);
        var pauseicon = PIXI.Sprite.fromImage(path + 'ii.png');
        pauseicon.width = size3;
        pauseicon.height = size3;
        pauseicon.x = width - size3;
        pauseicon.y = height / 2 - size3 / 2;
        stage.addChild(pauseicon);
        var buttontext = {
            fontFamily: 'Titillium Web',
            fill: '#111',
            align: 'center',
            fontSize: size3 / 2.5,
            fontWeight: 600,
            dropShadow: true,
            dropShadowColor: '#111',
            dropShadowDistance: 0,
            dropShadowAngle: 135
        };
        ptext = new PIXI.Text(actual_JSON.pause, buttontext);
        ptext.anchor.x = 0.5;
        ptext.x = Math.floor(width - 1.66 * size3);
        ptext.y = Math.floor(height * 0.489);
        btext = new PIXI.Text(actual_JSON.home, buttontext);
        btext.anchor.x = 0.5;
        btext.x = Math.floor(1.66 * size3);
        btext.y = Math.floor(height * 0.489);
        homebutton.mousedown = homebutton.touchstart = function () {
            pauseswitch = true;
            homeswitch = true;
            pause();
        };
        pausebutton.mousedown = pausebutton.touchstart = function () {
            pauseswitch = true;
            pause();
        }
    }

    function animateMenu() {
        stage.removeChild(ptext);
        stage.removeChild(btext);
        if (pausebuttontimer) {
            pausebuttoncount = Math.min(1, pausebuttoncount + 0.2);
            stage.removeChild(ptext);
            stage.addChild(ptext);
        } else {
            pausebuttoncount = Math.max(0, pausebuttoncount - 0.2);
        }
        if (homebuttontimer) {
            homebuttoncount = Math.min(1, homebuttoncount + 0.2);
            stage.removeChild(btext);
            stage.addChild(btext);
        } else {
            homebuttoncount = Math.max(0, homebuttoncount - 0.2);
        }
        if(homebutton && homebutton.x) {
            homebutton.x = Math.floor((1.5 * size * homebuttoncount));
        }
        pausebutton.x = Math.floor(-(1.5 * size * pausebuttoncount));
    }

//-----------------------------------PREPARE TO START-----------------------------------------------
    function prepareToStart() {
        var gridlength = Math.min(Math.min(width - (2 * size), 600), Math.min(height - (6 * size), 600));
        var largeTextOptions = {
            fontFamily: 'Titillium Web',
            fill: '#fff',
            align: 'center',
            fontSize: Math.floor(gridlength / (app.helpers.isMobile() ? 9 : 20))
        }
        var smallTextOptions = {
            fontFamily: 'Titillium Web',
            fill: '#fff',
            align: 'center',
            fontSize: Math.floor(gridlength / ((app.helpers.isMobile() || resolution === "mediump")  ? 12 : 30)),
            lineHeight: Math.floor(gridlength / ((app.helpers.isMobile() || resolution === "mediump")  ? 8 : 16))
        }
        var fatTextOptions = {
            fontFamily: 'Titillium Web',
            fill: '#fff',
            align: 'center',
            fontSize: Math.floor(size / (app.helpers.isMobile() ? 0.7 : 1.9)),
            fontWeight: 600 }
        stage.removeChildren();
        drawBg();
        var text2 = new PIXI.Text(actual_JSON.title1.toUpperCase(), largeTextOptions);
        text2.anchor.x = 0.5;
        text2.x = width / 2;
        text2.y = Math.floor(screenUtils.getWindowHeightOrMin(parentDiv) * 0.18);
        var text3 = new PIXI.Text(actual_JSON.sub1, smallTextOptions);
        text3.anchor.x = 0.5;
        text3.x = width / 2;
        text3.y = height * (app.helpers.isMobile() ? 0.28 : 0.23);
        text3.resolution = 2;

        var startImg1 = PIXI.Sprite.fromImage(path + '3.png');
        startImg1.width = gridlength / 6;
        startImg1.height = gridlength / 6;
        startImg1.x = width / 2 - gridlength / 12;
        startImg1.y = height * (app.helpers.isMobile() ? 0.06 : 0.3);
        var text4 = new PIXI.Text(actual_JSON.title2, largeTextOptions);
        text4.anchor.x = 0.5;
        text4.x = width / 2;
        text4.y = height * (app.helpers.isMobile() ? 0.38 : 0.43);
        var text5 = new PIXI.Text(actual_JSON.sub2 + "\n" + actual_JSON.sub3, smallTextOptions);
        text5.anchor.x = 0.5;
        text5.x = width / 2;
        text5.y = height * 0.49;
        var startImg2 = PIXI.Sprite.fromImage(path + 'bulletred.png');
        startImg2.width = gridlength / 12;
        startImg2.height = gridlength / 12;
        startImg2.x = width / 2 - gridlength / 24;
        startImg2.y = height * (app.helpers.isMobile() ? 0.66 : 0.6);
        var startButtonShape = new PIXI.Graphics();
        startButtonShape.beginFill(0xE9B600); // Yellow
        startButtonShape.drawPolygon((app.helpers.isMobile() ? drawArrowMobile(width / 2, height * 0.75) : drawArrow(width / 2, height * 0.75)));
        startButtonShape.interactive = true;
        var buttonTimer = false;
        startButtonShape.mouseover = function (mouseData) {
            buttonTimer = true;
            $('html,body').css('cursor', 'pointer');
        }
        startButtonShape.mouseout = function (mouseData) {
            buttonTimer = false;
            $('html,body').css('cursor', 'default');
        }
        var text6 = new PIXI.Text(actual_JSON.start, fatTextOptions);
        text6.anchor.x = 0.5;
        text6.x = width / 2;
        text6.y = height * 0.77;
        stage.addChild(text2);
        stage.addChild(text3);
        stage.addChild(startImg1);
        stage.addChild(text4);
        stage.addChild(text5);
        stage.addChild(startImg2);
        stage.addChild(startButtonShape);
        stage.addChild(text6);
        makemenu();
        var count = 0;
        startButtonShape.mousedown = startButtonShape.touchstart = function () {
            instructions();
        }

        function animate() {
            animateMenu();
            if (buttonTimer) {
                count = Math.min(1.12, count + 0.013);
            } else {
                count = Math.max(1, count - 0.013);
            }
            if (stage.children.indexOf(startButtonShape) > -1) {
                startButtonShape.setTransform(0, 0, count, 1);
                startButtonShape.x = (1 - count) * screenUtils.getWindowWidthOrMin() / 2;
            }
            animationLoop = requestAnimationFrame(animate);
            renderer.render(stage);
        }

        cancelAnimationFrame(animationLoop);
        animate();
    }

//---------------------------------- HELPER -----------------------------------
    function drawBg() {
        bg = PIXI.Sprite.fromImage(path + 'background.png');
        blacklayer = new PIXI.Graphics();
        blacklayer.beginFill(0x000000, 1);
        blacklayer.drawRect(0, 0, width, height);
        blacklayer.alpha = 0.8;
        bglayer = new PIXI.Graphics();
        bglayer.beginFill(0x000000, 0.0);
        bglayer.drawRect(0, 0, width, height);
        bg.width = width;
        bg.height = height;
        powered = PIXI.Sprite.fromImage(path + 'powered.png');
        powered.x = width - 214;
        powered.y = height - 40;
        powered.width = 214;
        powered.height = 40;

        powered.interactive = true;
        powered.mousedown = function () {
            if(!app.helpers.isMobileOrTablet){
                window.open('http://brainsfirst.com/', '_blank');
            }
        };
        powered.mouseover = function (mouseData) {
            buttonTimer = true;
            $('html,body').css('cursor', 'pointer');
        }
        powered.mouseout = function (mouseData) {
            buttonTimer = false;
            $('html,body').css('cursor', 'default');
        }

        stage.addChild(bg);
        stage.addChild(blacklayer);
        stage.addChild(bglayer);
        if (!app.helpers.isMobile()) {
            stage.addChild(powered);
        }
    }

    function drawArrow(x, y) {
        return [x - 3.4 * size, y,
            x + 3 * size, y,
            x + 3.3 * size, y + 0.7 * size,
            x + 3 * size, y + 1.4 * size,
            x - 3.4 * size, y + 1.4 * size,
            x - 3.1 * size, y + 0.7 * size
        ]
    }

    function drawArrowMobile(x, y) {
        var size3 = size * 1.8;
        var size3x = size * 2.7;
        return [x - 3.4 * size3x, y,
            x + 3 * size3x, y,
            x + 3.3 * size3x, y + 0.7 * size3,
            x + 3 * size3x, y + 1.4 * size3,
            x - 3.4 * size3x, y + 1.4 * size3,
            x - 3.1 * size3x, y + 0.7 * size3
        ]
    }

//---------------------------- PAUSE LISTENERS--------------------------------
    $(window).blur(function () {
        pauseswitch = true;
        try {
            pause();
        } catch (e) {
        }
    });
    $('.header').click(function (e) {
        if (app.helpers.checkDeviceOrientation()) {
            pauseswitch = true;
            pause();
        }
    });

    $('.subheader *').click(function (e) {
        if (app.helpers.checkDeviceOrientation()) {
            pauseswitch = true;
            pause();
        }
        e.preventDefault();
        return false;
    });

    $('.logo, .logo img ').click(function (e) {
        if (app.helpers.checkDeviceOrientation()) {
            home();
        }
        e.preventDefault();
        return false;
    });

    this.initDummy = function () {
        $(parentDiv).css({
            "overflow": "hidden",
            "position": "absolute",
            "width": "100%"
        });
        $('canvas').hide();
        $('.page__wrapper').show();
        $('.medal-score-cont span').html('666');
        $('.game-score-box .score').html('666');

        results[0] = ["Level", "time in level", "iti", "points", "shots", "hits", "misses", "score", "Browser", "OS", "Screen", "Timestamp"];
        for (var i = 0; i < 6; i++) {
            results.push([1, 222, 333, 444, 555, 666, 777, 888, app.helpers.getBrowserName(), navigator.platform, screen.width + 'x' + screen.height, app.helpers.getTimestamp() ]);
        }
        common.saveGameData(results, 2, 666);
    }

}

export default Game2;
