import $ from 'jquery';

export default function (cb) {

    var $email = $('#email-field');
    var $btn = $('#reset-password-btn');

    $btn.on('click', function (e) {
        e.preventDefault();

        var email = $email.val();
        if (email == '') {
            $email.closest('.form-group').addClass('has-error');
        } else {

            const success = function () {
                sessionStorage.setItem("game-tempData", email);
                app.events.fire.navigate('pass-confirm.html');
            };

            const error = function () {
                $email.closest('.form-group').addClass('has-error');
            };

            app.api.resetPasswordMailRequest(email, success, error);
        }
    });

    //proceed with common page initialisation
    cb();
};

