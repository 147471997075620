import $ from 'jquery';
import screenfull from 'screenfull';

var chromeHeight;

function activateMobileCss() {
    $('.page').css("padding", "0");
    $('.header').hide();
    $('.ribbon').hide();
    $('.divider').css('max-width', '10px');
    $('.subheader-list li:not(.active)').hide();
    $('.subheader-list .active').css("top", "0");
    $('.game-score-box').css("top", "0");

    var css = '.demo-navigation {top: 50px; padding:0 !important} .demo-navigation>div{transform:scale(0.64)}',
        head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('style');
    style.type = 'text/css';
    if (style.styleSheet) {
        style.styleSheet.cssText = css;
    } else {
        style.appendChild(document.createTextNode(css));
    }
    head.appendChild(style);
    $('body').css("overflow-x ", "hidden");
    $('.page.page-welcome-game.game-finish .page__wrapper .page__content .welcome-game-item.finish-game.top-block ').remove();
}

function activateMobileMode() {
    activateMobileCss();
    $('<div class="scrollmsg swipemsg">' +
        '<img src="./static/img/content/swipeup.png">' +
        '<div>swipe up</div>' +
        '</div>').insertBefore('.page__wrapper');
    $('<div class="scrollmsg touchmsg">' +
        '<img src="./static/img/content/tapscreen.png">' +
        '<div>tap to go fullscreen</div>' +
        '</div>').insertBefore('.page__wrapper');
    fullscreenCheck();
    chromeHeight = window.innerHeight;
}

var _chromeIOSReady = false;

function fullscreenCheck() {

    var isLandscape = Math.abs(window.orientation) === 90;
    var windowHeight = window.innerHeight;

    var forceSwipeUp = function () {
        var checkChromeIOS = function () {
            if (!_chromeIOSReady) {
                showWindmill();
                var checker = setInterval(function () {
                    if (windowHeight > chromeHeight + 5) {
                        hideWindmill();
                        _chromeIOSReady = true;
                        clearInterval(checker);
                        document.body.addEventListener('touchstart', preventTouchmove);
                    }
                }, 2);
            }
        }

        var checkSafariIOS = function () {
            var showTooltip = (windowHeight < document.body.offsetHeight);
            if (!isLandscape) return;

            if (showTooltip) {
                showWindmill();
            } else {
                hideWindmill();
                try {
                    window.game.invokeResume();
                } catch (e) {
                }
                document.body.addEventListener('touchstart', preventTouchmove);
            }
        };

        hideTouchMsg();
        if (isChromeIOS()) {
            checkChromeIOS();
        } else {
            checkSafariIOS();
        }

        window.addEventListener("orientationchange", function (event) {
            setTimeout(function () {
                isLandscape = Math.abs(window.orientation) === 90;
                chromeHeight = window.innerHeight;
                if (isLandscape) {
                    if (isChromeIOS()) {

                    } else {
                        document.body.addEventListener('touchstart', preventTouchmove);
                    }
                }
            }, 100);
        });

    };

    var forceTap = function () {
        hideWindmill();
        hideTouchMsg();
        if (screenfull.isFullscreen === false && isLandscape === true) {
            showTouchMsg();
        }
        screenfull.on('change', () => {
            if (screenfull.isFullscreen === false && isLandscape === true) {
                showTouchMsg();
            } else {
                hideTouchMsg();
            }
        });
        $('.touchmsg').on('click', function (event) {
            if (screenfull.enabled) {
                screenfull.toggle(event.target);
            }
        });
    };

    if (isSafari()) {
        forceSwipeUp();
    } else {
        forceTap();
    }

    function showTouchMsg() {
        $('.touchmsg').css('z-index', '1').show();
    };

    function hideTouchMsg() {
        $('.touchmsg').css('z-index', '-1').hide();
    };

    function showWindmill() {
        $('.swipemsg').css('z-index', '1').show();
    };

    function hideWindmill() {
        $('.swipemsg').css('z-index', '-1').hide();
    };
};

function isChromeIOS() {
    return (window.navigator.userAgent.indexOf('Android') > -1 && (/Chrome\/[.0-9]*/).test(window.navigator.userAgent)) || navigator.userAgent.match('CriOS');
}

function isSafari() {
    var ua = window.navigator.userAgent;
    return (ua.indexOf('iPhone') !== -1 && ua.indexOf('Safari') !== -1);
}

function preventTouchmove(e) {
    e.preventDefault();
}

export default {
    activateMobileCss,
    activateMobileMode,
    fullscreenCheck,
    preventTouchmove,
};
