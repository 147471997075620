//
// Common place to have constants available on all JS
//
export default {

    ui: {
        animationSpeed: 300,
    },

    http: {
        contentType: {
            json: 'application/json; charset=UTF-8'
        }
    },

    consents: {
        // options must match the BE enum ClientConsentKey.java
        contact: 'contact',
        shareBrainsfirst: 'share_brainsfirst',
        sharePartner: 'share_partner'
    },

    gameMode: {
        // options must match the BE enum GameMode.java
        SINGLE: 'single',
        MANUAL_REPLAY: 'manual_replay',
        AUTOMATIC_REPLAY: 'automatic_replay',
    },

    landingPageFilesMap: {
        0: 'index-default.html',
        1: 'index-sports.html',
        6: 'index-soccer.html',
    },
    landingPageSoccer: 6,
    landingPageSports: 1,

    downloads: {
        candidateReportFileName: 'Neurolympics.pdf'
    },

    sheetKeys: {
        submitGameResult: {
            btnLabel: "game_result_submit_button",
            errorLine1: "game_result_error_line1",
            errorLine2: "game_result_error_line2",
            waitLine1: "game_result_submit_line1",
            waitLine2: "game_result_submit_line2",
        }
    },

    topTraitType: {
        // options must match the BE enum TopTraitType.java
        PYRAMID: 'PYRAMID'
    },

    remoteWork: {
        path: 'workfromanywhere',
        googleTagManagerEvents: {
            signUp: 'remote-work-sign-up',
            viewResults: 'view-remote-work-results',
            shareResults: 'share-linkedin-results',
        }
    },

    openAgeDropdownAtMinusYears: 30,

};
