import $ from 'jquery';
import storage from '../../js/app/base/storage';

let isDemo = storage.getCampaign() ? (storage.getCampaign() || {}).id === 43 || (storage.getCampaign() || {}).id === 82 : null;
let isSportsDemo = storage.getCampaign() ? (storage.getCampaign() || {}).id === 82 : null;

const showDemo = function () {
    if (isDemo) {
        $('.demo-image').show();
        $('.demo-bottom-bar').hide();
        $('.splash-landing-1').addClass('splash-demo');
    }
    if (isSportsDemo) {
        $('.demo-background').addClass('demo-sports-bg');
        $('.signup-container').addClass('demo-sports');
    }
}

const styleDemo = function () {
    $('.show-for-demo').hide();
    $('.hide-for-demo').show();
    $('.slider-container').hide()
    $('.copyright-for-demo').hide()
    if (isDemo) {
        $('.show-for-demo').show();
        $('.hide-for-demo').hide();
        $('.profile-page').addClass('demo-profile');
        $('.slider-container').css("display", "flex")
        $('.copyright-for-demo').show()
        $('.profile-next-btn').hide();
        $('.spacer-40').hide();
    }
    if (isSportsDemo) {
        $('.page__content').addClass('demo-sports');
    }
}

const demoSwitch = function () {
    $("#switch-next").on("click", () => {
        setTimeout(function() {
            if (document.getElementById('switch-input').checked) {
                app.events.fire.navigate('game-types.html');
            }
        }, 500);
    });
}

export default {
    showDemo,
    styleDemo,
    demoSwitch
};
