import $ from 'jquery';
import ui from 'static/js/app/ui/ui';
import api from 'static/js/app/base/api';
import helpers from 'static/js/app/helpers/helpers';
import * as Sentry from "@sentry/browser";

export default function (cb) {

    // this page can be accessed without a logged in user, without a known campaign ID
    // values from app.storage may not be present

    const key = helpers.getURLParameter('key');

    const $password = $('#password-field');
    const $error = $password.closest('.form-group');
    const $apiError = $(".api-error");
    const $resetButton = $('#reset-password-btn');
    const $showOnSuccess = $('.css-marker-show-on-success');
    const $hideOnSuccess = $('.css-marker-hide-on-success');
    const $goHomeButton = $('#go-to-login-btn');

    $resetButton.on('click', function (e) {
        e.preventDefault();
        ui.startPageLoading();

        var password = $password.val();
        if (password == '' || helpers.checkPasswordStrength(password) === false) {
            $error.addClass('has-error');
            ui.stopPageLoading();
            return;
        }

        api.resetPasswordConfirm(password, key)
            .then((campaignId) => {
                $error.removeClass('has-error');
                $hideOnSuccess.hide();

                // Don't use navigate() here
                //    app.events.fire.navigate('pass-reset-confirm.html');
                // because that requires local storage to have the current campaign information
                // Most likely, the user followed the link from the email that will open a new tab/window that does not have the campaign set on local storage.
                // This will then default to campaign 1 and the user is on a wrong URL.
                if (campaignId) {
                    const url = helpers.setUrlParameter('index.html', 'c', campaignId);
                    $goHomeButton.attr("href", url);
                    $showOnSuccess.show();
                    return;
                }

                // ERROR -- no campaign ID provided from BE
                const obj = {
                    // using stringify is easy to confirm null, undefined, empty string...
                    campaignId: JSON.stringify(campaignId),
                    key: JSON.stringify(key),
                }
                // track can only take 1 argument. stringify our data after the message
                Sentry.captureMessage('Successfully did password reset, but there is no campaign ID to redirect the user to the proper landing page | data: ' + JSON.stringify(obj))
                throw new Error();
            })
            .catch(() => $apiError.show())
            .finally(() => ui.stopPageLoading());

    });

    cb();
};

