import $ from "jquery";
import ui from "static/js/app/ui/ui";
import api from "static/js/app/base/api";
import events from "static/js/app/base/events";
import storage from "static/js/app/base/storage";
import helpers from "static/js/app/helpers/helpers";
import constants from "static/js/app/base/constants";

// This class does not adapt automatically any number of consents
class Consents {
    constructor(opt) {
        this.renderDom = opt.renderDom;
        this.consents = $(opt.consentIds);
        this.submitButton = $(opt.nextBtn);
        this.error = $(opt.error);
        this.NEXT_PAGE = opt.nextPage;
        this.askConsentBrainsfirst = storage.getCampaign()
            ? storage.getCampaign().askConsentBrainsfirst
            : false;
    }

    /* public methods */

    renderIfConsentsAreNotSubmittedYet() {
        return this.initialize().then((consents) => {
            if (consents.data.some((consent) => consent.key === 'share_partner')) {
                return events.fire.navigate(this.NEXT_PAGE);
            } else if (consents.exist && !consents.data.some((consent) => consent.key === 'share_partner')) {
                $("#r1, #r3").hide();
            }
            return this.renderDom();
        });
    }

    render() {
        return this.initialize()
            .then((consents) => this.updateDomCheckboxStatus(consents.data))
            .then(() => this.renderDom());
    }

    /* private methods */

    initialize() {
        if (!this.askConsentBrainsfirst) {
            $("#r3").hide();
        }
        this.consents.click(() => this.refreshSubmitButton());


        return api.getClientConsents().then((res) => {
            let consentsData = res || [];
            if (consentsData.length > 0) {
                return {
                    data: consentsData,
                    exist: true,
                };
            }
            return {
                data: [],
                exist: false,
            };
        });
    }

    refreshSubmitButton() {
        const consentsAlreadyAccepted = $("#r1, #r2").is(":hidden");

        const allConsentsAccepted = $(this.consents)
            .toArray()
            .filter((el, i) => (i !== 2 && (!consentsAlreadyAccepted || (i !== 0 && i !== 2))))
            .every((el) => el.checked);

        if (allConsentsAccepted) {
            this.enableSubmit();
        } else {
            this.disableSubmit();
        }
    }

    disableSubmit() {
        this.submitButton.off("click");
        this.submitButton.attr("disabled", true);
    }

    enableSubmit() {
        this.submitButton.click((e) => {
            e.preventDefault();
            e.stopPropagation();
            this.submit();
        });
        this.submitButton.attr("disabled", false);
    }

    showError() {
        this.error.removeClass("start-hidden");
    }

    hideError() {
        this.error.addClass("start-hidden");
    }

    submit() {
        ui.startPageLoading();
        this.disableSubmit();
        this.hideError();

        // We can only submit when 'contact' and 'sharePartner' are accepted
        // Only the 'shareBrainsfirst' is optional (the last DOM element)
        let consentsDto = [
            { key: constants.consents.contact, accepted: true },
            { key: constants.consents.sharePartner, accepted: true },
        ];
        if (this.askConsentBrainsfirst) {
            let consentBrainsfirst = this.consents[this.consents.length - 2];
            consentsDto.push({
                key: constants.consents.shareBrainsfirst,
                accepted: consentBrainsfirst.checked,
            });
        }

        app.api
            .postClientConsents(consentsDto)
            .then(() => events.fire.navigate(this.NEXT_PAGE))
            .catch((err) => {
                console.error("Can't save client consents: ", err);
                this.showError();
                this.refreshSubmitButton();
                ui.stopPageLoading();
            });
    }

    updateDomCheckboxStatus(consentsData) {
        const contact =
            consentsData.find((x) => x.key === constants.consents.contact) ||
            false;
        if (contact.accepted) {
            this.consents[0].checked = true;
        }

        const sharePartner =
            consentsData.find(
                (x) => x.key === constants.consents.sharePartner
            ) || false;
        if (sharePartner.accepted) {
            this.consents[1].checked = true;
            this.consents[3].checked = true;
        }

        if (this.askConsentBrainsfirst) {
            const shareBrainsfirst =
                consentsData.find(
                    (x) => x.key === constants.consents.shareBrainsfirst
                ) || false;
            if (shareBrainsfirst.accepted) {
                this.consents[2].checked = true;
            }
        }

        this.refreshSubmitButton();
    }
}

export default function (cb) {
    // if mode edit, we come from the navigation link
    const isEditMode = helpers.getURLParameter("mode") === "edit";
    const opt = {
        consentIds: "#consent1,#consent2,#consent3,#consent4",
        nextBtn: "#next",
        error: ".consents-error",
        nextPage: "game-types.html",
        renderDom: cb,
    };
    const consents = new Consents(opt);

    let promise = null;
    if (isEditMode) {
        promise = consents.render();
    } else {
        promise = consents.renderIfConsentsAreNotSubmittedYet();
    }
    return promise.catch((err) => {
        console.error(err);
        ui.error("Can't display consents information");
    });
}
