import constants from 'static/js/app/base/constants';
import campaign from 'static/js/app/base/campaign';

function submitDefaultConsents() {
    var consents = [{ key: constants.consents.sharePartner, accepted: true }];

    app.api.postClientConsents(consents)
        .then(() => goToNextPage())
        .catch((err) => {
            console.error("Can't save client default consents: ", err);
            app.ui.error("We are unable to save your decision. Please try again later.");
        });
}

function goToNextPage() {
    app.events.fire.navigate('introduction-step.html');
}

export default function (cb) {

    // HCM campaigns (landing page 1) have the consents page.
    // all other campaigns just assume consents are managed elsewhere and we can just marked the required ones as accepted.
    // for example, sport clients are underage and so the clubs manage this for us.
    let action = submitDefaultConsents;
    if (campaign.isHcm()) {
        action = goToNextPage;
    }

    action();

    cb();
}
