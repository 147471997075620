import $ from "jquery";
import * as Sentry from "@sentry/browser";

export default function (cb) {
    reloadPolicy().then(() => {
        //proceed with common page initialisation
        cb();
    });

    // reload policy when language changes
    app.events.subscribe.languageUpdated(reloadPolicy);

    function reloadPolicy() {
        var campaignId = app.storage.getCampaign()
            ? app.storage.getCampaign().id
            : 43
        return app.api
            .getTermsAndConditions(campaignId)
            .then((result) => $(".terms-and-privacy-cont").html(result.text))
            .catch((err) =>
                showError("Privacy information cant be displayed.", err)
            );
    }

    function showError(msg, err) {
        app.ui.error(msg);
        Sentry.captureException(
            {
                message: msg,
                extra: "loading privacy policy information",
                error: err,
            }
        )

    }
}
