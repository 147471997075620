export default {
    loginAndGoHome: function (res) {
        app.campaign.setCampaign(res.client.campaign);
        app.storage.setToken(res.token);
        app.storage.setRefreshToken(res.refreshToken);
        app.storage.setCandidate(res.client);
        app.state.loggedIn = app.api.isTokenValid(res.refreshToken);
        app.events.fire.navigate('home.html');
    },

    // click handler for DOM events
    logout: function () {
        const logoutDestination = 'index.html?c=' + app.storage.getCampaign().id;
        app.api.logout(() => {
            app.storage.clear();
            app.events.fire.navigate(logoutDestination);
        });
    },

    getName: function () {
        return (app.storage.getCandidate().firstName || '').capitalize() + ' ' + (app.storage.getCandidate().lastName || '').capitalize();
    },

    isEmailKnown: function () {
        return app.storage.getCandidate().isEmailKnown;
    },
};
