
const errorCode = {
    // the user belongs to campaign A but used campaign B's url to login
    INVALID_CAMPAIGN: 256,
    EMAIL_EXISTS: 259,
    EMAIL_USED_ON_PRE_REG: 260,
    // the user needs to follow the confirmation link sent to the email
    REGISTRATION_NOT_CONFIRMED: 261,
    PROFILE_NOT_CREATED: 404,
}

export default errorCode;
