import $ from 'jquery';
import device from "current-device";
var MobileDetect = require("./mobileDetect");
String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

function basisToWidth(element, prop) {
    const $this = $(element);
    const flexBasis = $this.css("flex-basis");
    const flex = $this.css("flex");
    if (
        (typeof flexBasis == "string" && flexBasis.indexOf(prop) !== -1) ||
        (typeof flex == "string" && flex.indexOf(prop) !== -1)
    ) {
        $this.css({
            flex: "none",
            width: prop
        });
    }
};

export default {

    getURLParameter: function (name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
    },

    getBrowserName: function () {
        var N = navigator.appName, ua = navigator.userAgent, tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*([\d\.]+)/i);
        if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) M[2] = tem[1];
        M = M ? [M[1], M[2]] : [N, navigator.appVersion, '-?'];
        return M.join(' ');
    },

    checkPasswordStrength: function (pw) {
        var strength = 0;
        if ((/[a-z]/.test(pw))) {
            strength++;
        }
        if ((/[A-Z]/.test(pw))) {
            strength++;
        }
        if ((/[0-9]/.test(pw))) {
            strength++;
        }
        if (pw.match(/[^A-Za-z0-9\-_]/)) {
            strength++
        }
        if (strength < 3 || pw.length < 8) {
            return false;
        } else {
            return true;
        }
    },

    replaceAll: function (str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    },

    isWebkitBrowser: function () {
        b = false;
        if (navigator.userAgent.indexOf("WebKit") > -1) {
            b = true;
        }
        return b;
    },

    isFirefox: function () {
        b = false;
        if (navigator.userAgent.indexOf("Firefox") > -1) {
            b = true;
        }
        return b;
    },

    firefoxGameHack: function () {
        if (this.isFirefox() && !this.isMobileOrTablet()) {
            $('#firefoxGameHackInput').remove();
            $('<input id="firefoxGameHackInput">').insertBefore('.subheader');
            $('#firefoxGameHackInput').focus();
        }
    },

    gamesDone: function (campaignGames, results) {
        if (!campaignGames || !results) {
            return false;
        }
        const campaignGameIds = campaignGames.map(game => game.id);
        const gameResultsIds = results.map(result => result.game.id);
        return campaignGameIds.every(campaignGameId =>
            gameResultsIds.some(resultGameId => resultGameId === campaignGameId)
        )
    },

    isIE: function () {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }
        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }
        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }
        return false;
    },

    supportsWebGL: function () {
        if (this.isIE() && this.isIE() > 9) {
            return true;
        } else {
            try {
                var canvas = document.createElement("canvas");
                return !!
                    window.WebGLRenderingContext &&
                    (canvas.getContext("webgl") ||
                        canvas.getContext("experimental-webgl"));
            } catch (e) {
                return false;
            }
        }
    },

    checkDeviceOrientation: function () {
        var b = true;
        if (this.isMobileOrTablet() && ((window.innerHeight > window.innerWidth))) {
            b = false;
        }
        return b;
    },

    // TODO ccampos maybe we can delete this and other IE related code -- test on edge only
    fixIESignUpInBox: function (parent) {
        if (navigator.userAgent.indexOf("Trident") !== -1) {
            let totalHeightEI = 0;
            parent.find('.enter-information > *').each(function () { totalHeightEI += $(this).outerHeight(true); });
            let totalHeightLN = 0;
            parent.find('.legal-notice > *').each(function () { totalHeightLN += $(this).outerHeight(true); });
            const maxHeight = Math.max(totalHeightEI, totalHeightLN);
            $('.enter-information, .legal-notice').css("min-height", maxHeight);
            $('.enter-information').parent().css("min-height", maxHeight);
        }
    },


    isMobile: function () {
        const md = new MobileDetect(window.navigator.userAgent);
        return (
            device.mobile() &&
            "ontouchstart" in document.documentElement &&
            !!md.phone()
        );
    },

    isMobileOrTablet: function () {
        const md = new MobileDetect(window.navigator.userAgent);
        return this.isMobile() || device.tablet() || md.mobile();
    },

    getColor: function (value) {
        //value from 0 to 1
        var hue = ((1 - value) * 120).toString(10);
        return ["hsl(", hue, ",100%,50%)"].join("");
    },

    validateEmail: function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },

    validateDeloitteEmail: function (email) {
        var regexp = /@deloitte/;
        return regexp.test(email)
    },

    getTimestamp: function () {
        function a(a) {
            return 0 <= a && 10 > a ? "0" + a : a + ""
        }

        var b = new Date;
        return [
            [a(b.getDate()), a(b.getMonth() + 1), b.getFullYear()].join("."), [a(b.getHours()), a(b.getMinutes()), a(b.getSeconds())].join(":")
        ].join("-")
    },

    verificar: function (arr) {
        for (var i = 1; i < arr.length; i++) {
            if (arr[i - 1] != arr[i]) return true;
        }
        return false;
    },

    median: function (a) {
        a.sort(function (a, b) {
            return a - b
        });
        var b = Math.floor(a.length / 2);
        return a.length % 2 ? a[b] : (a[b - 1] + a[b]) / 2
    },

    shuffleArray: function (a) {
        for (var b = a.length - 1; 0 < b; b--) {
            var c = Math.floor(Math.random() * (b + 1)),
                d = a[b];
            a[b] = a[c];
            a[c] = d
        }
        return a
    },

    ieFlexFix: function (specificElement = false) {
        if (navigator.userAgent.indexOf("Trident") !== -1) {
            if (specificElement) {
                specificElement = $(specificElement);
                basisToWidth(specificElement, "100%");
                basisToWidth(specificElement, "100vw");
            } else {
                $("div, form, p").each(function () {
                    basisToWidth(this, "100%");
                    basisToWidth(this, "100vw");
                });
            }
        }
    },

    toTitleCase: function (str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    },

    addDefaultParametersToUrl: (url) => {
        let finalUrl = url;
        let campaignId = app.storage.getCampaign() && app.storage.getCampaign().id;
        if (campaignId) {
            finalUrl = app.helpers.setUrlParameter(finalUrl, 'c', campaignId);
        }
        return finalUrl;
    },

    setUrlParameter: (url, queryKey, queryValue) => {

        // TODO we should refactor to use standard DOM apis such as new URLSearchParams(...)

        let key = encodeURIComponent(queryKey),
            value = encodeURIComponent(queryValue);

        let baseUrl = url.split('?')[0],
            newParam = key + '=' + value,
            params = '?' + newParam;

        // if there are no query strings, make urlQueryString empty
        let qs = url.split('?')[1];
        let urlQueryString = qs === undefined ? '' : '?' + qs;

        // If the "search" string exists, then build params from it
        if (urlQueryString) {
            let updateRegex = new RegExp('([\?&])' + key + '[^&]*');
            let removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');

            if (value === undefined || value === null || value === '') { // Remove param if value is empty
                params = urlQueryString.replace(removeRegex, "$1");
                params = params.replace(/[&;]$/, "");

            } else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
                params = urlQueryString.replace(updateRegex, "$1" + newParam);

            } else if (urlQueryString == '') { // If there are no query strings
                params = '?' + newParam;
            } else { // Otherwise, add it to end of query string
                params = urlQueryString + '&' + newParam;
            }
        }

        // no parameter was set so we don't need the question mark
        params = params === '?' ? '' : params;

        return baseUrl + params;
    }

}
