(function (root, factory) {
  'use strict'
  if (typeof define === 'function' && define.amd) define([], factory)
  else if (typeof exports === 'object') module.exports = factory()
  else root.Timer = factory()
}(this, function () {
  'use strict'

  var Timer = function () {
    if (!(this instanceof Timer)) return new Timer()
    this._ = {
      duration : 0,
      status   : 'paused',
      start    : 0
    }
  }

  Timer.prototype.start = function () {

    this._.start = performance.now() || new Date().getTime()
    this._.status = 'started'
    return this
  }
  
  
  Timer.prototype.resume = function () {

    this._.start =  (performance.now() || new Date().getTime()) - this._.duration
    this._.status = 'started'
    return this
  }

  Timer.prototype.pause = function () {
    if (this._.status !== 'started') return this
    this._.duration = ( performance.now() || new Date().getTime()) - this._.start
    this._.status = 'paused'
    return this
  }

  
  Timer.prototype.reset = function () {

    this._.start = performance.now() || new Date().getTime()
    this._.status = 'started'
    this._.duration = 0
    return this
  }
  
  Timer.prototype.getDuration = function () {

    if (this._.status === 'started')
      return Math.floor(( performance.now() || new Date().getTime()) - this._.start)
    if (this._.status === 'paused') return Math.floor(this._.duration)
    return 0
  }

  Timer.prototype.getStatus = function () {
    return this._.status
  }

  return Timer
}))