import BaseForm from './BaseForm';
import ui from 'static/js/app/ui/ui';

//
// This 'form' is only one Button to play the games (auto-register on BE)
//
// Show this form when:
//    (open campaign) campaign.registrationClose === false
//    (no reg. needed) campaign.open             === true
//    or
//    (no reg. needed) campaign.demo             === true
//
class AutoRegistrationForm extends BaseForm {
    constructor(tabReference, buttonReference, campaign) {
        super(tabReference, buttonReference, campaign);
    }

    validateForm() {
        return true
    }

    submit() {
        return app.api.autoRegister(this.campaign.id)
            .then((res) => {
                app.candidate.loginAndGoHome(res);
            })
            .catch((e) => {
                this.tabReference.find('.fields .help-block[data-text=default_error]').addClass(this.errorClass);
                ui.stopPageLoading();
            });
    }
}

export default AutoRegistrationForm;
