/*
*  Birthdate select picker by RootKot -- no npm for this? so let's make it part of the app as a helper class
*
*  Requires "select2"
*
*  Usage:
*       new BirthdayPicker('.birthdate-f', 'dd-mm-yyyy');
*       var date = $('.birthdate-f').data('val');
*
*/

import $ from 'jquery';
import constants from 'static/js/app/base/constants';

var BirthdayPicker = function (selector, format) {

    var daysInMonth = function (month, year) {
        return new Date(year, month, 0).getDate();
    }

    var updateNumberOfDays = function () {
        var day = $(selector + ' .days').val();

        $(selector + ' .days').html('');
        var month = $(selector + ' .months').val();
        var year = $(selector + ' .years').val();
        var days = daysInMonth(month, year);

        for (var i = 1; i < days + 1; i++) {
            $(selector + ' .days').append($('<option />').val(i).html(i));
        }

        if (day !== null)
            $(selector + ' .days option[value="' + day + '"]').attr('selected', 'selected');
    };

    var selected = function () {

        var month = $(selector + ' .months').val();
        var year = $(selector + ' .years').val();
        var day = $(selector + ' .days').val();

        if (day.length === 1) day = '0' + day;
        if (month.length === 1) month = '0' + month;

        var result = format;
        result = result.replace('dd', day);
        result = result.replace('mm', month);
        result = result.replace('yyyy', year);

        $(selector).attr('data-val', result);
    };

    var init = function () {

        var selectsHtml =
            '<select class="days"></select>' +
            '<select class="months"></select>' +
            '<select class="years"></select>';

        $(selector).html(selectsHtml);
        $(selector).css('padding', '0px')

        $(selector + ' .days').select2();
        $(selector + ' .months').select2();
        $(selector + ' .years').select2();

        const startYear = new Date().getFullYear();
        const openDropdownAt = startYear - constants.openAgeDropdownAtMinusYears;
        for (var i = startYear; i > 1900; i--) {
            (i === openDropdownAt) ?
                $(selector + ' .years').append($('<option selected="selected" />').val(i).html(i)) :
                $(selector + ' .years').append($('<option />').val(i).html(i));
        }

        for (var i = 1; i < 13; i++) {
            $(selector + ' .months').append($('<option />').val(i).html(i));
        }

        updateNumberOfDays();
        selected();

        $(selector + ' .months').change(updateNumberOfDays);
        $(selector + ' .years').change(updateNumberOfDays);
        $(selector + ' select').change(selected);
    };

    init();
};

export default BirthdayPicker;
