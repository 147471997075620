import { selectResultTemplate } from "../pagescripts/private/game-types"

function onError(err) {
    console.error("Can't submit dummy game results")
    console.error(err);
}

class Private {
    constructor(app) {
        this.app = app;
        this.gameDifficulty = (app.campaign.isLightversion()) ? 'GAME_LIGHT' : 'GAME_FULL';
        this.browser = app.helpers.getBrowserName();
        this.platform = navigator.platform;
        this.screen = screen.width + 'x' + screen.height;
    }

    saveGameData(gameNr, results, cb) {
        var data = {
            gameId: gameNr,
            result: results,
            gameDifficulty: this.gameDifficulty,
            points: 666,
        };
        console.log('Submitting dummy results for game ' + gameNr + ' ...');
        this.app.api.saveGameResult(data, cb, onError);
    }

    createDummyResults() {
        let results = [];
        let allResults = [];

        // game 1
        results[0] = ["Trial", "TType", "Used_Objects", "Corrects_Answers", "Response_Code", "Total_RT", "Browser", "OS", "Screen", "Timestamp"];
        for (var i = 0; i < 100; i++) {
            results.push([i, 2, 3, 4, 666, 555, this.browser, this.platform, this.screen, this.app.helpers.getTimestamp()]);
        }
        allResults.push(results);
        results = [];

        // game 2
        results[0] = ["Level", "time in level", "iti", "points", "shots", "hits", "misses", "score", "Browser", "OS", "Screen", "Timestamp"];
        for (var i = 0; i < 6; i++) {
            results.push([1, 222, 333, 444, 555, 666, 777, 888, this.browser, this.platform, this.screen, this.app.helpers.getTimestamp()]);
        }
        allResults.push(results);
        results = [];

        // game 3
        results[0] = ['trial', 'TType', 'response', 'correct', 'responseTime', 'iti', 'browser', 'os', 'screen', 'timestamp'];
        for (var i = 0; i < 225; i++) {
            results.push([i, 1, "left", 1, 333, 444, this.browser, this.platform, this.screen, this.app.helpers.getTimestamp()]);
        }
        allResults.push(results);
        results = [];

        // game 4
        results[0] = ["Trial", "RT", "Cue", "Congruency", "Arrow_Location", "Response", "score", "Browser", "OS", "Screen", "Timestamp"];
        for (var i = 0; i < 151; i++) {
            results.push([i, 111, "CENTER_CUE", "LEFT_INCONGRUENT", "BOTTOM", 1, 666, this.browser, this.platform, this.screen, this.app.helpers.getTimestamp()]);
        }
        allResults.push(results);
        results = [];

        return allResults;
    }
}

class GamesDummy {
    constructor(app) {
        this.private = new Private(app);
    }

    initDummyAllGames() {
        let allResults = this.private.createDummyResults();

        this.private.saveGameData(1, allResults[0], () => {
            console.log('done');
            this.private.saveGameData(2, allResults[1], () => {
                console.log('done');
                this.private.saveGameData(3, allResults[2], () => {
                    console.log('done');
                    this.private.saveGameData(4, allResults[3], () => {
                        console.log('All dummy data submitted OK. Accept the pop-up "leave site" to go to the results page.')
                        setTimeout(() => selectResultTemplate(), 1000);
                    });
                });
            });
        });
    }
}

export default GamesDummy;
